import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';

import {CRUD_VENDORS_OPTIONS, CRUD_VENDORS_LIST, CRUD_VENDORS_FILTER_STATUS, CRUD_VENDORS_FILTER_PAGESIZE, CRUD_VENDORS_FILTER_KEYWORD, CRUD_VENDORS_SORT_ITEMS, CRUD_VENDORS_DELETE_ITEM, CRUD_VENDORS_CREATE_ITEM, CRUD_VENDORS_UPDATE_ITEM, CRUD_VENDORS_NEED_COMPONENT_SET} from './action';

export const loadVendors = () => dispatch => {
    return Axios.get(API_BASE_URL + '/list/contact-options?roleName=ROLE_VENDOR', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_OPTIONS,
        options: res.data
    }));
}

export const listItems = (requestUrl) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_LIST,
        data: res.data,
    }));
}

export const filterUponStatus = (requestUrl, status) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_FILTER_STATUS,
        status: status,
        data: res.data
    }));
}

export const filterUponPageSize = (requestUrl, pageSize) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_FILTER_PAGESIZE,
        pageSize: pageSize,
        data: res.data
    }));
}

export const filterUponKeyword = (requestUrl, query) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_FILTER_KEYWORD,
        query: query,
        data: res.data
    }));
}

export const sortItems = (requestUrl, sort) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_SORT_ITEMS,
        sort: sort,
        data: res.data
    }));
}

export const deleteItem = (requestUrl) => dispatch => {
    return Axios.delete(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_DELETE_ITEM,
        needComponentUpdate:true
    }));
}

export const createItem = (requestUrl, data) => dispatch => {
    return Axios.post(API_BASE_URL + requestUrl, data, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_CREATE_ITEM,
        needComponentUpdate:true
    }));
}

export const updateItem = (requestUrl, data) => dispatch => {
    return Axios.put(API_BASE_URL + requestUrl, data, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_VENDORS_UPDATE_ITEM,
        needComponentUpdate:true
    }));
}

export const setNeedComponentUpdate = (status) => dispatch => {
    dispatch({
        type: CRUD_VENDORS_NEED_COMPONENT_SET,
        needComponentUpdate:status
    });
}