import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/TaxActions';
import { t } from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class TaxForm extends Component {

	state = {
		id: null,
		name: '',
		rate: '',
		status: true,

		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/business/tax/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
		Axios.get(API_BASE_URL + '/taxes/'+id, AuthService.getAuthHeader())
		.then(res => {			
			var st = res.data;
			this.setState({
				id: (this.props.path === dpath ? null: st.id),
				name: st.name,
				rate: st.rate,
				status: (st.status === "enabled")
			});
		});
	}
}
	changeStatus = () => { this.setState({ status: !this.state.status });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var tax = {
				name: this.state.name,
				rate: this.state.rate,
				status: this.state.status ? "enabled" : "disabled"
			}


			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			
			if (this.state.id !== null) {
				promise = this.props.updateItem('/taxes/'+this.state.id, tax);
			} else {
				promise = this.props.createItem('/taxes', tax);
			}

			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});
				
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit tax'):t('Add New tax')} </div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|string')}
											</div>
										</div>																		
										
										<div className="form-group row">
											<label htmlFor="rate" className="col-sm-3 col-form-label">{t("Rate")}</label>
											<div className="col-sm-9">
												<input value={this.state.rate} onChange={this.onChange} id="rate" type="text" name="rate" className="form-control" placeholder={t("Rate")}></input>
												{this.validator.message('rate', this.state.rate, 'required|numeric')}
											</div>
										</div>																		                            										
													
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled")}?</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>																		
																
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.tax.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (TaxForm);