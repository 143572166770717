import * as actionTypes from '../action';
const initialState = {
    bordered: true,
    striped: true,
    hover: true,
    searchable: true,
    query: "",

    daterange: true,
    start: null,
    end: null,
    amountrange: false,
    amountMin: null,
    amountMax: null,

    pageSize: 20,
    pageSizeOptions: [10,20,30,40,50],
    pagination: {
        page: 0,
        total: 0,
        pages: 0,
        last: true
    },
    sort: {
        col: null,
        order: null
    },
    rows: [],
    status: null,
    needComponentUpdate: false
}

const VendorPaymentReducer = (state= initialState, action) => {
    switch(action.type) {
        case actionTypes.CRUD_VENDOR_PAYMENT_LIST:            
        
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
            };
        case actionTypes.CRUD_VENDOR_PAYMENT_FILTER_STATUS:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                status: action.status
            };
        case actionTypes.CRUD_VENDOR_PAYMENT_FILTER_PAGESIZE:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                pageSize: action.pageSize
            };          
        case actionTypes.CRUD_VENDOR_PAYMENT_FILTER_KEYWORD:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                query: action.query
            };     
            case actionTypes.CRUD_VENDOR_PAYMENT_FILTER_DATE:
                return {
                    ...state,
                    pagination: {
                        page:  action.data.page,
                        pages: action.data.pages,
                        total: action.data.total,
                        last:  action.data.last,
                    },
                    rows: action.data.rows,
                    start: action.start,
                    end: action.end,
                };     
            case actionTypes.CRUD_VENDOR_PAYMENT_FILTER_AMOUNT:
                return {
                    ...state,
                    pagination: {
                        page:  action.data.page,
                        pages: action.data.pages,
                        total: action.data.total,
                        last:  action.data.last,
                    },
                    rows: action.data.rows,
                    amountMin: action.amountMin,
                    amountMax: action.amountMax
                };
        case actionTypes.CRUD_VENDOR_PAYMENT_SORT_ITEMS:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                sort: action.sort
            };  
        case actionTypes.CRUD_VENDOR_PAYMENT_CREATE_ITEM:
        case actionTypes.CRUD_VENDOR_PAYMENT_UPDATE_ITEM:
        case actionTypes.CRUD_VENDOR_PAYMENT_DELETE_ITEM:
        case actionTypes.CRUD_VENDOR_PAYMENT_NEED_COMPONENT_SET:
            return {
                ...state,
                needComponentUpdate: action.needComponentUpdate
            }
        default :
            return state;
    }

}

export default VendorPaymentReducer;