import * as actionTypes from '../action';
const initialState = {
    agreement: 0,
    revenue: 0,
    payment: 0,
    profit: 0,

    unpaidInvoices: {
        pageSize: 5,
        pagination: {
            page: 0,
            total: 0,
            pages: 0,
            last: true
        },
        rows: [],
    },
    latestPayments: [],
    latestMessages: {messages:[]},
    latestIncomes: [],
    salesAnalytics: {
        thisWeek: 0,
        thisMonth: 0,
        thisYear: 0,
        data: {}
    },
    globals: {
        timezone: "Europe/Paris",
        dateFormat: "DD-MM-YYYY",
        monthFormat: "MM-YYYY",
        datetimeFormat: 'DD-MM-YYYY hh:mm:ss',
        financial_year_start: 1640977200,
        financial_year_end: 1672513199,
        currency: {
            precision: 2,
            symbol: '€',
            symbolPosition: 'left',
            decimalMark: '.',
            thousandSeparator: ','
        },
        
        logo: null,
    },
    saleschart: {
        weekTotal: 0,
        monthTotal: 0,
        yearTotal: 0,
        data: [
            {x: '1', y: 0},
            {x: '2', y: 0},
            {x: '3', y: 0},
            {x: '4', y: 0},
            {x: '5', y: 0},
            {x: '6', y: 0},
            {x: '7', y: 0},
            {x: '8', y: 0},
            {x: '9', y: 0},
            {x: '10', y: 0},
            {x: '11', y: 0},
            {x: '12', y: 0},
        ]
    }
}

const DashboardReducer = (state= initialState, action) => {
    switch(action.type) {
        case actionTypes.DASH_COUNTERS:
            return {
                ...state,
                agreement: action.data.agreement,
                revenue: action.data.revenue,
                payment: action.data.payment,
                profit: action.data.profit
            }
        case actionTypes.DASH_UNPAID_INVOICES:
            const unpaidInvoices = state.unpaidInvoices;
            var d = action.data;
            return {
                ...state,
                unpaidInvoices: {
                    pageSize: unpaidInvoices.pageSize,
                    pagination: {
                        page: d.page,
                        total: d.total,
                        pages: d.pages,
                        last: d.last
                    },
                    rows: d.rows,
                }
            }
        case actionTypes.DASH_LATEST_INCOMES:
            return {
                ...state,
                latestIncomes: action.data
            }
        case actionTypes.DASH_LATEST_PAYMENTS:
            return {
                ...state,
                latestPayments: action.data
            }
        case actionTypes.DASH_LATEST_MESSAGES:
            return {
                ...state,
                latestMessages: action.data
            }
        case actionTypes.DASH_LOAD_GLOBALS:
            return {
                ...state,
                globals: action.data
            }
        case actionTypes.DASH_SALES_CHART:
            return {
                ...state,
                saleschart: action.data
            }
        default :
            return state;
    }
}

export default DashboardReducer;