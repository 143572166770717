import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/CustomersActions';
import {t} from '../../../components/Translation';
import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class CustomerForm extends Component {

	state = {
		uid: null,
		username: '',
		firstname:'',
		lastname:'',
		email: '',

		number:'',
		company: '',
		address: '',
		registration: '',
		phone: '',		
		website: '',
		currency: '',
		reference: '',	
		notes:'',
		attachment:'',

		status: true,
		currency_options: [],

		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/business/customers/:uid/duplicate";
		let uid = this.props.match.params.uid;
		if (typeof uid !== 'undefined') {
			Axios.get(API_BASE_URL + '/contacts/' + uid + '?roleName=ROLE_CUSTOMER', AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					...st,
					uid: (this.props.path === dpath ? null: st.uid),
					username: (this.props.path === dpath ? null: st.username),
					email: (this.props.path === dpath ? null: st.email),
					status: (st.status === 1),
				});
			});
		} else {
			Axios.get(API_BASE_URL + '/settings/contacts/customer', AuthService.getAuthHeader())
            .then(res => {
                let setting = res.data;
                this.setState({
                    ...this.state,
                    number: setting.number_prefix + setting.next_number.padStart(setting.number_digit, '0')
                });
            });
		}

		Axios.get(API_BASE_URL + '/list/currency-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({currency_options: res.data});
		});				
	}

	changeStatus = () => { this.setState({ status: !this.state.status });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onCurrencyChange = (e) => {
		this.setState({currency: e});
	}

	onChangeFile = (e) => {		
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/image', data, AuthService.getAuthHeader())
		    	.then(res => {			
					this.setState({
						attachment: res.data.fileName
					});
				});
		} else {
			
		}
	}
			
	onSubmit = (e) => {
		e.preventDefault();
		var objectConstructor = ({}).constructor;
		if (this.validator.allValid()) {
			var customer = {
				...this.state,
				currency: this.state.currency.value,
				attachment: (this.state.attachment !== null && this.state.attachment.constructor === objectConstructor)
					? this.state.attachment.fileName : this.state.attachment,
				status: (this.state.status ? 1 : 0),
				currency_options: [],
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.uid !== null) {
				promise = this.props.updateItem('/contacts/' + this.state.uid + '?roleName=ROLE_CUSTOMER', customer);
			} else {
				promise = this.props.createItem('/contacts?roleName=ROLE_CUSTOMER', customer);
			}

			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});
			
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit customer'):t('Add New customer')} </div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="username" className="col-sm-3 col-form-label">{t("User Name")}</label>
											<div className="col-sm-9">
												<input readOnly={this.state.uid !== null} value={this.state.username} onChange={this.onChange} id="username" type="text" name="username" className="form-control" placeholder={t("User Name")}></input>
												{this.validator.message('username', this.state.username, 'required|string')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="firstname" className="col-sm-3 col-form-label">{t("First Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.firstname} onChange={this.onChange} id="firstname" type="text" name="firstname" className="form-control" placeholder={t("First Name")}></input>
												{this.validator.message('firstname', this.state.firstname, 'required|string')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="lastname" className="col-sm-3 col-form-label">{t("Last Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.lastname} onChange={this.onChange} id="lastname" type="text" name="lastname" className="form-control" placeholder={t("Last Name")}></input>
												{this.validator.message('lastname', this.state.lastname, 'required|string')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="email" className="col-sm-3 col-form-label">{t("Email")}</label>
											<div className="col-sm-9">
												<input value={this.state.email} onChange={this.onChange} id="email" type="text" name="email" className="form-control" placeholder={t("Email")}></input>
												{this.validator.message('email', this.state.email, 'required|email')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="number" className="col-sm-3 col-form-label">{t("Number")}</label>
											<div className="col-sm-9">
												<input value={this.state.number} onChange={this.onChange} id="number" type="text" name="number" className="form-control" placeholder={t("Number")}></input>
												{this.validator.message('number', this.state.number, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="company" className="col-sm-3 col-form-label">{t("Company")}</label>
											<div className="col-sm-9">
												<input value={this.state.company} onChange={this.onChange} id="company" type="text" name="company" className="form-control" placeholder={t("Company")}></input>
												{this.validator.message('company', this.state.company, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="address" className="col-sm-3 col-form-label">{t("Address")}</label>
											<div className="col-sm-9">
												<input value={this.state.address} onChange={this.onChange} id="address" type="text" name="address" className="form-control" placeholder={t("Address")}></input>
												{this.validator.message('address', this.state.address, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="registration" className="col-sm-3 col-form-label">{t("Registration")}</label>
											<div className="col-sm-9">
												<input value={this.state.registration} onChange={this.onChange} id="registration" type="text" name="registration" className="form-control" placeholder={t("Registration")}></input>
												{this.validator.message('registration', this.state.registration, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="phone" className="col-sm-3 col-form-label">{t("Phone")}</label>
											<div className="col-sm-9">
												<input value={this.state.phone} onChange={this.onChange} id="phone" type="text" name="phone" className="form-control" placeholder={t("Phone")}></input>
												{this.validator.message('phone', this.state.phone, 'phone')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="website" className="col-sm-3 col-form-label">{t("Website")}</label>
											<div className="col-sm-9">
												<input value={this.state.website} onChange={this.onChange} id="website" type="text" name="website" className="form-control" placeholder={t("Website")}></input>
												{this.validator.message('website', this.state.website, 'url')}
											</div>
										</div>
										
									
										<div className="form-group row">
											<label htmlFor="currency" className="col-sm-3 col-form-label">{t("Currency")}</label>
											<div className="col-sm-9">
												<Select 
													name="currency"
													value={this.state.currency_options.filter(option => option.value === this.state.currency.value)}
													id="currency"
													onChange={this.onCurrencyChange}
													options = {this.state.currency_options} 
												/>
												{this.validator.message('currency', this.state.currency, 'required')}
											</div>
										</div>
																															
										<div className="form-group row">
											<label htmlFor="reference" className="col-sm-3 col-form-label">{t("Reference")}</label>
											<div className="col-sm-9">
												<input value={this.state.reference} onChange={this.onChange} id="reference" type="text" name="reference" className="form-control" placeholder={t("Reference")}></input>
												{this.validator.message('reference', this.state.reference, 'string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="notes" className="col-sm-3 col-form-label">{t("Notes")}</label>
											<div className="col-sm-9">
												<textarea value={this.state.notes} onChange={this.onChange} id="notes" type="text" name="notes" className="form-control" placeholder={t("Notes")}></textarea>
												{this.validator.message('notes', this.state.notes, 'string')}
											</div>
										</div>									

										<div className="form-group row">
											<label htmlFor="attachment" className="col-sm-3 col-form-label">{t("Attachment")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="attachment"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="attachment"											
														aria-describedby="attachment" />
														<label className="custom-file-label" htmlFor="attachment">
															{t("Choose file")}
														</label>
													</div>
			                                    </div>
												{(this.state.attachment !== null) && <img className='image-width' src={API_IMAGES_URL + this.state.attachment} /> }
												<div >{t("Allowed file types are: {0}.", [IMAGE_TYPES.join(', ')])}</div>
											</div>
										</div>				
													
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled?")}</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>																		
																
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.customers.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (CustomerForm);