import React , {Component } from 'react';
import {PropTypes} from 'prop-types';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {loadTaxes} from '../../../store/TaxActions';
import { t } from '../../../components/Translation';
import Select from 'react-select';

class ItemRow extends Component {

    state = {
        customerTax: null,
        supplierTax: null,
        total: 0
    }

    componentDidMount() {
        this.setState(this.props.item);
    }

    onItemInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.props.onItemInputChange(this.props.index, e);
    }

    onCustomerTaxChange = (e) => {
        this.setState({
            customerTax: e
        })
        this.props.onCustomerTaxChange(this.props.index, e);
    }

    onSupplierTaxChange = (e) => {
        this.setState({
            supplierTax: e
        })
        this.props.onSupplierTaxChange(this.props.index, e);
    }

    onQuantityChange = (e) => {
        this.setState({
            quantity: parseInt(e.target.value)
        })
        this.props.onQuantityChange(this.props.index, e);
    }

    onPriceChange = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== "") ? parseFloat(e.target.value) : 0
        })
        this.props.onPriceChange(this.props.index, e);
    }

    render() {

        return(
            <AUX>
                <tr>
                    <td>{this.props.removeable && <button onClick={this.props.onRemoveClick.bind(this, this.props.index)} type="button" className="btn btn-outline-danger waves-light waves-effect" title="Delete selected"><i className="ti-trash"></i></button>}</td>
                    <td style={{width: '22%'}}><input name='reference' onChange={this.onItemInputChange.bind(this)} value={this.state.reference} type="text" className="form-control" placeholder="ref: " /></td>
                    <td style={{width: '22%'}}><input name='name' onChange={this.onItemInputChange.bind(this)} value={this.state.name} type="text" className="form-control" placeholder="name: " /></td>
                    <td><input name='quantity' onChange={this.onQuantityChange.bind(this)} value={this.state.quantity} type="number" className="form-control text-center" placeholder="1" /></td>
                    <td><input name='purchasePrice' onChange={this.onPriceChange.bind(this)} value={this.state.purchasePrice} type="text" className="form-control text-center" placeholder="0,000.00" /></td>
                    <td><input name='salePrice' onChange={this.onPriceChange.bind(this)} value={this.state.salePrice} type="text" className="form-control text-center" placeholder="0,000.00" /></td>
                    <td>
                        <Select 
                            name="customerTax"
                            value={this.state.customerTax}
                            id="customerTax"
                            onChange={(e) => this.onCustomerTaxChange(e)}
                            options = {this.props.taxes} 
                            className={'tax-select'}
                        />
                    </td>
                    <td>
                        <Select 
                            name="supplierTax"
                            value={this.state.supplierTax}
                            id="supplierTax"
                            onChange={(e) => this.onSupplierTaxChange(e)}
                            options = {this.props.taxes} 
                            className={'tax-select'}
                        />
                    </td>
                    {/* <td className="text-right">{this.props.item.total}</td> */}
                </tr>
            </AUX>
        )
    }
}

ItemRow.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    taxes: state.tax.options,
})

export default connect (mapStateToProps, {loadTaxes}) (ItemRow);