import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';
import DateTime from '../../../components/Timestamp/DateTime';
import '../../../components/Text/String';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/CustomersActions';
import {loadGlobals} from '../../../store/DashboardActions';
import {t} from '../../../components/Translation';
import { formatDate } from '../../../helper';

class CustomersList extends Component {

	state = {	
		alert: null,	
		baseUrl: '/contacts',
		roleName: 'ROLE_CUSTOMER',
		addBtn: {
			label: t('Create new customer'),
			url: this.props.formPath + '/create'
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },	
			{
				label: 'UID',
				field: 'uid',
				sort: false
			},	
			{
				label: t('Number'),
				field: 'number',
				sort: true,
				sortCol: 'number'
			},		    
		    {
		      label: t('User Name'),
		      field: 'username',
		      sort: true,
			  sortCol: 'username'
		    },
			{
				label: t('First Name'),
				field: 'firstname',
				sort: true,
				sortCol: 'firstname'
			},
			{
				label: t('Last Name'),
				field: 'lastname',
				sort: true,
				sortCol: 'lastname'
			},
		    {
		      label: t('Email'),
		      field: 'email',
		      sort: true,
			  sortCol: 'email'
		    },
		    {
		      label: t('Phone'),
		      field: 'phone',
		      sort: false
		    },
		    {
		      label: t('Website'),
		      field: 'website',
		      sort: false
		    },
		    {
		      label: t('Address'),
		      field: 'address',
		      sort: false
		    },
		    {
		      label: t('Currency'),
		      field: 'currency',
		      sort: true,
			  sortCol: 'currencyName'
		    },
		    {
		      label: t('Reference'),
		      field: 'reference',
		      sort: false
		    },   
		    {
		      label: t('Status'),
		      field: 'status',
		      sort: true,
			  sortCol: 'status'
		    },		   
		    {
		      label: t('Created'),
		      field: 'created',
		      sort: true,
			  sortCol: 'created'
		    },		   
		    {
		      label: t('Updated'),
		      field: 'updated',
		      sort: true,
			  sortCol: 'updated'
		    },		  
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: [
			{
				label: t("All"),
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: t("Enabled"),
				value: 1,
				class: "btn-outline-success"
			},
			{
				label: t("Disabled"),
				value: 0,
				class: "btn-outline-warning"
			}
		]
	}

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id + '?roleName=ROLE_CUSTOMER');
	}
	
	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			  var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'uid': value.uid,		
				'number': value.number,		
				'username': value.username.Capitalize(),
				'firstname': value.firstname.Capitalize(),
				'lastname': value.lastname.Capitalize(),
				'email': value.email,
				'phone': value.phone,
				'website': value.website,
				'address': value.address,
				'currency': value.currency.label.Capitalize(),
				'reference': value.reference,				
				'status': <Status type="contact" value={value.status} />,
				'created': formatDate(value.created, this.props.globals.dateFormat),
				'updated': formatDate(value.updated, this.props.globals.dateFormat),
				'actions': <Action state={value} path={this.props.formPath} type="contact" onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}
	render() {

		let rows = this.mapAttributes(this.props.rows);

		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										roleName={this.state.roleName}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
                                        rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.customers.rows,
	globals: state.dash.globals,
	pagination: state.customers.pagination,
	pageSize: state.customers.pageSize,
})

export default connect( mapStateToProps, {deleteItem, loadGlobals} ) ( CustomersList );