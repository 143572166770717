import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, FILES_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import Action from '../../../components/UI/DataTable/Action';
import '../../../components/Text/String';
import { t } from '../../../components/Translation';
import Tbl from './tbl';
import {deleteItem} from '../../../store/RevenueActions';
import {connect} from 'react-redux';
import { loadGlobals } from '../../../store/DashboardActions';
import { formatDate, formatAmount } from '../../../helper';

class RevenueList extends Component {
	state = {
		alert: null,
		baseUrl: '/revenues',
		addBtn: {
			label: t('Create new revenue'),
			url: this.props.formPath + "/create"
		},		
		columns: [
			  {
                label: t('Date'),
                field: 'date',
                sort: true,
              	sortCol: 'date',
                width: 150
              },
			  {
				  label:'Id',
				  field:'id',
				  sort:false
			  },
              {
                label: t('Invoice'),
                field: 'invoice',
                sort: true,
              	sortCol: 'invoiceNumber',
                width: 150
              },
              {
                label: t('Amount'),
                field: 'amount',
                sort: true,
              	sortCol: 'amount',
                width: 200
              },
              {
                label: t('Customer'),
                field: 'customer',
                sort: true,
              	sortCol: 'customerName',
                width: 270
              },
              {
                label: t('Category'),
                field: 'category',
                sort: true,
              	sortCol: 'categoryName',
                width: 170
              },
              {
                label: t('Account'),
                field: 'account',
                sort: true,
              	sortCol: 'accountName',
                width: 150
              },
      	],
		statuses: []
	}

	deleteItem = (id) => {
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}

	mapAttributes(rows) {
		var items = [];
        for (const [index, value] of rows.entries()) {
            var item = {
                'date': formatDate(value.date, this.props.globals.dateFormat),
				'id': '',
				'invoice': value.invoice.label,
				'amount': formatAmount(value.amount, this.props.globals.currency),
				'customer': value.customer.label.Capitalize(),
				'category': value.category.label.Capitalize(),
				'account': value.account.label.Capitalize(),
			};
            items.push( item );
        }
        return items;
	}
	
	onDownloadClick = (filename) => {
		Axios.get(FILES_URL + filename, AuthService.getAuthHeader()).then(res => {
			console.log(res)
		});
	}
	
	deleteItem = (id) => {		
		Axios.delete(API_BASE_URL + '/revenue/'+ id, AuthService.getAuthHeader()).then(res => {
			this.setState({ rows: [...this.state.rows.filter(row => row.id !== id)]});
		})
	}
	
	render() {

		let rows = this.mapAttributes(this.props.rows);

		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								
								<div className="card-body">			
									
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
										columns={this.state.columns}
										rows={rows}
									/>			
																													
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
	rows: state.revenue.rows,
	globals: state.dash.globals,
	pagination: state.revenue.pagination,
	pageSize: state.revenue.pageSize,
  })
  
  export default connect( mapStateToProps, {loadGlobals, deleteItem} ) ( RevenueList );