import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import SweetAlert  from 'react-bootstrap-sweetalert';
import {t} from '../../../components/Translation';

class AgreementSettings extends Component {

	state = {
  	    next_number: '',
	    number_digit: '',
	    number_prefix: '',
		alert: null
	}
	
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		Axios.get(API_BASE_URL + '/settings/agreement', AuthService.getAuthHeader())
		.then(res => {
			this.setState(res.data);
		});
	}
	
	hideAlert() {
		this.setState({   alert: null  });
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var agreement = this.state;			
			var requestUrl = API_BASE_URL + '/settings/agreement';
			var requestMethod = 'put';
			Axios.request({
				url: requestUrl, 
				method: requestMethod,
				data: agreement,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + AuthService.getToken()
				}
			})
			.then(res => {						
				const alertBox = () => ( 
		            <SweetAlert
						success				
						confirmBtnBsStyle="success"
						title={t("Success!")}
						onConfirm={() => {					
							this.hideAlert();
						}}>
					</SweetAlert> 
				);
		        this.setState({   alert: alertBox()  });								
			});
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				{this.state.alert}
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{t("Agreement Settings")}</div>
								<div className="card-body">			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="next_number" className="col-sm-3 col-form-label">{t("Next Sequence number")}</label>
											<div className="col-sm-9">
												<input value={this.state.next_number} onChange={this.onChange} id="next_number" type="text" name="next_number" className="form-control" placeholder={t("Next Sequence number")}></input>
												{this.validator.message('next_number', this.state.next_number, 'required|string')}
											</div>
										</div>																		
										<div className="form-group row">
											<label htmlFor="number_digit" className="col-sm-3 col-form-label">{t("Number of digits")}</label>
											<div className="col-sm-9">
												<input value={this.state.number_digit} onChange={this.onChange} id="number_digit" type="text" name="number_digit" className="form-control" placeholder={t("Number of digits")}></input>
												{this.validator.message('number_digit', this.state.number_digit, 'required|string')}
											</div>
										</div>																		
										<div className="form-group row">
											<label htmlFor="number_prefix" className="col-sm-3 col-form-label">{t("Number prefix")}</label>
											<div className="col-sm-9">
												<input value={this.state.number_prefix} onChange={this.onChange} id="number_prefix" type="text" name="number_prefix" className="form-control" placeholder={t("Number prefix")}></input>
												{this.validator.message('number_prefix', this.state.number_prefix, 'required|string')}
											</div>
										</div>		
												
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>								
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

export default AgreementSettings;