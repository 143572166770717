import React  from 'react';

import '../../Text/String';
import {t} from '../../Translation';
 
export default class Status extends React.Component {
    
    state = {
        className: "",
        status: ""
    }

    componentDidMount() {
        this.handleStatus();
    }

    componentDidUpdate() {
        if (this.state.value !== this.props.value) {
            this.handleStatus();
        }
    }

    handleStatus = () => {

        let className = "font-12 badge badge-";
        let status = "";
        if (this.props.type === 'invoice') {
            switch (this.props.value) {
                case 0:
                    status = t('draft');
                    className = className + 'dark';
                    break;
                case 1:
                    status = t('sent');
                    className = className + 'warning';
                    break;
                case 2:
                    status = t('cancelled');
                    className = className + 'danger';
                    break;
                case 3:
                    status = t('partial paid');
                    className = className + 'warning';
                    break;
                case 4:
                    status = t('paid');
                    className = className + 'success';
                    break;
                case 5:
                    status = t('overdue');
                    className = className + 'danger';
                    break;
                default:
                    status = t('unknown');
                    className = className + 'dark';
              }  
        }

        else if (this.props.type === "role") {
            switch(this.props.value) {
                case 0:
                    status = t('Disabled');
                    className = className + 'danger';
                    break;
                case 1:
                    status = t('Enabled');
                    className = className + 'success';
                    break;
                default:
                    status = t('unknown');
                    className = className + 'dark';
                    break;
            }
        }

        else if (this.props.type === "permission" || this.props.type === "apipermission") {
            switch(this.props.value) {
                case 0:
                    status = t('Disabled');
                    className = className + 'danger';
                    break;
                case 1:
                    status = t('Enabled');
                    className = className + 'success';
                    break;
                default:
                    status = t('unknown');
                    className = className + 'dark';
                    break;
            }
        }

        else if (this.props.type === "user") {
            switch(this.props.value) {
                case 0:
                    status = t('pending');
                    className = className + 'warning';
                    break;
                case 1:
                    status = t('active');
                    className = className + 'success';
                    break;
                case 2:
                    status = t('inactive');
                    className = className + 'dark';
                    break;
                case 3:
                    status = t('deleted');
                    className = className + 'danger';
                    break;
                default:
                    status = t('unknown');
                    className = className + 'dark';
                    break;
            }
        }

        else if (this.props.type === "agreement") {
            switch(this.props.value) {
                case 0:
                    status = t('Draft');
                    className = className + 'secondary';
                    break;
                case 1:
                    status = t('Active');
                    className = className + 'success';
                    break;
                case 2:
                    status = t('Closed');
                    className = className + 'dark';
                    break;
                default:
                    status = t('unknown');
                    className = className + 'dark';
                    break;
            }
        }

        else if (this.props.type === "timesheet") {
            switch(this.props.value) {
                case 0:
                    status = t('Draft');
                    className = className + 'secondary';
                    break;
                case 1:
                    status = t('Sent');
                    className = className + 'warning';
                    break;
                case 2:
                    status = t('Approved');
                    className = className + 'success';
                    break;
                default:
                    status = t('unknown');
                    className = className + 'dark';
                    break;
            }
        }

        else if (this.props.type === 'contact') {
            switch (this.props.value) {
                case 1:
                    status = 'active';
                    className = className + 'success';
                    break;
                case 0:
                    status = 'inactive';
                    className = className + 'warning';
                    break;
                default:
                    status = 'unknown';
                    className = className + 'dark';
              }  
        }

		else {
			switch (this.props.value) {
                case "enabled":
                    status = t('enabled');
                    className = className + 'success';
                    break;
                case "disabled":
                    status = t('disabled');
                    className = className + 'warning';
                    break;            
                default:
                    status = t('unknown');
                    className = className + 'dark';
              }  
        }
        
        this.setState({
            className,
            status,
            value: this.props.value
        });
    }

    render() {
        return (
            <React.Fragment>
                {<span className={this.state.className} >{this.state.status.Capitalize()}</span>}
			</React.Fragment>
        );
    }
}