import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/CurrencyActions';
import {t} from '../../../components/Translation';

import { handleError } from '../../../helper';
import Select from 'react-select';
import SweetAlert  from 'react-bootstrap-sweetalert';

class CurrencyForm extends Component {

	state = {
		id: null,
		name: '',
		code: '',
		rate: '',
		precision: '',
		symbol: '',
		symbolPosition: 'left',
		decimalMark: '',
		thousandSeparator: '',		
		status: true,
		isdefault: true	,
		
		alert: null,

		symbolPositions: [
			{label: t('Left'), value: 'left'},
			{label: t('Right'), value: 'right'}
		]
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/business/currency/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
			Axios.get(API_BASE_URL + '/currencies/'+id, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					id: (this.props.path === dpath ? null: st.id),
					name: st.name,
					code: st.code,
					rate: st.rate,
					precision: st.precision,
					symbol: st.symbol,
					symbolPosition: st.symbolPosition,
					decimalMark: st.decimalMark,
					thousandSeparator: st.thousandSeparator,
					status: (st.status === "enabled"),
					isdefault: (st.isdefault === 1)
				});
			});
		}
	}

	changeStatus = () => { this.setState({ status: !this.state.status });  }
	changeIsdefault = () => { this.setState({ isdefault: !this.state.isdefault });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });		

	onChangePosition = (e) => {
		this.setState({symbolPosition: e.value});
	}
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var currency = {
				name: this.state.name,
				code: this.state.code,
				rate: this.state.rate,
				precision: this.state.precision,
				symbol: this.state.symbol,
				symbolPosition: this.state.symbolPosition,
				decimalMark: this.state.decimalMark,
				thousandSeparator: this.state.thousandSeparator,
				status: this.state.status ? "enabled" : "disabled",
				isdefault: this.state.isdefault ? 1 : 0
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			
			if (this.state.id !== null) {
				promise = this.props.updateItem('/currencies/'+this.state.id, currency);
			} else {
				promise = this.props.createItem('/currencies', currency);
			}

			promise
			.then(response => {
				
				const alertBox = () => ( 
					<SweetAlert
						success				
						confirmBtnBsStyle="success"
						title={t("Success!")}
						onConfirm={() => {					
							this.hideAlert();
							history.push(listPath);
						}}>
					</SweetAlert> 
				);
				this.setState({   alert: alertBox()  });		
				
			})
			.catch(error => {
				var message = handleError(error);
				
				const alertBox = () => ( 
					<SweetAlert
						danger				
						confirmBtnBsStyle="danger"
						title={t("Error!")}
						onConfirm={() => {					
							this.hideAlert();
						}}>
							{message}
					</SweetAlert> 
				);
				this.setState({   alert: alertBox()  });
			});
		
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit Currency'):t('Add New Currency')}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|alpha_space')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="code" className="col-sm-3 col-form-label">{t("Code")}</label>
											<div className="col-sm-9">
												<input value={this.state.code} onChange={this.onChange} id="code" type="text" name="code" className="form-control" placeholder={t("Code")}></input>
												{this.validator.message('code', this.state.code, 'required|alpha')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="rate" className="col-sm-3 col-form-label">{t("Rate")}</label>
											<div className="col-sm-9">
												<input value={this.state.rate} onChange={this.onChange} id="rate" type="text" name="rate" className="form-control" placeholder={t("Rate")}></input>
												{this.validator.message('rate', this.state.rate, 'required|numeric')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="precision" className="col-sm-3 col-form-label">{t("Precision")}</label>
											<div className="col-sm-9">
												<input value={this.state.precision} onChange={this.onChange} id="precision" type="text" name="precision" className="form-control" placeholder={t("Precision")}></input>
												{this.validator.message('precision', this.state.precision, 'required|integer')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="symbol" className="col-sm-3 col-form-label">{t("Symbol")}</label>
											<div className="col-sm-9">
												<input value={this.state.symbol} onChange={this.onChange} id="symbol" type="text" name="symbol" className="form-control" placeholder={t("Symbol")}></input>
												{this.validator.message('symbol', this.state.symbol, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="symbolPosition" className="col-sm-3 col-form-label">{t("Symbol position")}</label>
											<div className="col-sm-9">
												<Select 
													name="symbolPosition"
													value={this.state.symbolPositions.filter(option => option.value === this.state.symbolPosition)}
													id="symbolPosition"
													onChange={this.onChangePosition}
													options = {this.state.symbolPositions} 
												/>
												{this.validator.message('symbolPosition', this.state.symbolPosition, 'required')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="decimalMark" className="col-sm-3 col-form-label">{t("Decimal mark")}</label>
											<div className="col-sm-9">
												<input value={this.state.decimalMark} onChange={this.onChange} id="decimalMark" type="text" name="decimalMark" className="form-control" placeholder={t("Decimal mark")}></input>
												{this.validator.message('decimalMark', this.state.decimalMark, 'required|string')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="thousand_separator" className="col-sm-3 col-form-label">{t("Thousand Separator")}</label>
											<div className="col-sm-9">
												<input value={this.state.thousandSeparator} onChange={this.onChange} id="thousandSeparator" type="text" name="thousandSeparator" className="form-control" placeholder={t("Thousand Separator")}></input>
												{this.validator.message('thousandSeparator', this.state.thousandSeparator, 'required|string')}
											</div>
										</div>
										
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled?")}</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>																		
									
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Is default?")}</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="isdefault" checked={this.state.isdefault ? "checked"  :  "" } switch="none" onChange={this.changeIsdefault} />
												<label htmlFor="isdefault" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>
			
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.currency.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (CurrencyForm);