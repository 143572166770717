import Axios from 'axios';
import decode from 'jwt-decode';
import { ToastsStore } from 'react-toasts';
import {API_BASE_URL, TOKEN, IDENTITY, IMAGES_URL, API_IMAGES_URL, LANG, BASIC_AUTH_TOKEN} from '../../config';
import {handleError} from '../../helper';

class AuthService {

    login(credentials, history, UpdateLoginAgain) {
		Axios.post(API_BASE_URL + "/auth/login", credentials, this.getBasicAuthHeader())
		.then(res => {

			// Set token in local storage
            this.setToken(res.data.token);
            this.loadUserInfo();
			history.push("/");
			UpdateLoginAgain();
		})

		.catch(error => {
			var message = handleError(error);
			ToastsStore.error(message, 6000);
		});
    }

	loadUserInfo() {
		// Get user identity and save to local storage.
		Axios.get(API_BASE_URL + '/me', this.getAuthHeader())
		.then(res => {
			localStorage.setItem(IDENTITY, JSON.stringify(res.data));
		})
		.catch(error => {
			console.error(error);
		});
	}

	renewToken() {
		Axios.post(API_BASE_URL + "/auth/refresh-token", {}, {headers: {Authorization: 'Bearer ' + this.getToken() }})
		.then(res => {
			// Set token in local storage
			this.setToken(res.data.token);
		})
		.catch(error => {
			console.error(error); 
			
		});
	}

    getUserInfo() {
    	if (this.loggedIn()) {
			return JSON.parse(localStorage.getItem(IDENTITY));
		} else {
    		return false;
		}
	}
	
	getEmail() {
		var info = this.getUserInfo();
		if (info) {
			return this.getUserInfo().email;
		} else {
			return "";
		}
	}

	getUsername() {
		var info = this.getUserInfo();
		if (info) {
			return this.getUserInfo().username;
		} else {
			return "";
		}
	}

    getPhoto() {
    	let defaultPhoto = IMAGES_URL + 'user.png';
		if (this.getUserInfo() && this.getUserInfo().photo !== null && this.getUserInfo().photo !== "") {
			defaultPhoto = API_IMAGES_URL + this.getUserInfo().photo;
		}
		return defaultPhoto;
	}

    setPhoto(photo) {
    	var info = this.getUserInfo();
    	info.photo = photo;
    	localStorage.setItem(IDENTITY, JSON.stringify(info));
	}

	setToken(token) {
		localStorage.setItem(TOKEN, token);
	}

	loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken()
        return !!token && !this.isTokenExpired(token) // handwaiving here
	}

	getUid() {
		if (this.loggedIn()) {
			const decoded = decode(this.getToken());
			return decoded.sub;
		}
	}
	
	isRenewTokenRequired() {
		try {
			const decoded = decode(this.getToken());
			var now = Math.abs(Date.now() / 1000);
            if (decoded.exp < now) {
                return false;
            } else {
				var diff = decoded.exp - now;
				if ( diff <= 600 ) {
					return true;
				}
			}
        }
        catch (err) {
            return false;
        }
	}

	isTokenExpired() {
        try {
            const decoded = decode(this.getToken());
            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

	getToken() {
		return localStorage.getItem(TOKEN);
	}

    getAuthHeader() {
		if (this.isRenewTokenRequired() === true) {
			this.renewToken();
		}
       	return {
			headers: {
			   Authorization: 'Bearer ' + this.getToken(),
			   'Accept-Language': LANG
			}
		};
    }

	getBasicAuthHeader() {
       	return {
			headers: {
			   Authorization: 'Basic ' + BASIC_AUTH_TOKEN,
			   'Accept-Language': LANG
			}
		};
    }

    logOut() {
		var authToken = this.getToken();
		localStorage.removeItem(IDENTITY);
		localStorage.removeItem(TOKEN);
        return Axios.post(API_BASE_URL + '/auth/logout', {}, {headers: {Authorization: 'Bearer ' + authToken }});
    }
}

export default new AuthService();