import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import {API_BASE_URL, FILES_URL} from '../../../config';
import Left from './Left';
import Toolbar from './Toolbar';

import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

import {loadMessage} from '../../../store/MessageActions';
import ReactHtmlParser from 'react-html-parser'; 
import { t } from '../../../components/Translation';

class Read extends Component {

    state = {
        alert: null,
        folders: [],
        labels: [],
        message: {},
    }

    componentDidMount() {
        let st = this.props.location.state;
        console.log(st)
        if (st) {
            this.setState({
                folders: st.folders,
                labels: st.labels,
                currentFolder: st.currentFolder,
                // currentLabel: st.currentLabel,
                userUid: st.userUid
            });
        }
    }

    componentDidUpdate() {
        let messageUid = this.props.match.params.message;
        if ( !this.props.message.uid || this.props.message.uid !== messageUid) {
            this.props.loadMessage(this.state.currentFolder, this.props.match.params.message);
        }
    }
    
    render() {

        const msg = this.props.message;
        const replyTo = {
            pathname: "/message/compose",
            state: {
                labels: this.state.labels,
                folders: this.state.folders,
                userUid: this.state.userUid,
                to: (this.state.userUid === msg.receiverId ? msg.senderEmail : msg.receiverEmail)
            }
        }

        return (
            <AUX>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            
                            <div className="email-leftbar card">
                                <Left 
                                    type="read"                                     
                                    messageUid={this.props.match.params.message}
                                    folders={this.state.folders} 
                                    labels={this.state.labels}                                     
                                   />
                            </div>
                            
                            <div className="email-rightbar mb-3">
                                
                                <div className="card">
                                    <Toolbar 
                                        type="read"
                                        labels={this.state.labels}
                                        folders={this.state.folders}
                                        message={this.props.message}
                                        userUid={this.state.userUid}
                                        />

                                    <div className="card-body">
    
                                        <div className="media m-b-30">
                                            <img className="d-flex mr-3 rounded-circle thumb-md" src="/assets/images/user.png" alt="Generic placeholder" />
                                            <div className="media-body">
                                                <h4 className="font-14 m-0">{(this.state.userUid === msg.receiverId ? msg.senderName : msg.receiverName)}</h4>
                                                <small className="text-muted">{(this.state.userUid === msg.receiverId ? msg.senderEmail : msg.receiverEmail)}</small>
                                            </div>
                                        </div>
    
                                        <h4 className="mt-0 font-16">{msg.subject}</h4>
    
                                        <div> { ReactHtmlParser (msg.content) } </div>
                                        <hr/>
    
                                        <div className="row">
                                            {msg.attachments.map((file, index) => {
                                                return <div className="col-xl-2 col-6" key={index}>
                                                    <div className="card m-b-20">
                                                        <img className="card-img-top img-fluid" src="/assets/images/img-3.jpg" alt="Preview" />
                                                            <div className="p-t-10 p-b-10 text-center">
                                                            <a href={FILES_URL + file.name} className="text-muted font-500">{file.originalName}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <Link to={replyTo} className="btn btn-outline-primary waves-effect m-t-30"><i className="mdi mdi-reply"></i> {t("Reply")}</Link>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>  
                </div>
            </AUX>
        );
    }
}

Read.propTypes = {
    loadMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    message: state.msg_store.message,
});

export default connect( mapStateToProps, {loadMessage} ) ( Read );