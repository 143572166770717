import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, IMAGE_TYPES, FILE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/CustomersActions';
import {t} from '../../../components/Translation';
import ItemRow from './ItemRow';
import { loadCustomers } from '../../../store/CustomersActions';
import { loadSuppliers } from '../../../store/SuppliersActions';
import { loadCurrencies } from '../../../store/CurrencyActions';
import { loadCreditCategories, loadDebitCategories } from '../../../store/CategoryActions';
import { loadTaxes } from '../../../store/TaxActions';
import { loadGlobals } from '../../../store/DashboardActions';

import { handleError } from '../../../helper';
import { formatAmount } from '../../../helper';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {ToastsStore} from 'react-toasts';
import SweetAlert  from 'react-bootstrap-sweetalert';

class AgreementsForm extends Component {

	state = {
		id: null,
		customer: {},
		customerCategory: {},
		supplier: {},
		supplierCategory: {},
		currency: {},
		number: '',	
		start: new Date(),
		end: new Date(),	
			
		items: [],
		subtotal: 0,
		tax: 0,
		total: 0,

		notes: '',
		attachments: [],
		

		status: 0,

		statuses: [
			{label: t('Draft'), value: 0},
			{label: t('Active'), value: 1},
			{label: t('Closed'), value: 2},
		],

		attachmentsToDelete: [],
		servicesToDelete: [],

		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {

		this.props.loadCustomers();
		this.props.loadCurrencies();
		this.props.loadCreditCategories();
		this.props.loadDebitCategories();
		this.props.loadTaxes();
		this.props.loadGlobals();
		this.props.loadSuppliers();
		
		let dpath = "/business/agreements/:id/duplicate";
		let id = this.props.match.params.id;

		if (typeof id !== 'undefined') {
			Axios.get(API_BASE_URL + '/agreements/'+id, AuthService.getAuthHeader())
			.then(res => {
				var st = res.data;
				this.setState({
					id: (this.props.path === dpath ? null: st.id),
					customer: st.customer,
					currency: st.currency,
					customerCategory: st.customerCategory,
					supplier: st.supplier,
					supplierCategory: st.supplierCategory,
					number: st.number,
					start: new Date( st.start * 1000 ),
					end: new Date( st.end * 1000 ),
					status: st.status,
					notes: st.notes,
					items: st.services,
					attachments: st.attachments,
				});
				this.calculate();
			});
		} else {
            Axios.get(API_BASE_URL + '/settings/agreement', AuthService.getAuthHeader())
            .then(res => {
                let setting = res.data;
                this.setState({
                    ...this.state,
                    number: setting.number_prefix + setting.next_number.padStart(setting.number_digit, '0')
                });
            });
			this.onAddClick();
        }			
						
	}

	// changeStatus = () => { this.setState({ status: !this.state.status });  }
	
	onChange = (e) => this.setState( {[e.target.name]: e.target.value });

	onSelectChange = (field, e) => {
		this.setState({[field]: e});
	}

	onDateChange = (field, date) => {
		this.setState({  [field]:date   });  
	}
	
	onChangeFile = (e) => {
        document.getElementById('attachment').disabled = "disabled";
        let attachments = this.state.attachments;
        var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.concat(FILE_TYPES).indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/file', data, AuthService.getAuthHeader())
		    	.then(res => {			
					let obj = {
                        name: res.data.fileName,
                        originalFilename: res.data.originalFilename,
                        type: res.data.fileType,
                        size: res.data.size
                    }
                    attachments.push(obj);
                    this.setState({attachments});
                    document.getElementById('attachment').value = null;
                    document.getElementById('attachment').disabled = "";
					ToastsStore.success("File added!", 5000);
				});
		} else {
			ToastsStore.error("Extension: not allowed: " + ext, 5000);
			document.getElementById('attachment').value = null;
			document.getElementById('attachment').disabled = "";
			console.error("Extension: not allowed: " + ext)
		}
    }

	removeAttachment = (index) => {
        let attachments = this.state.attachments;
		let attachmentsToDelete = this.state.attachmentsToDelete;
		if ( typeof attachments[index].id !== 'undefined' ) {
			attachmentsToDelete.push(attachments[index].id);
		}
        attachments.splice(index, 1);
        this.setState({attachments, attachmentsToDelete});
		ToastsStore.success("File removed!", 5000);
    }

	onItemInputChange = (index, e) => {
		let items = this.state.items;
        items[index][e.target.name] = e.target.value;
        this.setState({items});
	}

    onQuantityChange = (index, e) => {
        let items = this.state.items;
        items[index].quantity = parseInt(e.target.value);
        this.setState({items});
        this.calculate();
    }

    onPriceChange = (index, e) => {
        let items = this.state.items;
        items[index][e.target.name] = parseFloat(e.target.value);
        this.setState({items});
        this.calculate();
    }

	onCustomerTaxChange = (index, e) => {
		let items = this.state.items;
        items[index].customerTax = e;
        this.setState({items});
        this.calculate();
    }

	onSupplierTaxChange = (index, e) => {
		let items = this.state.items;
        items[index].supplierTax = e;
        this.setState({items});
        this.calculate();
    }

    onAddClick = () => {
        let items = this.state.items;
        items.push({
			id: null,
			reference: '',
			name: '',
			quantity: 1,
			purchasePrice: 0,
			salePrice: 0,
			customerTax: null,
			supplierTax: null,
			total: 0
		});
        this.setState({items});
        this.calculate();
    }

    onRemoveClick = (index) => {
		if (this.state.items.length > 1) {
			let servicesToDelete = this.state.servicesToDelete;
			let items = this.state.items;
			if ( items[index].id !== null) {
				servicesToDelete.push(items[index].id);
			}
			items.splice(index, 1);
			this.setState({items, servicesToDelete});
			this.calculate();
		} else {
			ToastsStore.error(t("At least one service is reqired!"), 5000);
		}
    }

	calculate() {
        var objectConstructor = ({}).constructor;
        let subtotal = 0;
        let tax = 0;
        let total = 0;
        let discount = 0;
        const items = this.state.items;
        // let discountType = $('#discountType').val();
        // discountType = (discountType !== null ? parseInt(discountType) : DISCOUNT_FIXED);
        // let discountValue = $('#discountValue').val();

		let discountValue = "0";
        discountValue = ((discountValue !== null && discountValue !== "") ? parseInt(discountValue) : 0.0);
        items.forEach((element, ix) => {
            subtotal += element.salePrice * element.quantity;
            let itemTax = 0;
            if (typeof element.customerTax !== 'undefined' && element.customerTax !== null) {
				var rate = element.customerTax.value2;
				itemTax = (element.salePrice * ( rate / 100));
            } 
            tax += (itemTax * element.quantity);
            items[ix].total = ((element.salePrice * element.quantity) + (itemTax * element.quantity)).toFixed(2);
        });

        this.setState({
            subtotal: subtotal.toFixed(2),
            tax: tax.toFixed(2),
            discount: discount.toFixed(2),
            total: (subtotal + tax - discount).toFixed(2),
            items
        })
    }

	onSubmit = (e) => {
		e.preventDefault();
		if (this.validator.allValid()) {
			let services = this.state.items;
			for (let i =0; i < services.length; i++) {
				services[i].customerTax = services[i].customerTax.value;
				services[i].supplierTax = services[i].supplierTax.value;
			}
			
			var agreement = {
				customer: this.state.customer.value,
				customerCategory: this.state.customerCategory.value,
				supplier: this.state.supplier.value,
				supplierCategory: this.state.supplierCategory.value,
				currency: this.state.currency.value,
				number: this.state.number,
				start: Math.floor( this.state.start.getTime() / 1000 ),
				end: Math.floor( this.state.end.getTime() / 1000 ),
				status: this.state.status,
				notes: this.state.notes,
				services: this.state.items,
				attachments: this.state.attachments,
				servicesToDelete: this.state.servicesToDelete,
				attachmentsToDelete: this.state.attachmentsToDelete
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.id !== null) {
				promise = this.props.updateItem('/agreements/'+this.state.id, agreement);				
			} else {
				promise = this.props.createItem('/agreements', agreement);
			}
			
			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit agreement'):t('Add New agreement')} </div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
									
										<div className="form-group row">
											<label htmlFor="customer" className="col-sm-3 col-form-label">{t("Customer")}</label>
											<div className="col-sm-9">
												<Select 
													name="customer"
													value={this.state.customer}
													id="customer"
													onChange={(e) => this.onSelectChange('customer', e)}
													options = {this.props.customers} 
												/>
												{this.validator.message('customer', this.state.customer, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="customerCategory" className="col-sm-3 col-form-label">{t("Customer Category")}</label>
											<div className="col-sm-9">
												<Select 
													name="customerCategory"
													value={this.state.customerCategory}
													id="customerCategory"
													onChange={(e) => this.onSelectChange('customerCategory', e)}
													options = {this.props.creditCategories} 
												/>
												{this.validator.message('customerCategory', this.state.customerCategory, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="supplier" className="col-sm-3 col-form-label">{t("Supplier")}</label>
											<div className="col-sm-9">
												<Select 
													name="supplier"
													value={this.state.supplier}
													id="supplier"
													onChange={(e) => this.onSelectChange('supplier', e)}
													options = {this.props.suppliers} 
												/>
												{this.validator.message('supplier', this.state.supplier, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="supplierCategory" className="col-sm-3 col-form-label">{t("Supplier Category")}</label>
											<div className="col-sm-9">
												<Select 
													name="supplierCategory"
													value={this.state.supplierCategory}
													id="supplierCategory"
													onChange={(e) => this.onSelectChange('supplierCategory', e)}
													options = {this.props.debitCategories} 
												/>
												{this.validator.message('supplierCategory', this.state.supplierCategory, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="currency" className="col-sm-3 col-form-label">{t("Currency")}</label>
											<div className="col-sm-9">
												<Select 
													name="currency"
													value={this.state.currency}
													id="currency"
													onChange={(e) => this.onSelectChange('currency', e)}
													options = {this.props.currencies} 
												/>
												{this.validator.message('currency', this.state.currency, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="number" className="col-sm-3 col-form-label">{t("Agreement Number")}</label>
											<div className="col-sm-9">
												<input value={this.state.number} onChange={this.onChange} id="number" type="text" name="number" className="form-control" placeholder={t("Number")}></input>
												{this.validator.message('number', this.state.number, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="start" className="col-sm-3 col-form-label">{t("Start Date")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="input-group-prepend" >
														<span className="input-group-text"><i className="ti-calendar"></i></span>
													</div>
													<DatePicker
														name="start"
														locale="fr"
														className="form-control"
														dateFormat="yyyy-MM-dd"
														selected={this.state.start}
														onChange={(date) => this.onDateChange('start', date)}
													/>
												</div>
													{this.validator.message('start', this.state.start, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="end" className="col-sm-3 col-form-label">{t("End Date")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="input-group-prepend" >
														<span className="input-group-text"><i className="ti-calendar"></i></span>
													</div>
													<DatePicker
														name="end"
														locale="fr"
														className="form-control"
														dateFormat="yyyy-MM-dd"
														selected={this.state.end}
														onChange={(date) => this.onDateChange('end', date)}
													/>
												</div>
													{this.validator.message('end', this.state.end, 'required')}
											</div>
										</div>


										<div className="row">
                                                
											<div className="col-12 mt-3 table-responsive">
												<table className="table table-vertical table-bordered">
													<thead className="thead-light">
														<tr>
															<th className="text-left col-1">{t("Action")}</th>
															<th className="text-left col-md-auto">{t("Reference")}</th>
															<th className="text-left col-md-1">{t("Name")}</th>
															<th className="text-center col-md-1">{t("Quantity")}</th>
															<th className="col-md-2">{t("Purchase Price")}</th>
															<th className="col-md-2">{t("Sale Price")}</th>
															<th className="col-md-2">{t("Customer Tax")}</th>
															<th className="col-md-2">{t("Supplier Tax")}</th>
															{/* <th className="col-md-2">{t("Total")}</th> */}
														</tr>
													</thead>
													<tbody>

														{this.state.items.map((item, index) => {                                        
															return <ItemRow 
																key={index}
																index={index}
																item={item}
																removeable={(index > 0)}
																onItemInputChange={this.onItemInputChange}
																onRemoveClick={this.onRemoveClick}
																onItemChange={this.onItemChange}
																onCustomerTaxChange={this.onCustomerTaxChange}
																onSupplierTaxChange={this.onSupplierTaxChange}
																onQuantityChange={this.onQuantityChange}
																onPriceChange={this.onPriceChange}
															/>
														})}

														<tr>
															<td><button onClick={this.onAddClick.bind(this)} type="button" className="btn btn-primary waves-light waves-effect" title="Add"><i className="ti-plus"></i></button></td>
															<td colSpan="7">&nbsp;</td>
														</tr>
														{/* <tr className="text-right">
															<td colSpan="8" className="font-weight-bold">{t("Subtotal")}</td>
															<td>{formatAmount(this.state.subtotal, this.props.globals.currency)}</td>
														</tr> */}
														{/* <tr className="text-right">
															<td colSpan="8" className="font-weight-bold">{t("Tax")}</td>
															<td>{formatAmount(this.state.tax, this.props.globals.currency)}</td>
														</tr>
														<tr className="text-right font-weight-bold">
															<td colSpan="8">{t("Total")}</td>
															<td>{formatAmount(this.state.total, this.props.globals.currency)}</td>
														</tr> */}
													</tbody>
												</table>
											</div>
										</div>


										<div className="form-group row">
											<label htmlFor="notes" className="col-sm-3 col-form-label">{t("Note")}</label>
											<div className="col-sm-9">
												<textarea value={this.state.notes} onChange={this.onChange} id="notes" name="notes" className="form-control" placeholder={t("Notes")}></textarea>
												{/* {this.validator.message('notes', this.state.notes, 'required|string')} */}
											</div>
										</div>
													
										<div className="row">
											<label htmlFor="attachment" className="col-sm-3 col-form-label">{t("Attachment")}</label>
                                            <div className="col-9">
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                        multiple="multiple"
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="attachment"
                                                        onChange={this.onChangeFile}
                                                        aria-describedby="inputGroupFileAddon01" />
                                                        <label className="custom-file-label" htmlFor="attachment">
                                                        {t("Add attachment")}
                                                        </label>
                                                    </div>
                                                </div>
                                             </div>
                                        </div>
										<br />
                                        <div className="row">
                                            <div className="col-12">
                                                <ul className="list-group list-group-horizontal">
                                                    {this.state.attachments.map((obj, idx) => {                                                    
                                                        return <li key={idx} className="list-group-item">{obj.originalFilename}<span onClick={this.removeAttachment.bind(this, idx)} className="crossBtn">X</span></li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
										
										<div className="form-group row">
											<label htmlFor="status" className="col-sm-3 col-form-label">{t("Status")}</label>
											<div className="col-sm-9">
												<Select 
													name="status"
													value={this.state.statuses.filter(s => s.value === this.state.status)[0]}
													id="status"
													onChange={(e) => this.onSelectChange('status', e.value)}
													options = {this.state.statuses} 
												/>
												{this.validator.message('status', this.state.status, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.agreement.needComponentUpdate,
	customers: state.customers.options,
	currencies: state.currency.options,
	suppliers: state.suppliers.options,
	creditCategories: state.category.creditCategories,
	debitCategories: state.category.debitCategories,
	taxes: state.tax.options,
	globals: state.dash.globals,
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate, loadCustomers, loadCreditCategories, loadDebitCategories, loadCurrencies, loadTaxes, loadGlobals, loadSuppliers}) (AgreementsForm);