import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';

import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/InvoiceActions';
import { t } from '../../../components/Translation';
import { formatDate, formatAmount } from '../../../helper';
import { loadGlobals } from '../../../store/DashboardActions';

class Invoices extends Component {

    state = {
      alert: null,
        baseUrl: '/invoices',
        addBtn: {
            label: t('Create new invoice'),
            url: this.props.formPath + "/create"
        },
        columns: [
            {
              label: '#',
              field: 'serial',
              sort: false,
            },
            {
              label: t('Id'),
              field: 'id',
              sort: false
            },
            {
              label: t('Number'),
              field: 'number',
              sort: true,
              sortCol: 'invoicenumber',
            },
            {
              label: t('Customer'),
              field: 'customer',
              sort: false,
              sortCol: 'customerName',
            },
            {
              label: t('Agreement'),
              field: 'agreement',
              sort: true,
              sortCol: 'contractNumber',
            },
            {
              label: t('Amount'),
              field: 'total',
              sort: true,
              sortCol: 'total',
            },
            {
              label: t('Invoice Date'),
              field: 'issuedate',
              sort: true,
              sortCol: 'issuedate',
            },
            {
              label: t('Due Date'),
              field: 'duedate',
              sort: true,
              sortCol: 'duedate',
            },
            {
              label: t('Status'),
              field: 'status',
              sort: true,
              sortCol: 'status',
            },
            {
              label: t('Action'),
              field: 'action',
              sort: false,
            }
        ],
        statuses: [
          {
            label: t("All"),
            value: null,
            class: "btn-outline-dark"
          },
          {
            label: t("Draft"),
            value: 0,
            class: "btn-outline-dark"
          },
          {
            label: t("Sent"),
            value: 1,
            class: "btn-outline-warning"
          },
          {
            label: t("Cancelled"),
            value: 2,
            class: "btn-outline-danger"
          },
          {
            label: t("Partial Paid"),
            value: 3,
            class: "btn-outline-warning"
          },
          {
            label: t("Paid"),
            value: 4,
            class: "btn-outline-success"
          },
          {
            label: t("Overdue"),
            value: 5,
            class: "btn-outline-danger"
          }
        ],
    }

    deleteItem = (id) => {		
      this.props.deleteItem(this.state.baseUrl + '/' + id);
    }

    mapAttributes(rows) {
        var items = [];
        for (const [index, value] of rows.entries()) {
            var item = {
                'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
                'id': value.id,
                'number': value.invoiceNumber,
                'customer': value.customer.label.Capitalize(),
                'agreement': value.agreement.label.Capitalize(),
                'total': formatAmount(value.total, this.props.globals.currency),
                'issuedate': formatDate(value.issuedate, this.props.globals.dateFormat),
                'duedate': formatDate(value.duedate, this.props.globals.dateFormat),
                'status': <Status type="invoice" value={value.status} />,
                'actions': <Action type="invoice" state={value} path={this.props.formPath} onDelete={this.deleteItem} />
            };
            items.push( item );
        }
        return items;
    }

    render() {

        let rows = this.mapAttributes(this.props.rows);

        return(
            <AUX>
                <div className="container-fluid">  

                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <Tbl 
                                        baseUrl={this.state.baseUrl}
                                        addBtn={this.state.addBtn}
                                        statuses={this.state.statuses}
                                        columns={this.state.columns}
                                        rows={rows}
                                    />	
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>    
            </AUX>
        );
    }
}

const mapStateToProps = state => ({
  rows: state.invoice.rows,
  globals: state.dash.globals,
  pagination: state.invoice.pagination,
	pageSize: state.invoice.pageSize,
})

export default connect( mapStateToProps, {loadGlobals, deleteItem} ) ( Invoices );