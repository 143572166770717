import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/fullcalendar/dist/fullcalendar.min.js';
import { createStore , combineReducers, applyMiddleware, compose } from 'redux';
import  UIreducer from './store/reducers/reducer';
import  Tinyreducer from './store/reducers/TinychartReducer';
import MessageReducer from './store/reducers/MessageReducer';
import ConfigReducer from './store/reducers/ConfigReducer';


import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import AccountReducer from './store/reducers/AccountReducer';
import TimesheetReducer from './store/reducers/TimesheetReducer';
import TransfersReducer from './store/reducers/TransfersReducer';
import TransactionsReducer from './store/reducers/TransactionsReducer';
import CategoryReducer from './store/reducers/CategoryReducer';
import CompanyReducer from './store/reducers/CompanyReducer';
import CurrencyReducer from './store/reducers/CurrencyReducer';
import CustomersReducer from './store/reducers/CustomersReducer';
import SuppliersReducer from './store/reducers/SuppliersReducer';
import VendorsReducer from './store/reducers/VendorsReducer';
import ExpenseReducer from './store/reducers/ExpenseReducer';
import SupplierPaymentReducer from './store/reducers/SupplierPaymentReducer';
import VendorPaymentReducer from './store/reducers/VendorPaymentReducer';
import ItemReducer from './store/reducers/ItemReducer';
import LanguageReducer from './store/reducers/LanguageReducer';
import PermissionReducer from './store/reducers/PermissionReducer';
import ApiPermissionReducer from './store/reducers/ApiPermissionReducer';
import PaymentMethodReducer from './store/reducers/PaymentMethodReducer';
import RoleReducer from './store/reducers/RoleReducer';
import TaxReducer from './store/reducers/TaxReducer';
import UserReducer from './store/reducers/UserReducer';
import InvoiceReducer from './store/reducers/InvoiceReducer';
import RevenueReducer from './store/reducers/RevenueReducer';
import RevenueFormReducer from './store/reducers/RevenueFormReducer';
import AgreementReducer from './store/reducers/AgreementReducer';

import DashboardReducer from './store/reducers/DashboardReducer';


const middleware = [thunk];

const rootReducer = combineReducers({
    ui_red:UIreducer,
    tinyCharts:Tinyreducer,
    msg_store: MessageReducer,
    dash: DashboardReducer,
    
    config: ConfigReducer,

    account: AccountReducer,
    timesheet: TimesheetReducer,
    transfer: TransfersReducer,
    transactions: TransactionsReducer,
    category: CategoryReducer,
    company: CompanyReducer,
    currency: CurrencyReducer,
    customers: CustomersReducer,
    suppliers: SuppliersReducer,
    vendors: VendorsReducer,
    expense: ExpenseReducer,
    supplierpayment: SupplierPaymentReducer,
    vendorpayment: VendorPaymentReducer,

    item: ItemReducer,
    language: LanguageReducer,
    paymentmethod: PaymentMethodReducer,
    role: RoleReducer,
    tax: TaxReducer,
    user: UserReducer,
    permission: PermissionReducer,
    apipermission: ApiPermissionReducer,
    invoice: InvoiceReducer,
    revenue: RevenueReducer,
    revenueForm: RevenueFormReducer,
    agreement: AgreementReducer,
});

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(
            /* ----  middlewares ----  */
           ...middleware
        ),
    //    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    )
);
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
