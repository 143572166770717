import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import AuthService from '../../containers/Auth/AuthService';

import '../../components/Text/String';

import TinyCharts from './TinyCharts';
import {listFolders} from '../../store/MessageActions';
import { listAllowedPermissions } from '../../store/PermissionActions';
import {connect} from 'react-redux';

import {t} from '../Translation';

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Tab: '', SubTab: '', MoreTab: '', inbox:{uid:"", messages:0}
        };
    }

    componentDidMount() {
        this.props.listAllowedPermissions();
        $('.navigation-menu li.has-submenu #pmenu').on('click', function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });
        
        if (this.state.inbox.uid === "" && AuthService.loggedIn()) {
            this.props.listFolders();            
        }
    }

    componentDidUpdate() {
        if (this.props.folders.length > 0 && this.state.inbox.uid === "") {
            this.setState({inbox: this.props.folders[0]});
        }
    }

    setActiveTab = (tab,subtab,moretab, e)=> {
        this.setState({Tab: tab,SubTab: subtab,MoreTab: moretab});
    }

    can = (route) => {
        var res = this.props.permissions.filter(p => p === route);
        return (res.length > 0);
    }

    render() {

        return (
            <div>

                <div className="container-fluid">
                    <div className="row">                   
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                {this.state.Tab === 'dashboard' || this.state.Tab === ''  ?
                                <div>
                                    <h4 className="page-title">{t('Dashboard')}</h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active">
                                            {t("Welcome to Smart Office Dashboard")}
                                        </li>
                                    </ol>
                                </div>
                                : 
                                    <div>
                                        <h4 className="page-title">{this.state.Tab.Capitalize()} {this.state.SubTab}</h4>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="javascript:void(0);">Smart Office</a></li>
                                            <li className="breadcrumb-item"><a href="javascript:void(0);">{this.state.Tab}</a></li>
                                            <li className="breadcrumb-item active">{this.state.SubTab}</li>
                                        </ol>
                                    </div>
                                }
                                    
                                <TinyCharts />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="navbar-custom">
                    <div className="container-fluid">
                        <div id="navigation">
                            <ul className="navigation-menu">

                                <li className={this.state.Tab === 'dashboard' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'dashboard','','')}>
                                    <Link to="/" id="pmenu">
                                        <i className="ti-dashboard"></i>
                                        <span>{t("Dashboard")}</span>
                                    </Link>
                                </li>

                                {(this.can("/message/:box")) && 
                                <li className={this.state.Tab === 'inbox' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'message','inbox','')}>
                                    <Link to={"/message/"+this.state.inbox.uid} id="pmenu">
                                        <i className="ti-comments"></i>
                                        <span>{t("Inbox")}</span> {(this.state.inbox.messages > 0 ? <span className="badge badge-pill badge-danger noti-icon-badge">{this.state.inbox.messages}</span> : "")}</Link>
                                </li>}

                                {(this.can("/income/invoices") || this.can("/income/revenues")) && 
                                <li className={this.state.Tab === 'income' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="pmenu"><i className="ti-shopping-cart"></i><span>{t("Incomes")}</span></Link>
                                    <ul className="submenu">
                                        {(this.can("/income/invoices")) && <li className={this.state.SubTab === 'invoice' ? 'active':''} onClick={this.setActiveTab.bind(this, 'income','invoice','')}><Link to="/income/invoices"><span>{t("Invoices")}</span></Link></li>}
                                        {(this.can("/income/revenues")) && <li className={this.state.SubTab === 'revenue' ? 'active':''} onClick={this.setActiveTab.bind(this, 'income','revenue','')}><Link to="/income/revenues"><span>{t("Revenues")}</span></Link></li>}
                                    </ul>
                                </li>}

                                {(this.can("/payments/suppliers") || this.can("/payments/vendors") || this.can("/payments/expenses")) && 
                                <li className={this.state.Tab === 'payment' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="pmenu"><i className="ti-money"></i><span>Payments</span></Link>
                                    <ul className="submenu">
                                        {(this.can("/payments/suppliers")) && <li className={this.state.SubTab === 'suppliers' ? 'active':''} onClick={this.setActiveTab.bind(this, 'payments','suppliers','')}><Link to="/payments/suppliers"><span>{t("Supplier")}</span></Link></li>}
                                        {(this.can("/payments/vendors")) && <li className={this.state.SubTab === 'vendors' ? 'active':''} onClick={this.setActiveTab.bind(this, 'payments','vendors','')}><Link to="/payments/vendors"><span>{t("Vendors")}</span></Link></li>}
                                        {(this.can("/payments/expenses")) && <li className={this.state.SubTab === 'expense' ? 'active':''} onClick={this.setActiveTab.bind(this, 'payments','expenses','')}><Link to="/payments/expenses"><span>{t("Expenses")}</span></Link></li>}
                                    </ul>
                                </li>}

                                {(this.can("/banking/accounts") || this.can("/banking/transfers") || this.can("/banking/transactions")) && 
                                <li className={this.state.Tab === 'banking' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="pmenu"><i className="ti-wallet"></i><span>{t("Banking")}</span></Link>
                                    <ul className="submenu">
                                        {(this.can("/banking/accounts")) && <li className={this.state.SubTab === 'account' ? 'active':''} onClick={this.setActiveTab.bind(this, 'banking','account','')}><Link to="/banking/accounts"><span>{t("Accounts")}</span></Link></li>}
                                        {(this.can("/banking/transfers")) && <li className={this.state.SubTab === 'transfers' ? 'active':''} onClick={this.setActiveTab.bind(this, 'banking','transfers','')}><Link to="/banking/transfers"><span>{t("Transfers")}</span></Link></li>}
                                        {(this.can("/banking/transactions")) && <li className={this.state.SubTab === 'transactions' ? 'active':''} onClick={this.setActiveTab.bind(this, 'banking','transactions','')}><Link to="/banking/transactions"><span>{t("Transactions")}</span></Link></li>}
                                    </ul>
                                </li>}

                                {(this.can("/dashboard/timesheet")) && 
                                <li className={this.state.Tab === 'timesheet' ? 'has-submenu active':'has-submenu'} onClick={this.setActiveTab.bind(this, 'timesheet','','')}>
                                    <Link to="/timesheet" id="pmenu">
                                        <i className="ti-calendar"></i>
                                        <span>{t("Timesheet")}</span> {/* <span className="badge badge-pill badge-danger noti-icon-badge">11</span> */}
                                    </Link>
                                </li>}

                                {(
                                    this.can("/business/agreements")
                                    || this.can("/business/customers")
                                    || this.can("/business/suppliers")
                                    || this.can("/business/vendors")
                                    || this.can("/business/categories")
                                    || this.can("/business/currencies")
                                    || this.can("/business/taxes")
                                    || this.can("/business/payments")
                                ) &&
                                <li className={this.state.Tab === 'business' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="pmenu"><i className="ti-briefcase"></i><span>{t("Business")}</span></Link>
                                    <ul className="submenu">
                                        {(this.can("/business/agreements")) && <li className={this.state.SubTab === 'agreements' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','agreements','')}><Link to="/business/agreements"><span>{t("Agreements")}</span></Link></li>}
                                        {(
                                            this.can("/business/customers")
                                            || this.can("/business/suppliers")
                                            || this.can("/business/vendors")
                                        ) &&
                                        <li className={this.state.Tab === 'contact' ? 'has-submenu active':'has-submenu'}>
                                            <Link to="javascript:void(0);" id="pmenu"><span>{t("Contacts")}</span></Link>
                                            <ul className="submenu">
                                                {(this.can("/business/customers")) && <li className={this.state.SubTab === 'customer' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','customer','')}><Link to="/business/customers"><span>{t("Customers")}</span></Link></li>}
                                                {(this.can("/business/suppliers")) && <li className={this.state.SubTab === 'supplier' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','supplier','')}><Link to="/business/suppliers"><span>{t("Suppliers")}</span></Link></li>}
                                                {(this.can("/business/vendors")) && <li className={this.state.SubTab === 'vendor' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','vendor','')}><Link to="/business/vendors"><span>{t("Vendors")}</span></Link></li>}
                                            </ul>
                                        </li>}

                                        {(this.can("/business/customers")) && <li className={this.state.SubTab === 'category' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','category','')}><Link to="/business/categories"><span>{t("Categories")}</span></Link></li>}
                                        {(
                                            this.can("/business/currencies")
                                            || this.can("/business/taxes")
                                            || this.can("/business/payments")
                                        ) &&
                                        <li className={this.state.Tab === 'settings' ? 'has-submenu active':'has-submenu'}>
                                            <Link to="javascript:void(0);" id="pmenu"><span>Settings</span></Link>
                                            <ul className="submenu">
                                                {(this.can("/business/currencies")) && <li className={this.state.SubTab === 'currency' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','currency','')}><Link to="/business/currencies"><span>{t("Currencies")}</span></Link></li>}
                                                {(this.can("/business/taxes")) && <li className={this.state.SubTab === 'tax' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','tax','')}><Link to="/business/taxes"><span>{t("Taxes")}</span></Link></li>}
                                                {(this.can("/business/payments")) && <li className={this.state.SubTab === 'payment' ? 'active':''} onClick={this.setActiveTab.bind(this, 'business','payment','')}><Link to="/business/payments"><span>{t("Payments")}</span></Link></li>}
                                            </ul>
                                        </li>}
                                    </ul>
                                </li>}

                                {(
                                    this.can("/reports/income")
                                    || this.can("/reports/payment")
                                    || this.can("/reports/profit")
                                    || this.can("/reports")
                                ) &&
                                <li className={this.state.Tab === 'report' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="pmenu"><i className="ti-stats-up"></i><span>{t("Reports")}</span></Link>
                                    <ul className="submenu">
                                        {(this.can("/reports/income")) && <li  className={this.state.SubTab === 'income' ? 'active':''} onClick={this.setActiveTab.bind(this, 'report','income','')}><Link to="/reports/income"><span>{t("Income summary")}</span></Link></li>}
                                        {(this.can("/reports/payment")) && <li  className={this.state.SubTab === 'payment' ? 'active':''} onClick={this.setActiveTab.bind(this, 'report','payment','')}><Link to="/reports/payment"><span>{t("Payment summary")}</span></Link></li>}
                                        {(this.can("/reports/profit")) && <li  className={this.state.SubTab === 'profit' ? 'active':''} onClick={this.setActiveTab.bind(this, 'report','profit','')}><Link to="/reports/profit"><span>{t("Profit summary")}</span></Link></li>}
                                        {(this.can("/reports")) && <li  className={this.state.SubTab === 'global' ? 'active':''} onClick={this.setActiveTab.bind(this, 'report','global','')}><Link to="/reports"><span>{t("Global")}</span></Link></li>}
                                    </ul>
                                </li>}

                                {(
                                    this.can("/settings/companyinfo")
                                    || this.can("/settings/localization")
                                    || this.can("/settings/invoice")
                                    || this.can("/settings/agreement")
                                    || this.can("/settings/contacts")
                                    || this.can("/settings/timesheet")
                                    || this.can("/settings/users")
                                    || this.can("/settings/permission")
                                    || this.can("/settings/apipermission")
                                    || this.can("/settings/roles")
                                    || this.can("/settings/languages")
                                    || this.can("/settings/companies")
                                    || this.can("/settings/emailtemplates")
                                ) &&
                                <li className={this.state.Tab === 'setting' ? 'has-submenu active':'has-submenu'}>
                                    <Link to="javascript:void(0);" id="pmenu"><i className="ti-settings"></i><span>{t("Settings")}</span></Link>
                                    <ul className="submenu">
                                        {(this.can("/settings/companyinfo")) && <li  className={this.state.SubTab === 'my company' ? 'active':''} onClick={this.setActiveTab.bind(this, 'my company','')}><Link to="/settings/companyinfo"><span>{t("My Company")}</span></Link></li>}
										{(this.can("/settings/localization")) && <li  className={this.state.SubTab === 'localization' ? 'active':''} onClick={this.setActiveTab.bind(this, 'localization','')}><Link to="/settings/localization"><span>{t("Localization")}</span></Link></li>}
                                        {(this.can("/settings/invoice")) && <li  className={this.state.SubTab === 'invoice' ? 'active':''} onClick={this.setActiveTab.bind(this, 'invoice','')}><Link to="/settings/invoice"><span>{t("Invoice")}</span></Link></li>}
                                        {(this.can("/settings/agreement")) && <li  className={this.state.SubTab === 'agreement' ? 'active':''} onClick={this.setActiveTab.bind(this, 'agreement','')}><Link to="/settings/agreement"><span>{t("Agreement")}</span></Link></li>}
                                        {(this.can("/settings/contacts")) && <li  className={this.state.SubTab === 'contacts' ? 'active':''} onClick={this.setActiveTab.bind(this, 'contacts','')}><Link to="/settings/contacts"><span>{t("Contacts")}</span></Link></li>}
                                        {(this.can("/settings/timesheet")) && <li  className={this.state.SubTab === 'timesheet' ? 'active':''} onClick={this.setActiveTab.bind(this, 'timesheet','')}><Link to="/settings/timesheet"><span>{t("Timesheet")}</span></Link></li>}
                                        {(this.can("/settings/users")) && <li  className={this.state.SubTab === 'user' ? 'active':''} onClick={this.setActiveTab.bind(this, 'user','')}><Link to="/settings/users"><span>{t("Users")}</span></Link></li>}
										
                                        {(
                                            this.can("/settings/permission")
                                            || this.can("/settings/apipermission")
                                            || this.can("/settings/roles")
                                        ) &&
                                        <li className={this.state.Tab === 'rbac' ? 'has-submenu active':'has-submenu'}>
                                            <Link to="javascript:void(0);" id="pmenu"><span>{t("RBAC")}</span></Link>
                                            <ul className="submenu submenu-left">
                                                {(this.can("/settings/permission")) && <li  className={this.state.SubTab === 'permission' ? 'active':''} onClick={this.setActiveTab.bind(this, 'setting','permission','')}><Link to="/settings/permission"><span>{t("Web Permission")}</span></Link></li>}
                                                {(this.can("/settings/apipermission")) && <li  className={this.state.SubTab === 'apipermission' ? 'active':''} onClick={this.setActiveTab.bind(this, 'setting','apipermission','')}><Link to="/settings/apipermission"><span>{t("Api Permission")}</span></Link></li>}
                                                {(this.can("/settings/roles")) && <li  className={this.state.SubTab === 'role' ? 'active':''} onClick={this.setActiveTab.bind(this, 'setting','role','')}><Link to="/settings/roles"><span>{t("Roles")}</span></Link></li>}
                                            </ul>
                                        </li>}

                                        {(this.can("/settings/languages")) && <li  className={this.state.SubTab === 'language' ? 'active':''} onClick={this.setActiveTab.bind(this, 'setting','language','')}><Link to="/settings/languages"><span>{t("Languages")}</span></Link></li>}
										{(this.can("/settings/companies")) && <li  className={this.state.SubTab === 'company' ? 'active':''} onClick={this.setActiveTab.bind(this, 'setting','company','')}><Link to="/settings/companies"><span>{t("Companies")}</span></Link></li>}
										{(this.can("/settings/emailtemplates")) && <li  className={this.state.SubTab === 'emailtemplate' ? 'active':''} onClick={this.setActiveTab.bind(this, 'setting','emailtemplate','')}><Link to="/settings/emailtemplates"><span>{t("Emails")}</span></Link></li>}
                                    </ul>
                                </li>}

                            </ul>
                        </div>
                    </div> 
                </div>

           </div>                         
        );
    }
}

const mapStateToProps = state => ({
    folders: state.msg_store.folders,
    permissions: state.permission.allowedPermissions
});

export default connect(mapStateToProps, {listFolders, listAllowedPermissions}) (Menu);