import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

import {listFolders, listLabels, listMessages, loadMessagePage, markImportant, setLabelNull} from '../../../store/MessageActions';
import AuthService from '../../Auth/AuthService';

import Left from './Left';
import Toolbar from './Toolbar';
import RowItem from './RowItem';
import { t } from '../../../components/Translation';

class Inbox extends Component {
    componentDidUpdate() {
        let box = this.props.match.params.box;
        if (box !== this.props.currentFolder) {
            this.props.setLabelNull();
            this.props.listMessages(box, this.props.pageSize);
        }
    }

    componentDidMount() {
        let box = this.props.match.params.box;
        this.props.listFolders();
        this.props.listLabels();
        this.props.setLabelNull();
        this.props.listMessages(box, this.props.pageSize);
    }

    onToggleSingleImportant = (uid, important) => {
        this.props.markImportant((important ? 1 : 0), [uid], "list");
    }

    onClickLeft = () => {
        this.props.loadMessagePage(this.props.currentFolder, this.props.pageSize, (this.props.messages.page-1), this.props.currentLabel);
    }

    onClickRight = () => {
        this.props.loadMessagePage(this.props.currentFolder, this.props.pageSize, (this.props.messages.page+1), this.props.currentLabel);
    }

    render() {
        let userUid = AuthService.getUid();

        return (
            
            <AUX>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="email-leftbar card">
                                <Left 
                                    type="list"
                                    box={this.props.match.params.box} 
                                    folders={this.props.folders} 
                                    labels={this.props.labels}                                  
                                    />
                            </div>

                            <div className="email-rightbar mb-3">
                                
                                <div className="card">
                                    
                                    <Toolbar type="list" labels={this.props.labels} folders={this.props.folders} />

                                    <ul className="message-list">
                                        {this.props.messages.rows.length > 0 ? 
                                        this.props.messages.rows.map((value, index) => {
                                            return <RowItem
                                                key={index}
                                                row={value}
                                                userUid={userUid}                                                
                                                labels={this.props.labels}
                                                folders={this.props.folders}
                                                currentFolder={this.props.currentFolder}
                                                onToggleSingleImportant={this.onToggleSingleImportant}
                                               
                                            />
                                        }) : <li style={{textAlign:'center'}}>{t("No rows found")}!</li> }

                                    </ul>
                                </div> 

                                {this.props.messages.pages > 1 ? 
                                <div className="row m-t-20">
                                    <div className="col-7">
                                        Showing {this.props.messages.page * this.props.messages.size + 1} - {this.props.messages.page * this.props.messages.size + this.props.messages.rows.length} of {this.props.messages.total}
                                    </div>
                                    <div className="col-5">
                                        <div className="btn-group float-right">
                                            <button disabled={(this.props.messages.page===0) ? "disabled": ""} onClick={this.onClickLeft.bind(this)} type="button" className="btn btn-sm btn-secondary waves-effect"><i className="fa fa-chevron-left"></i></button>
                                            <button disabled={(this.props.messages.last) ? "disabled": ""} onClick={this.onClickRight.bind(this)} type="button" className="btn btn-sm btn-secondary waves-effect"><i className="fa fa-chevron-right"></i></button>
                                        </div>
                                    </div>
                                </div> : ""}
                            </div> 
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

Inbox.propTypes = {
    listFolders: PropTypes.func.isRequired,
    folders: PropTypes.array.isRequired,
    listLabels: PropTypes.func.isRequired,
    labels: PropTypes.array.isRequired,
    listMessages: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({
    folders: state.msg_store.folders,
    labels: state.msg_store.labels,
    currentFolder: state.msg_store.currentFolder,
    currentLabel: state.msg_store.currentLabel,
    messages: state.msg_store.messages,
    pageSize: state.msg_store.pageSize,
    pagination: state.msg_store.pagination,
});

export default connect( mapStateToProps, {listFolders, listLabels, listMessages, loadMessagePage, markImportant, setLabelNull} ) ( Inbox );