import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';
import ImageString from '../../../components/UI/DataTable/ImageString';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/LanguageActions';
import { t } from '../../../components/Translation';

class LanguageList extends Component {
	state = {
		alert: null,
		baseUrl: '/languages',
		addBtn: {
			label: 'Create new language',
			url: this.props.formPath + '/create'
		},
		columns: [
			{
				label: '#',
				field: 'serial',
				sort: false
			},
			{
				label: 'Id',
				field: 'id',
				sort: false
			},
			{
				label: 'Name',
				field: 'name',
				sort: true
			},
			{
				label: 'Code',
				field: 'code',
				sort: true
			},
			{
				label: 'Is default?',
				field: 'isdefault',
				sort: true
			},
			{
				label: 'Status',
				field: 'status',
				sort: true
			},
			{
				label: 'Actions',
				field: 'actions',
				sort: false
			}
		],
		statuses: [
			{
				label: "All",
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: "Enabled",
				value: "enabled",
				class: "btn-outline-success"
			},
			{
				label: "Disabled",
				value: "disabled",
				class: "btn-outline-warning"
			}
		]
	}

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}
	
	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			  var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id': value.id,
				'name': <ImageString image={value.icon} string={value.name} />,
				'code': value.code,
				'isdefault': value.isdefault ? 'Yes':'No',
				'status': <Status type="language" value={value.status} />,
				'actions': <Action state={value} path={this.props.formPath} onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}

	render() {

		let rows = this.mapAttributes(this.props.rows);

		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-body">
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
                                        rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.language.rows,
	pagination: state.language.pagination,
	pageSize: state.language.pageSize,
})

export default connect( mapStateToProps, {deleteItem} ) ( LanguageList );