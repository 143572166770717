import * as actionTypes from '../action';
const initialState={
    currentFolder:null,
    currentLabel:null,
    folders:[],
    labels:[],
    messages:{rows:[]},
    message: {
        attachments: []
    },
    fromCompose:false,
    messageStatus: false,
    pageSize: 5
}

const messagereducer = (state=initialState,action) => {
    switch(action.type) {
        case actionTypes.LIST_FOLDERS: 
            return {
                ...state,
                folders:action.folders
            };
        case actionTypes.LIST_MESSAGES:
            return {
                ...state,
                currentLabel: null,
                messages: action.messages
            };
        case actionTypes.LOAD_MESSAGE_PAGE:
            return {
                ...state,
                currentLabel: action.currentLabel,
                messages: action.messages
            };
        case actionTypes.LIST_LABELS:
            return {
                ...state,
                labels:action.labels
            };
        case actionTypes.SET_FOLDER:
            return {
                ...state,
                currentFolder: action.currentFolder,
                fromCompose: action.fromCompose
            };
        case actionTypes.SET_LABEL:
            return {
                ...state,
                currentLabel: action.currentLabel,
                messages: action.messages
            }
        case actionTypes.LOAD_MESSAGE:
            return {
                ...state,
                message: action.message
            };
        case actionTypes.DELETE_MESSAGES:
            if (action.viewType === "list") {
                const messages = Object.assign([], state.messages);
                for (let i = 0; i < action.messageUids.length; i++) {
                    let msgIndex = messages.rows.findIndex(row => {return row.uid === action.messageUids[i]});
                    messages.rows.splice(msgIndex, 1);
                }
                return {
                    ...state,
                    messages
                }
            } else {
                return {
                    ...state,
                }
            }
        case actionTypes.SET_LABEL_TO_MESSAGE:
            if (action.viewType === "list") {
                const messages = Object.assign([], state.messages);
                for (let i = 0; i < action.messageUids.length; i++) {
                    let msgIndex = messages.rows.findIndex(row => {return row.uid === action.messageUids[i]});
                    messages.rows[msgIndex].labelId = action.labelId;
                }
                return {
                    ...state,
                    messages
                }
            } else {
                return {
                    ...state,
                }
            }
        case actionTypes.CREATE_LABEL: // Creates and set new label to messages.
        if (action.viewType === "list") {
            const labels = Object.assign([], state.labels);
            labels.push(action.label);

            const messages = Object.assign([], state.messages);
            for (let i = 0; i < action.messageUids.length; i++) {
                let msgIndex = messages.rows.findIndex(row => {return row.uid === action.messageUids[i]});
                messages.rows[msgIndex].labelId = action.label.id;
            }
            return {
                ...state,
                labels,
                messages
            }
        } else {
            return {
                ...state,
            }
        }
        case actionTypes.MARK_MESSAGES_READ_UNREAD:
            if (action.viewType === "list") {
                const messages = Object.assign([], state.messages);
                for (let i = 0; i < action.messageUids.length; i++) {
                    let msgIndex = messages.rows.findIndex(row => {return row.uid === action.messageUids[i]});
                    messages.rows[msgIndex].readStatus = (action.status === "read" ? 1 : 0);
                }
                return {
                    ...state,
                    messages
                }
            } else {
                return {
                    ...state,
                }
            }
        case actionTypes.MARK_IMPORTANT:
            if (action.viewType === "list") {
                const messages = Object.assign([], state.messages);
                for (let i = 0; i < action.messageUids.length; i++) {
                    let msgIndex = messages.rows.findIndex(row => {return row.uid === action.messageUids[i]});
                    messages.rows[msgIndex].important = action.important;
                }
                return {
                    ...state,
                    messages
                }
            } else {
                return {
                    ...state,
                }
            }
        case actionTypes.SEND_MESSAGE:
            return {
                ...state,
                messageStatus: action.status
            }
        default :
        return state;
    }

}

export default messagereducer;