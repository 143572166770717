import * as actionTypes from '../action';
const initialState = {
    bordered: true,
    striped: true,
    hover: true,
    searchable: true,
    query: "",
    pageSize: 20,
    pageSizeOptions: [10,20,30,40,50],
    pagination: {
        page: 0,
        total: 0,
        pages: 0,
        last: true
    },
    sort: {
        col: null,
        order: null
    },
    rows: [],
    status: null,
    needComponentUpdate: false,
    options: []
}

const SuppliersReducer = (state= initialState, action) => {
    switch(action.type) {
        case actionTypes.CRUD_SUPPLIERS_OPTIONS:            
            return {
                ...state,
                options: action.options
            };
        case actionTypes.CRUD_SUPPLIERS_LIST:            
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
            };
        case actionTypes.CRUD_SUPPLIERS_FILTER_STATUS:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                status: action.status
            };
        case actionTypes.CRUD_SUPPLIERS_FILTER_PAGESIZE:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                pageSize: action.pageSize
            };          
        case actionTypes.CRUD_SUPPLIERS_FILTER_KEYWORD:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                query: action.query
            };     
        case actionTypes.CRUD_SUPPLIERS_SORT_ITEMS:
            return {
                ...state,
                pagination: {
                    page:  action.data.page,
                    pages: action.data.pages,
                    total: action.data.total,
                    last:  action.data.last,
                },
                rows: action.data.rows,
                sort: action.sort
            };  
        case actionTypes.CRUD_SUPPLIERS_CREATE_ITEM:
        case actionTypes.CRUD_SUPPLIERS_UPDATE_ITEM:
        case actionTypes.CRUD_SUPPLIERS_DELETE_ITEM:
        case actionTypes.CRUD_SUPPLIERS_NEED_COMPONENT_SET:
            return {
                ...state,
                needComponentUpdate: action.needComponentUpdate
            }
        default :
            return state;
    }

}

export default SuppliersReducer;