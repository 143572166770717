import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';

import {TINY_CHART_DATA} from './action';

export const loadTinyCharts = (start, end) => dispatch => {
    return Axios.get(API_BASE_URL + '/dashboard/tinycharts?start='+start+'&end='+end, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: TINY_CHART_DATA,
        data: res.data,
    }));
}