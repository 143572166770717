import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import $ from 'jquery';
import '../../../components/Text/String';
import SweetAlert  from 'react-bootstrap-sweetalert';
import {Link, withRouter} from 'react-router-dom';
import {deleteMessages, setLabelToMessage, createNewLabel, setMessageReadStatus, markImportant} from '../../../store/MessageActions';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import { t } from '../../../components/Translation';

class Toolbar extends Component {
    
    state = {
        alert: null,
        isToggleOn: true,
    }

    toggleSelectCheckbox = () => {
        this.setState(state => ({
          isToggleOn: !state.isToggleOn
        }));

        $('input:checkbox').not(this).prop('checked', this.state.isToggleOn);
    }

    onSetLabel = (labelId) => {
        // console.log('mesage label set method callback');
        // console.log(labelId);
        let messageUids = this.getSelectedMessages();
        this.props.setLabelToMessage(labelId, messageUids, this.props.type);
        if (this.props.type === "read") {
            let folder = "/message/" + this.props.folders[0].uid;
            this.props.history.push(folder);               
        }
    }

    hideForm() {
		this.setState({   alert: null  });
	}

    onShowLabelForm = () => {
        // console.log('show label form called');
        const alertBox = () => (
            <SweetAlert
				input
                showCancel
                btnSize="sm"
				confirmBtnText={t("Submit")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Enter label name!")}
            	onCancel={() => this.hideForm()}
				onConfirm={(value) => {                    
                    let label = {name: value, color: 'gray'};
                    let messages = this.getSelectedMessages();
                    this.props.createNewLabel(label, messages, this.props.type);
                    if (this.props.type === "read") {
                        let folder = "/message/" + this.props.folders[0].uid;
                        this.props.history.push(folder);               
                    }
					this.hideForm();
				}}>
			</SweetAlert> 
		);
        this.setState({   alert: alertBox()  });
    }

    onAddNewLabel = (labelUid, messageUids) => {
        console.log('new label add function called');
    }

    getSelectedMessages() {
        let ids = new Array;
        if (this.props.type === "list") {
            let checkboxes = $('.message-list input[type^="checkbox"]:checked');
            for (var i=0; i<checkboxes.length; i++) {
                ids.push($(checkboxes[i]).data('id'));
            }
        } else {
            ids.push( this.props.message.uid );
        }
        return ids;
    }

    onClickMarkRead = (readStatus) => {
        let messages = this.getSelectedMessages();
        this.props.setMessageReadStatus(readStatus, messages, this.props.type);
        if (this.props.type === "read") {
            let folder = "/message/" + this.props.folders[0].uid;
            this.props.history.push(folder);               
        }
    }

    onClickMarkImportant = (important) => {
        let messages = this.getSelectedMessages();
        this.props.markImportant(important, messages, this.props.type);
        if (this.props.type === "read") {
            let folder = "/message/" + this.props.folders[0].uid;
            this.props.history.push(folder);               
        }
    }

    onClickDelete = () => {
        let messages = this.getSelectedMessages();
        this.props.deleteMessages(messages, this.props.type);
        if (this.props.type === "read") {
            let folder = "/message/" + this.props.folders[0].uid;
            this.props.history.push(folder);               
        }
    }

    render() {

        let msg = this.props.message;
        const replyTo = {
            pathname: "/message/compose",
            state: {
                labels: this.props.labels,
                folders: this.props.folders,
                to: ((this.props.type === "read") ? ((this.props.userUid === msg.receiverId) ? msg.senderEmail : msg.receiverEmail) : "")
            }
        }

        return (
            <AUX>
                {this.state.alert}
                <div className="btn-toolbar p-3" role="toolbar">
                    
                    <div className="btn-group mo-mb-2">
                        {this.props.type === 'read' ?

                        <Link to={replyTo} className="btn btn-outline-primary waves-light waves-effect" title={t("Reply")}><i className="mdi mdi-reply"></i> {t("Reply")}</Link>

                        // <button type="button" className="btn btn-outline-primary waves-light waves-effect" title="Reply"><i className="mdi mdi-reply"></i> Reply</button>
                        :
                        <button type="button" className="btn btn-outline-secondary waves-light waves-effect" title={t("Check/Unchek All")} onClick={this.toggleSelectCheckbox.bind(this)}><i className="ti-check"></i></button>
                        }
                        <button type="button" onClick={this.onClickDelete} className="btn btn-outline-danger waves-light waves-effect" title={t("Delete selected")}><i className="ti-trash"></i></button>
                    </div>
                    <div className="btn-group ml-1 mo-mb-2">
                        <button type="button" className="btn btn-outline-secondary waves-light waves-effect dropdown-toggle" data-toggle="dropdown" aria-expanded="false" title={t("Assign label")}>
                            <i className="fa fa-tag"></i>
                        </button>
                        <div className="dropdown-menu">
                            {this.props.labels.map((value, index) => {
                                return <button key={index} onClick={this.onSetLabel.bind(this, value.id)} className="dropdown-item">{value.name.Capitalize()}</button>
                            })}
                            <div className="dropdown-divider"></div>
                            <button onClick={this.onShowLabelForm} className="dropdown-item">{t("Add new")}</button>
                        </div>
                    </div>

                    <div className="btn-group ml-1 mo-mb-2">
                        <button type="button" className="btn btn-outline-secondary waves-light waves-effect dropdown-toggle" data-toggle="dropdown" aria-expanded="false" title={t("Mark selected")}>
                            {t("Mark as")}
                        </button>
                        <div className="dropdown-menu">
                            <button onClick={this.onClickMarkRead.bind(this, "unread")} className="dropdown-item">{t("Unread")}</button>
                            <button onClick={this.onClickMarkRead.bind(this, "read")} className="dropdown-item">{t("Read")}</button>
                            <button onClick={this.onClickMarkImportant.bind(this, 1)} className="dropdown-item">{t("Important")}</button>
                            <button onClick={this.onClickMarkImportant.bind(this, 0)} className="dropdown-item">{t("Not Important")}</button>
                        </div>
                    </div>

                    {/* TODO: add search */}

                </div>

            </AUX>
        );
    }
}


// const mapStateToProps = state => ({
    
// });

export default connect( null, {deleteMessages, setLabelToMessage, setMessageReadStatus, markImportant, createNewLabel} ) ( withRouter(Toolbar) );
// export default withRouter(Toolbar);