import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';

import {
    CRUD_REVENUE_LOAD_CUSTOMERS, CRUD_REVENUE_LOAD_ACCOUNTS, CRUD_REVENUE_LOAD_CATEGORIES, CRUD_REVENUE_LOAD_PAYMETHODS
} from './action';

export const loadCustomers = () => dispatch => {
    return Axios.get(API_BASE_URL + '/list/contact-options?roleName=ROLE_CUSTOMER', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_REVENUE_LOAD_CUSTOMERS,
        customers: res.data
    }));
}

export const loadAccounts = () => dispatch => {
    return Axios.get(API_BASE_URL + '/list/account-options', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_REVENUE_LOAD_ACCOUNTS,
        accounts: res.data
    }));
}

export const loadCategories = (type) => dispatch => {
    return Axios.get(API_BASE_URL + '/list/category-options?type='+type, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_REVENUE_LOAD_CATEGORIES,
        categories: res.data
    }));
}

export const loadPaymentMethods = () => dispatch => {
    return Axios.get(API_BASE_URL + '/list/paymentmethod-options', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_REVENUE_LOAD_PAYMETHODS,
        paymentmethods: res.data
    }));
}