import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import SweetAlert  from 'react-bootstrap-sweetalert';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/ExpenseActions';
import { t } from '../../../components/Translation';

import { handleError } from '../../../helper';

class ExpenseForm extends Component {

	state = {
		id: null,
		date1: new Date(),
		amount: '',
		currency:'',
		account: '',
		notes: '',
		category: '',
		paymentMethod: '',
		reference: '',
		attachment: null,
		currency_options: [],	
		account_options: [],
		category_options: [],
		payment_methods: [],

		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		registerLocale('fr', fr);
		let dpath = "/payments/expense/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
			Axios.get(API_BASE_URL + '/expenses/'+id, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					id: (this.props.path === dpath ? null: st.id),
					date1: new Date(st.date*1000),
					amount: st.amount,
					currency: st.currency,
					account: st.account,
					notes: st.notes,
					category: st.category,
					paymentMethod: st.paymentMethod,
					reference: st.reference,
					attachment: st.attachment				
				});
			});
		}		

		Axios.get(API_BASE_URL + '/list/account-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({account_options: res.data});
		});
		
		Axios.get(API_BASE_URL + '/list/category-options?type=debit', AuthService.getAuthHeader())
		.then(res => {
			this.setState({category_options: res.data});
		});
		
		Axios.get(API_BASE_URL + '/list/paymentmethod-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({payment_methods: res.data});
		});

		Axios.get(API_BASE_URL + '/list/currency-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({currency_options: res.data});
		});
	}
	
	onChange = (e) => this.setState( {[e.target.name]: e.target.value } );


	hideAlert() {
		this.setState({   alert: null  });
	}

	//datePicker
	handleDateChange = (date) => {
		this.setState({  date1:date   });  
	}
	
	onAccountChange = (e) => {
		this.setState({account: e});
	}
	onCategoryChange = (e) => {
		this.setState({category: e});
	}
	onMethodChange = (e) => {
		this.setState({paymentMethod: e});
	}

	onCurrencyChange = (e) => {
		this.setState({currency: e});
	}

	onChangeFile = (e) => {		
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/image', data, AuthService.getAuthHeader())
		    	.then(res => {			
					this.setState({
						attachment: res.data.fileName
					});
				});
		} else {
			
		}
	}
	
	onSubmit = (e) => {
		e.preventDefault();
		if (this.validator.allValid()) {
			var expense = {
				date: Math.floor(this.state.date1.getTime()/1000),
				amount: this.state.amount,
				account: this.state.account.value,
				notes: this.state.notes,
				currency: this.state.currency.value,
				category: this.state.category.value,
				paymentMethod: this.state.paymentMethod.value,
				reference: this.state.reference,
				attachment: (this.state.attachment !== null) ? this.state.attachment : null			
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;


			if (this.state.id !== null) {
				promise = this.props.updateItem('/expenses/'+this.state.id, expense);
			} else {
				promise = this.props.createItem('/expenses', expense);
			}

			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});
				
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit Expense'):t('Add New expense')} </div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="date" className="col-sm-3 col-form-label">{t("Date")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="input-group-prepend" >
														<span className="input-group-text"><i className="ti-calendar"></i></span>
													</div>
													<DatePicker
														name="date1"
														locale="fr"
														className="form-control"
														dateFormat="yyyy-MM-dd"
														selected={this.state.date1}
														onChange={this.handleDateChange}
													/>
												</div>
													{this.validator.message('date1', this.state.date1, 'required')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="amount" className="col-sm-3 col-form-label">{t("Amount")}</label>
											<div className="col-sm-9">
												<input value={this.state.amount} onChange={this.onChange} id="amount" type="text" name="amount" className="form-control" placeholder={t("amount")}></input>
												{this.validator.message('amount', this.state.amount, 'required|numeric')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="currency" className="col-sm-3 col-form-label">{t("Currency")}</label>
											<div className="col-sm-9">
												<Select 
													name="currency"
													value={this.state.currency}
													id="currency"
													onChange={this.onCurrencyChange}
													options = {this.state.currency_options}
												/>
												{this.validator.message('currency', this.state.currency, 'required')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="account" className="col-sm-3 col-form-label">{t("Account")}</label>
											<div className="col-sm-9">
												<Select 
													name="account"
													value={this.state.account}
													id="account"
													onChange={this.onAccountChange}
													options = {this.state.account_options} 
												/>
												{this.validator.message('account', this.state.account, 'required')}
											</div>
										</div>
									
										
										<div className="form-group row">
											<label htmlFor="category" className="col-sm-3 col-form-label">{t("Category")}</label>
											<div className="col-sm-9">
												<Select 
													name="category"
													value={this.state.category}
													id="category"
													onChange={this.onCategoryChange}
													options = {this.state.category_options} 
												/>
												{this.validator.message('category', this.state.category, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="reference" className="col-sm-3 col-form-label">{t("Reference")}</label>
											<div className="col-sm-9">
												<input value={this.state.reference} onChange={this.onChange} id="reference" type="text" name="reference" className="form-control" placeholder={t("Reference")}></input>
												{/* {this.validator.message('reference', this.state.reference, 'required|string')} */}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="paymentMethod" className="col-sm-3 col-form-label">{t("Payment Method")}</label>
											<div className="col-sm-9">
												<Select 
													name="paymentMethod"
													value={this.state.paymentMethod}
													id="category"
													onChange={this.onMethodChange}
													options = {this.state.payment_methods} 
												/>
												{this.validator.message('paymentMethod', this.state.paymentMethod, 'required')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="notes" className="col-sm-3 col-form-label">{t("Notes")}</label>
											<div className="col-sm-9">
												<input value={this.state.notes} onChange={this.onChange} id="notes" type="text" name="notes" className="form-control" placeholder={t("Notes")}></input>
												{/* {this.validator.message('notes', this.state.notes, 'required|string')} */}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="attachment" className="col-sm-3 col-form-label">{t("Attachment")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="attachment"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="attachment"											
														aria-describedby="attachment" />
														<label className="custom-file-label" htmlFor="attachment">
															{t("Choose file")}
														</label>
													</div>
			                                    </div>
												{(this.state.attachment !== null) && <img className="image-width" src={API_IMAGES_URL + this.state.attachment} /> }
												<div >{t("Allowed file types are: {0}.", [IMAGE_TYPES.join(', ')])}</div>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.expense.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (ExpenseForm);