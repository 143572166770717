import * as actionTypes from '../action';
const initialState = {


    id: null,
    date: '',
    amount: '',
    invoiceNumber: '',
    customer: '',
    description: '',
    account: '',
    category: '',
    paymentmethod: '',
    reference: '',
    attachment: {}, // {"name":"asdfad","type":"asdfa","size":345345}

    // dropdown options
    customers: [],
    accounts: [],
    paymentmethods: [],
    categories: [],
}

const RevenueFormReducer = (state= initialState, action) => {
    switch(action.type) {
        case actionTypes.CRUD_REVENUE_LOAD_CUSTOMERS:
            return {
                ...state,
                customers: action.customers
            }
        case actionTypes.CRUD_REVENUE_LOAD_ACCOUNTS:
            return {
                ...state,
                accounts: action.accounts
            }
        case actionTypes.CRUD_REVENUE_LOAD_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            }
        case actionTypes.CRUD_REVENUE_LOAD_PAYMETHODS:
            return {
                ...state,
                paymentmethods: action.paymentmethods
            }
        default :
            return state;
    }

}

export default RevenueFormReducer;