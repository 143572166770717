import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';

import {CRUD_TRANSACTIONS_LIST, CRUD_TRANSACTIONS_FILTER_STATUS, CRUD_TRANSACTIONS_FILTER_PAGESIZE, CRUD_TRANSACTIONS_FILTER_KEYWORD, CRUD_TRANSACTIONS_SORT_ITEMS, CRUD_TRANSACTIONS_DELETE_ITEM, CRUD_TRANSACTIONS_CREATE_ITEM, CRUD_TRANSACTIONS_UPDATE_ITEM, CRUD_TRANSACTIONS_NEED_COMPONENT_SET,CRUD_TRANSACTIONS_FILTER_DATE, CRUD_TRANSACTIONS_FILTER_AMOUNT} from './action';

export const listItems = (requestUrl) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSACTIONS_LIST,
        data: res.data,
    }));
}

export const filterUponStatus = (requestUrl, status) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSACTIONS_FILTER_STATUS,
        status: status,
        data: res.data
    }));
}

export const filterUponPageSize = (requestUrl, pageSize) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSACTIONS_FILTER_PAGESIZE,
        pageSize: pageSize,
        data: res.data
    }));
}

export const filterUponKeyword = (requestUrl, query) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSACTIONS_FILTER_KEYWORD,
        query: query,
        data: res.data
    }));
}


export const filterUponDates = (requestUrl, start, end) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSACTIONS_FILTER_DATE,
        start: start,
        end: end,
        data: res.data
    }));
}

export const filterUponAmounts = (requestUrl, amountMin, amountMax) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSACTIONS_FILTER_AMOUNT,
        amountMin: amountMin,
        amountMax: amountMax,
        data: res.data
    }));
}

export const sortItems = (requestUrl, sort) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSACTIONS_SORT_ITEMS,
        sort: sort,
        data: res.data
    }));
}

export const setNeedComponentUpdate = (status) => dispatch => {
    dispatch({
        type: CRUD_TRANSACTIONS_NEED_COMPONENT_SET,
        needComponentUpdate:status
    });
}