import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import PermissionCheck from './PermissionCheck';
import '../../../components/Text/String';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/RoleActions';
import { listAllowedPermissions } from '../../../store/PermissionActions';
import { t } from '../../../components/Translation';

import { handleSuccess, handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class WebPermissions extends Component {

	state = {
		uid: null,
		name: '',
		description: '',
		status: true,
		permissions: [],
		options: [],
		alert: null,
		allActive: false,
	}
	
	hideAlert() {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/settings/role/:uid/duplicate";
		let uid = this.props.match.params.uid;
		if (uid !== null) {
			Axios.get(API_BASE_URL + '/roles/'+uid, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				var uid = (this.props.path === dpath ? null: st.uid);
				this.setState({
					uid: uid,
					name: st.name,
					description: st.description,
					status: (st.status === 1),
					apipermissions: st.apipermissions,
				});

				Axios.get(API_BASE_URL + '/permissions/options?roleUid='+uid, AuthService.getAuthHeader())
				.then(resp => {			
					
					this.setState({
						options: resp.data
					});
				});
			});
		}
	}

	changeStatus = () => { this.setState({ status: !this.state.status });  }

	onAllActiveChange = () => { 
		const allActive = !this.state.allActive;
		const options = this.state.options;
		for (var i = 0; i < options.length; i ++) {
			options[i].status = allActive;
		}
		this.setState({ 
			allActive ,
			options
		});  	
	}

	onPermissionChange = (uid) => { 
		// this.setState({ status: !this.state.status });  
		var permissionIndex = this.findPermissionIndex(uid);
		const options = this.state.options;

		options[permissionIndex].status = !options[permissionIndex].status;

		this.setState({
			options
		});

	}

	findPermissionIndex = (uid) => {
		const options = this.state.options;
		for (var i = 0; i < options.length; i ++) {
			if (options[i].uid === uid) {
				return i;
			}
		}
		return null;
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			const permissionList = this.state.options.filter(permission => permission.status === true).map(p => p.uid);
			var role = {
				name: this.state.name,
				description: this.state.description,
				status: (this.state.status === true ? 1 : 0),
				permissions: permissionList
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.uid !== null) {
				promise = this.props.updateItem('/roles/'+this.state.uid, role);				
			} else {
				promise = this.props.createItem('/roles', role);
			}
			
			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{t('Edit Web permissions')}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input readOnly={true} value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|string')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="description" className="col-sm-3 col-form-label">{t("Description")}</label>
											<div className="col-sm-9">
												<input readOnly={true} value={this.state.description} onChange={this.onChange} id="description" type="text" name="description" className="form-control" placeholder={t("Description")}></input>
												{this.validator.message('description', this.state.description, 'string')}
											</div>
										</div>
													
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled?")}</label>
											<div className="col-sm-9">												
												<input disabled={true} type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>	

										<div className="form-group row">
											<label className="h1 col-sm-12 col-form-label"><strong>{t("Permissions")}</strong></label>
											<div className="col-sm-12">	
												<div className="text-right">		
													<label className="col-sm-3 col-form-label">{t("Toggle All")}</label><br />						
													<input type="checkbox" id="allActive" checked={this.state.allActive ? "checked"  :  "" } switch="none" onChange={this.onAllActiveChange} />
													<label htmlFor="allActive" data-on-label={t("Yes")}  data-off-label={t("No")}></label><br />
												</div>									
												<div className='row'>
													{this.state.options.map((item, index) => {
														return <div key={index} className='col-sm-6'>
															<PermissionCheck key={index} item={item} onChange={this.onPermissionChange} />
														</div>
													})}
												</div>
											</div>
										</div>	
										
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.role.needComponentUpdate
})

export default connect (mapStateToProps, {listAllowedPermissions, createItem, updateItem, setNeedComponentUpdate}) (WebPermissions);