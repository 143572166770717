import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL ,FILE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/CompanyActions';
import {t} from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class CompanyForm extends Component {

	state = {
		id: null,
		name: '',
		email: '',
		domain: '',
		currency: '',
		address: '',
		address2:'',
		logo: null,
		status: 0,

		adminName: '',
		adminEmail: '',
		adminFirstname: '',
		adminLastname: '',

		currency_options: [],

		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/settings/company/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
		Axios.get(API_BASE_URL + '/companies/'+id, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					...st,
					id: (this.props.path === dpath ? null: st.id),
					status: (st.status === "enabled")
				});
			});
		}			
		Axios.get(API_BASE_URL + '/list/currency-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({currency_options: res.data});
		});
	}

	changeStatus = () => { this.setState({ status: !this.state.status });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onCurrencyChange = (e) => {
		this.setState({currency: e.value});
	}
	
	onChangeFile = (e) => {
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( FILE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData()
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/file', data, AuthService.getAuthHeader())
		    	.then(res => {
					this.setState({logo: res.data.fileName});
				});
		} else {
			
		}
	}
	
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var company = {
				...this.state,
				currency: this.state.currency.value,
				logo: (this.state.logo !== null) ? this.state.logo : null,
				status: this.state.status ? "enabled" : "disabled"
			}
			
			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.id !== null) {
				promise = this.props.updateItem('/companies/' + this.state.id, company);
			} else {
				promise = this.props.createItem('/companies', company);
			}
			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});
			
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit'):t('Add New')} {t("Company")}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<fieldset>
											<legend>Company information</legend>
											<div className="form-group row">
												<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
												<div className="col-sm-9">
													<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Company Name")}></input>
													{this.validator.message('name', this.state.name, 'required|string')}
												</div>
											</div>
										
											<div className="form-group row">
												<label htmlFor="email" className="col-sm-3 col-form-label">{t("Email")}</label>
												<div className="col-sm-9">
													<input value={this.state.email} onChange={this.onChange} id="email" type="text" name="email" className="form-control" placeholder={t("Company Email")}></input>
													{this.validator.message('email', this.state.email, 'required|email')}
												</div>
											</div>
										
											<div className="form-group row">
												<label htmlFor="domain" className="col-sm-3 col-form-label">{t("Domain")}</label>
												<div className="col-sm-9">
													<input value={this.state.domain} onChange={this.onChange} id="domain" type="text" name="domain" className="form-control" placeholder={t("Company domain")}></input>
													{this.validator.message('domain', this.state.domain, 'required|string')}
												</div>
											</div>

											<div className="form-group row">
												<label htmlFor="currency" className="col-sm-3 col-form-label">{t("Currency")}</label>
												<div className="col-sm-9">
													<Select 
														name="currency"
														value={this.state.currency.value}
														id="currency"
														onChange={this.onCurrencyChange}
														options = {this.state.currency_options} 
													/>
													{this.validator.message('currency', this.state.currency, 'required')}
												</div>
											</div>
										
											<div className="form-group row">
												<label htmlFor="address" className="col-sm-3 col-form-label">{t("Address")}</label>
												<div className="col-sm-9">
													<input value={this.state.address} onChange={this.onChange} id="address" type="text" name="address" className="form-control" placeholder={t("Company address")}></input>
													{this.validator.message('address', this.state.address, 'required|string')}
												</div>
											</div>

											<div className="form-group row">
											<label htmlFor="address2" className="col-sm-3 col-form-label">{t("Address2")}</label>
											<div className="col-sm-9">
												<input value={this.state.address2} onChange={this.onChange} id="address2" type="text" name="address2" className="form-control" placeholder={t("Address2")}></input>
												{this.validator.message('address2', this.state.address2, 'string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="city" className="col-sm-3 col-form-label">{t("City")}</label>
											<div className="col-sm-9">
												<input value={this.state.city} onChange={this.onChange} id="city" type="text" name="city" className="form-control" placeholder={t("City")}></input>
												{this.validator.message('city', this.state.city, 'required|string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="country" className="col-sm-3 col-form-label">{t("Country")}</label>
											<div className="col-sm-9">
												<input value={this.state.country} onChange={this.onChange} id="country" type="text" name="country" className="form-control" placeholder={t("Country")}></input>
												{this.validator.message('country', this.state.country, 'required|string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="tax number" className="col-sm-3 col-form-label">{t("Tax")}</label>
											<div className="col-sm-9">
												<input value={this.state.tax} onChange={this.onChange} id="tax" type="text" name="tax" className="form-control" placeholder={t("Tax number")}></input>
												{this.validator.message('tax', this.state.tax, 'required|string')}
											</div>
										</div>	
				
											<div className="form-group row">
											<label htmlFor="logo" className="col-sm-3 col-form-label">{t("Logo")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="logo"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="logo"											
														aria-describedby="logo" />
														<label className="custom-file-label" htmlFor="logo">
															{t("Choose file")}
														</label>
													</div>
			                                    </div>
												{(this.state.logo !== null && this.state.logo !== "") && <img src={API_IMAGES_URL + this.state.logo} /> }
												<div >Allowed file types are: {FILE_TYPES.join(', ')}.</div>
											</div>
										</div>		
											<div className="form-group row">
												<label className="col-sm-3 col-form-label">{t("Enabled?")}</label>
												<div className="col-sm-9">												
													<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
													<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
												</div>
											</div>	
										</fieldset>		

										
										<fieldset>
											<legend>Admin user information</legend>

											<div className="form-group row">
												<label htmlFor="adminName" className="col-sm-3 col-form-label">{t("Username")}</label>
												<div className="col-sm-9">
													<input readOnly={(this.state.id !== null)} value={this.state.adminName} onChange={this.onChange} id="adminName" type="text" name="adminName" className="form-control" placeholder={t("Username")}></input>
													{this.validator.message('adminName', this.state.adminName, 'required|string')}
												</div>
											</div>
										
											<div className="form-group row">
												<label htmlFor="adminEmail" className="col-sm-3 col-form-label">{t("Email")}</label>
												<div className="col-sm-9">
													<input readOnly={(this.state.id !== null)} value={this.state.adminEmail} onChange={this.onChange} id="adminEmail" type="text" name="adminEmail" className="form-control" placeholder={t("Email")} ></input>
													{this.validator.message('adminEmail', this.state.adminEmail, 'required|email')}
												</div>
											</div>
										
											<div className="form-group row">
												<label htmlFor="adminFirstname" className="col-sm-3 col-form-label">{t("Firstname")}</label>
												<div className="col-sm-9">
													<input value={this.state.adminFirstname} onChange={this.onChange} id="adminFirstname" type="text" name="adminFirstname" className="form-control" placeholder={t("Firstname")}></input>
													{this.validator.message('adminFirstname', this.state.adminFirstname, 'required|string')}
												</div>
											</div>

											<div className="form-group row">
												<label htmlFor="adminLastname" className="col-sm-3 col-form-label">{t("Lastname")}</label>
												<div className="col-sm-9">
													<input value={this.state.adminLastname} onChange={this.onChange} id="adminLastname" type="text" name="adminLastname" className="form-control" placeholder={t("Lastname")}></input>
													{this.validator.message('adminLastname', this.state.adminLastname, 'required|string')}
												</div>
											</div>

										</fieldset>	
																
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.company.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (CompanyForm);