import * as actionTypes from '../action';
const initialState = {
    localization: {},
}

const ConfigReducer = (state= initialState, action) => {
    switch(action.type) {
        case actionTypes.LOAD_CONFIGURATION_LOCALIZATION:            
            return {
                ...state,
                localization: action.data
            };
        
        default :
            return state;
    }

}

export default ConfigReducer;