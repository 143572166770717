import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import DateTime from '../../../components/Timestamp/DateTime';
import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/AccountActions';
import {loadGlobals} from '../../../store/DashboardActions';
import {t} from '../../../components/Translation';
import { formatAmount } from '../../../helper';

class AccountList extends Component {
	state = {
		alert: null,
		baseUrl: '/accounts',
		addBtn: {
			label: t('Create new account'),
			url: this.props.formPath + "/create"
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },
			{
			  label: t('Id'),
			  field: 'id',
			  sort: false
		    },
		    {
		      label: t('Name'),
		      field: 'name',
		      sort: true,
			  sortCol: 'name',
		    },
		    {
		      label: t('Number'),
		      field: 'number',
		      sort: true,
			  sortCol: 'number',
		    },
		    {
		      label: t('Currency'),
		      field: 'currency',
		      sort: true,
			  sortCol: 'currencyName',
		    },
		    {
		      label: t('Balance'),
		      field: 'balance',
		      sort: true,
			  sortCol: 'balance',
		    },
		    {
		      label: t('Bank Name'),
		      field: 'bankName',
		      sort: true,
			  sortCol: 'bankname',
		    },
		    {
		      label: t('Bank Phone'),
		      field: 'bankPhone',
		      sort: false
		    },
		    {
		      label: t('Bank Address'),
		      field: 'bankAddress',
		      sort: false
		    },
		    {
		      label: t('Is default'),
		      field: 'isdefault',
		      sort: true,
			  sortCol: '`default`',
		    },
		    {
		      label: t('Status'),
		      field: 'status',
		      sort: true,
			  sortCol: 'status',
		    },
		    {
		      label: t('Created'),
		      field: 'created',
		      sort: true,
			  sortCol: 'name',
		    },
		    {
		      label: t('Updated'),
		      field: 'updated',
		      sort: true,
			  sortCol: 'name',
		    },
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: [
			{
				label: t("All"),
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: t("Enabled"),
				value: "enabled",
				class: "btn-outline-success"
			},
			{
				label: t("Disabled"),
				value: "disabled",
				class: "btn-outline-warning"
			}
		]
	}

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}

	componentDidMount() {
		this.props.loadGlobals();
	}

	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id': value.id,
				'name': value.name,
				'number': value.number,
				'currency': value.currency.label,
				'balance': formatAmount(value.balance, this.props.globals.currency),
				'bankName': value.bankName,
				'bankPhone': value.bankPhone,
				'bankAddress': value.bankAddress,
				'isdefault': value.isdefault ? 'Yes' : 'No',
				'status': <Status type="account" value={value.status} />,
				'created': <DateTime timestamp={value.created} />,
				'updated': <DateTime timestamp={value.updated} />,
				'actions': <Action state={value} path={this.props.formPath} onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}

	render() {
		let rows = this.mapAttributes(this.props.rows);
		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
										rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.account.rows,
	globals: state.dash.globals,
	pagination: state.account.pagination,
	pageSize: state.account.pageSize,
})

export default connect( mapStateToProps, {deleteItem, loadGlobals} ) ( AccountList );