import React , {Component } from 'react';
import {PropTypes} from 'prop-types';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {loadTaxes} from '../../../store/TaxActions';
import {loadServices} from '../../../store/AgreementActions';
import { t } from '../../../components/Translation';
import Select from 'react-select';
import { loadGlobals } from '../../../store/DashboardActions';
import { formatAmount } from '../../../helper';

class ItemRow extends Component {

    state = {
        service: null,
        tax: null
    }

    componentDidMount() {
        this.setState(this.props.item);
    }

    onSelectInputChange = (e) => {
        if ( e.target.value !== null && e.target.value !== "") {
            this.setState({...this.state, service: {label: e.target.value, value: e.target.value}});
            this.props.onSelectInputChange(this.props.index, e);
        }
    }

    onItemChange = (e) => {
        if (e === null) {
            this.setState({
                service: null,
                name: '',
                price: 0,
                quantity: 1,
                tax: null,
            });
        } else {
            this.setState({
                ...this.state,
                service: e,
                name: e.name,
                price: e.price,
                quantity: e.quantity,
                tax: this.props.taxes.filter(t => t.value === e.tax)[0],
            });
        } 
        this.props.onItemChange(this.props.index, e);
    }

    onTaxChange = (e) => {
        this.setState({
            tax: e
        });
        this.props.onTaxChange(this.props.index, e);
    }

    onQuantityChange = (e) => {
        this.setState({
            quantity: parseInt(e.target.value)
        })
        this.props.onQuantityChange(this.props.index, e);
    }

    onPriceChange = (e) => {
        this.setState({
            price: parseFloat(e.target.value)
        })
        this.props.onPriceChange(this.props.index, e);
    }

    onNameChange = (e) => {
        this.setState({
            name : e.target.value
        });
        this.props.onNameChange(this.props.index, e);
    }

    render() {

        return(
            <AUX>
                <tr>
                    <td><button onClick={this.props.onRemoveClick.bind(this, this.props.index)} type="button" className="btn btn-outline-danger waves-light waves-effect" title="Delete selected"><i className="ti-trash"></i></button></td>
                    <td>
                        <Select 
                            name="service"
                            value={this.state.service}
                            id="service"
                            onChange={this.onItemChange}
                            onBlur={this.onSelectInputChange}
                            options = {this.props.services}
                        />
                    </td>
                    <td><input name="name" onChange={this.onNameChange.bind(this)} value={this.state.name} type="text" className="form-control text-center" placeholder="Name" /></td>
                    <td><input onChange={this.onQuantityChange.bind(this)} value={this.state.quantity} type="number" className="form-control text-center" placeholder="1" /></td>
                    <td><input onChange={this.onPriceChange.bind(this)} value={this.state.price} type="text" className="form-control text-right" placeholder="0,000.00" /></td>
                    <td>
                        <Select 
                            name="tax"
                            isClearable
                            value={this.state.tax}
                            id="tax"
                            onChange={this.onTaxChange}
                            options = {this.props.taxes}
                        />
                    </td>
                    <td className="text-right">{formatAmount(this.props.item.total, this.props.globals.currency)}</td>
                </tr>
            </AUX>
        )
    }
}

ItemRow.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    taxes: state.tax.options,
    services: state.agreement.services,
    globals: state.dash.globals,
})

export default connect (mapStateToProps, {loadTaxes, loadServices, loadGlobals}) (ItemRow);