
import React , {Component } from 'react';
import AUX from '../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/action';
import { Link } from 'react-router-dom';
import {t} from '../../components/Translation';

import Axios from 'axios';
import {API_BASE_URL, BASIC_AUTH_TOKEN} from '../../config';

class ActivateAccount extends Component{
    
    state = {
        status: false,
        call: false
    }

    componentDidMount() 
    {

        if ( this.state.call === false) {
            var token = this.props.match.params.token;
            var email = this.props.match.params.email;

            var requestUrl = API_BASE_URL + '/auth/activation';
            var requestMethod = 'post';
            Axios.request({
                url: requestUrl, 
                method: requestMethod,
                data: {
                    code: token,
                    email
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + BASIC_AUTH_TOKEN
                }
            })
            .then(res => {						
				
			
                console.log(res.status);	
                
                if (res.status === 200 || res.status === 304) {				
                    this.setState( {
                        status: true,
                        call: true
                    } );
                } else {
                    this.setState( {
                        status: false,
                        call: true
                    } );
                }
                this.setState({call: true});
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status  === 200 || error.response.status === 304) {
                        this.setState( {
                            status: true,
                            call: true
                        } );
                    } else {
                        this.setState( {
                            status: false,
                            call: true
                        } );
                    }
                }
            });
            
        }

        if (this.props.loginpage === false) 
        {
            this.props.UpdateLogin();
        }
        window.onpopstate  = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    render(){
        return (
            <AUX>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link onClick={()=> this.props.UpdateLoginAgain()} to="/" className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="text-muted font-18 mb-3 text-center">{t("Account activation")}</h4>
                                {(this.state.status !== null && this.state.status === true) && <div className="alert alert-success" role="alert">
                                    {t("Your account is activated successfully you may login using credentials sent to your email earlier!")}
                                </div>}
                                {(this.state.status == null || this.state.status === false) && <div className="alert alert-danger" role="alert">
                                    {t("There was an error processing your request Please contact system administrator!")}
                                </div>}
                            </div>

                        </div>
                    </div>

                <div className="m-t-40 text-center">
                    <p>{t("Remember It ?")} <Link to ='/login' className="text-primary"> {t("Sign In Here")} </Link> </p>
                    <p>© {new Date().getFullYear()} Smart Office</p>
                </div>

            </div>   
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
        UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false }),
      
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ActivateAccount);