import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';
// import DateTime from '../../../components/Timestamp/DateTime';
import '../../../components/Text/String';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/AgreementActions';
import {t} from '../../../components/Translation';
import {loadGlobals} from '../../../store/DashboardActions';
import { formatDate } from '../../../helper';

class AgreementsList extends Component {

	state = {		
		alert: null,
		baseUrl: '/agreements',
		addBtn: {
			label: t('Create new agreement'),
			url: this.props.formPath + '/create'
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },		    
		    {
		      label: t('Number'),
		      field: 'number',
		      sort: true,
			  sortCol: 'number'
		    },
			{
		      label: t('Customer'),
		      field: 'customer',
		      sort: true,
			  sortCol: 'customerName'
		    },
			{
				label: t('Customer Category'),
				field: 'customerCategory',
				sort: true,
				sortCol: 'categoryName'
			},
			{
				label: t('Currency'),
				field: 'currency',
				sort: true,
				sortCol: 'currencyName'
			},
			// {
			// 	label: t('Agreement Number'),
			// 	field: 'agreementNumber',
			// 	sort: true
			// },
			{
				label: t('Start date'),
				field: 'start',
				sort: true,
				sortCol: 'start'
			},
			{
				label: t('End date'),
				field: 'end',
				sort: true,
				sortCol: 'end'
			},
		    {
		      label: t('Status'),
		      field: 'status',
		      sort: true,
			  sortCol: 'status'
		    },		   
			{
				label: t('Supplier'),
				field: 'supplier',
				sort: true,
				sortCol: 'supplierName'
			},
			{
				label: t('Supplier Category'),
				field: 'supplierCategory',
				sort: true,
				sortCol: 'supplierCategory'
			}, 
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: [
			{
				label: t("All"),
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: t("Draft"),
				value: 0,
				class: "btn-outline-secondary"
			},
			{
				label: t("Active"),
				value: 1,
				class: "btn-outline-success"
			},
			{
				label: t("Closed"),
				value: 2,
				class: "btn-outline-warning"
			}
		]
	}

	componentDidMount() {
		this.props.loadGlobals();
	}

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}
	
	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			  var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'number': value.number,
				'customer': value.customer.label.Capitalize(),
				'customerCategory': value.customerCategory.label.Capitalize(),
				'currency': value.currency.label.Capitalize(),
				'start': formatDate(value.start, this.props.globals.dateFormat),
				'end': formatDate(value.end, this.props.globals.dateFormat),
				'status': <Status type="agreement" value={value.status} />,
				'supplier': value.supplier.label.Capitalize(),
				'supplierCategory': value.supplierCategory.label.Capitalize(),
				'actions': <Action state={value} path={this.props.formPath} type="agreement" onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}
	render() {

		let rows = this.mapAttributes(this.props.rows);

		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
                                        rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.agreement.rows,
	globals: state.dash.globals,
	pagination: state.agreement.pagination,
	pageSize: state.agreement.pageSize,
})

export default connect( mapStateToProps, {deleteItem, loadGlobals} ) ( AgreementsList );