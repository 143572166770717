import React, { Component } from 'react';
import AUX from '../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/action';
import { Link } from 'react-router-dom';
import AuthService from './AuthService';

import {t} from '../../components/Translation';

class login extends Component {

	state = {
        username: '',
        password: ''
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });

    componentDidMount() 
    {	
		if ( AuthService.loggedIn() ) {
            this.props.history.push("/");
        }
	
        if (this.props.loginpage === false) 
        {
            this.props.UpdateLogin();
        }
        window.onpopstate  = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    onSubmit = (e)  => {
        e.preventDefault();
        var res = AuthService.login(this.state, this.props.history, this.props.UpdateLoginAgain);
        console.log(res);
    }

    render() {

        return (
            <AUX>
                <div className="wrapper-page">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="text-center m-0">
                                <Link to="/" onClick={()=> this.props.UpdateLoginAgain()} className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                            </h3>
                            <div className="p-3">
                                <h4 className="text-muted font-18 m-b-5 text-center">{!this.props.is_reg ? t('Welcome Back !') : t('Free Register')}</h4>
                                <p className="text-muted text-center">{!this.props.is_reg ? t('Sign in to continue to Smart Office.') : t('Get your free Smart Office account now.')}</p>
                                <form className="form-horizontal m-t-30" onSubmit={this.onSubmit}>

                                    {(this.props.is_reg) ?
                                        <div className="form-group">
                                            <label htmlFor="email">{t('Email')}</label>
                                            <input type="email" className="form-control" id="email" placeholder={t("Enter email")} />
                                        </div>
                                        : null}

                                    <div className="form-group">
                                        <label htmlFor="username">{t('Username')}</label>
                                        <input onChange={this.onChange} name="username" type="text" className="form-control" id="username" placeholder={t("Enter username")} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="userpassword">{t('Password')}</label>
                                        <input onChange={this.onChange} name="password" type="password" className="form-control" id="userpassword" placeholder={t("Enter password")} />
                                    </div>

                                    <div className="form-group row m-t-20">

                                        <div className="col-6">
                                            {(!this.props.is_reg) ?
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                    <label className="custom-control-label" htmlFor="customControlInline">{t('Remember me')}</label>
                                                </div> : null}
                                        </div>
                                        <div className="col-6 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{!this.props.is_reg ? t('Log In') : t('Register')}</button>
                                        </div>
                                    </div>

                                    <div className="form-group m-t-10 mb-0 row">
                                        <div className="col-12 m-t-20">
                                            <Link to="/forget-password"><i className="mdi mdi-lock"></i> {t('forgotPassword')}</Link>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="m-t-40 text-center">
                        {/* <p>{t("Don't have an account ? ")}<Link to="register" className="text-primary"> {t('Signup Now')} </Link> </p> */}
                        <p>© {new Date().getFullYear()} Smart Office</p>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
        UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false }),
      
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(login);