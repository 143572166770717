import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, FILE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/PermissionActions';
import { t } from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class PermissionForm extends Component {

	state = {
		uid: null,
		name: '',
		route: '',
		description: '',
		status: true,
		alert: null,
	}
	
	hideAlert() {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		registerLocale('fr', fr);
		let dpath = "/settings/permissions/:uid/duplicate";
		let uid = this.props.match.params.uid;
		if (uid !== null) {
			Axios.get(API_BASE_URL + '/permissions/'+uid, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					uid: (this.props.path === dpath ? null: st.uid),
					name: st.name,
					route: st.route,
					description: st.description,
					status: (st.status === 1)
				});
			});
		}		
	}
	
	changeStatus = () => { this.setState({ status: !this.state.status });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onMethodChange = (e) => {
		this.setState({method: e});
	}

	onChangeFile = (e) => {
		var file = e.target.files[0];
		console.log(file);
		var ext = file.name.split('.').pop();
		if ( FILE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData()
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/file', data, AuthService.getAuthHeader())
		    	.then(res => {
					console.log(res.data);
					this.setState({attachment: res.data.fileName});
				});
		} else {
			
		}
	}
	
	onSubmit = (e) => {
		e.preventDefault();
		if (this.validator.allValid()) {
			var permission = {
				name: this.state.name,
				route: this.state.route,
				description: this.state.description,
				status: (this.state.status ? 1 : 0),			
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;


			if (this.state.uid !== null) {
				promise = this.props.updateItem('/permissions/'+this.state.uid, permission);
			} else {
				promise = this.props.createItem('/permissions', permission);
			}

			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit permissions'):t('Add New permissions')} </div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|alpha_space')}
											</div>
										</div>
									
										
										<div className="form-group row">
											<label htmlFor="description" className="col-sm-3 col-form-label">{t("Description")}</label>
											<div className="col-sm-9">
												<input value={this.state.description} onChange={this.onChange} id="description" type="text" name="description" className="form-control" placeholder={t("Description")}></input>
												{this.validator.message('description', this.state.description, 'string')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="route" className="col-sm-3 col-form-label">{t("Route")}</label>
											<div className="col-sm-9">
												<input value={this.state.route} onChange={this.onChange} id="route" type="text" name="route" className="form-control" placeholder={t("Route")}></input>
												{this.validator.message('route', this.state.route, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled?")}</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>
										
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.permission.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (PermissionForm);