import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';
import moment from 'moment';

import {DASH_COUNTERS, DASH_SALES_CHART, DASH_UNPAID_INVOICES, DASH_LATEST_INCOMES, DASH_LATEST_PAYMENTS, DASH_LATEST_MESSAGES, DASH_LOAD_GLOBALS } from './action';

export const getDashboardCounters = (start, end) => dispatch => {
    return Axios.get(API_BASE_URL + '/dashboard/counters?start='+start+'&end='+end, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: DASH_COUNTERS,
        data: res.data,
    }));
}

export const loadSalesChart = (start, end) => dispatch => {
    const params = {
        yearStart: start,
        yearEnd: end,
        monthStart: moment().startOf('month').unix(),
        monthEnd: moment().endOf('month').unix(),
        weekStart: moment().startOf('week').unix(),
        weekEnd: moment().endOf('week').unix()
    };
    const query = new URLSearchParams(params).toString();

    return Axios.get(API_BASE_URL + '/dashboard/saleschart?' + query, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: DASH_SALES_CHART,
        data: res.data,
    }));
}

export const loadGlobals = (currencyId = null) => dispatch => {
    let endpoint = '/dashboard/globals' + ( currencyId !== null ? ('?currencyId=' + currencyId) : '');
    return Axios.get(API_BASE_URL + endpoint, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: DASH_LOAD_GLOBALS,
        data: res.data,
    }));
}

export const getUnpaidInvoices = (start, end, pageNo, pageSize) => dispatch => {
    const params = {
        start,
        end,
        size: pageSize,
        page: pageNo,
        sort_by: 'days',
        sort_dir: 'DESC'
    };

    const query = new URLSearchParams(params).toString();

    return Axios.get(API_BASE_URL + '/dashboard/unpaid-invoices?' + query, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: DASH_UNPAID_INVOICES,
        data: res.data,
    }));
}

export const getIncomes = () => dispatch => {
    return Axios.get(API_BASE_URL + '/dashboard/incomes', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: DASH_LATEST_INCOMES,
        data: res.data,
    }));
}

export const getLatestPayments = () => dispatch => {
    return Axios.get(API_BASE_URL + '/dashboard/payments', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: DASH_LATEST_PAYMENTS,
        data: res.data,
    }));
}

export const getLatestMessages = () => dispatch => {

    return Axios.get(API_BASE_URL + '/dashboard/messages', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: DASH_LATEST_MESSAGES,
        data: res.data
    }));
}