import moment from "moment";
import {ToastsStore} from 'react-toasts';
import {t} from './components/Translation';

export const getDaysArray = (date1) => {
    var momentObj = moment(date1.getTime());
    var daysInMonth = momentObj.daysInMonth();
    var monthStart = momentObj.startOf('month').unix();
    var dates = [];
    for ( let i = 0; i < daysInMonth; i ++ ) {
        let tmpDate = moment(monthStart * 1000).add(i, 'days');
        var dayOfWeek = parseInt(tmpDate.format('E'));
        dates.push({
            dayNo: parseInt(tmpDate.format('D')),
            isOff: (dayOfWeek === 6 || dayOfWeek === 7),
            month: tmpDate.format('MMM'),
            day: tmpDate.format('ddd'),
            total: 0,
        });
    }
    return dates;
}

export const findTimesheetEntryIndex = (entries, agreement, service, day) => {
    var index = null;
    for (let i=0; i < entries.length; i ++) {
        var entry = entries[i];
        if ( entry.agreement.value === agreement && entry.service.value === service && entry.dayOfMonth === day) {
            index = i;
        }
    }
    return index;
}

export const formatDate = (timestamp, format) => {
    return moment(timestamp*1000).format(format);
}

export const formatAmount = (amount, currency) => {
    if (currency === null) {
        return amount;
    }
    amount = amount * currency.rate;
    amount = amount.toFixed(currency.precision).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    if (currency.symbolPosition === "left") {
        return currency.symbol + amount;
    } else {
        return amount + currency.symbol;
    }
}

export const hideAlert = () => {
    return null;
}
export const handleSuccess = (response) => {
    var message = t("Success!");
    ToastsStore.success(message, 5000);
}

export const handleError = (error) => {
   var message = t("Your credentials are not valid!");
    if (error.response) {
        if (error.response.status === 304) {
            message = t("Data is not updated!");
        } else if (error.response.status === 400) {
            message = t("There was an error please re-check form values!");
        } else if (error.response.status === 403) {
            message = t("You are not allowed to perform this action!");
        } else if (error.response.status === 404) {
            message = t("The server can not find the requested resource!");
        } else if (error.response.status === 405) {
            message = t("There was some error please try again later!");
        } else if (error.response.status === 409) {
            message = t("there are some duplicate entries in form. Please Re-check your form!");
        } else if (error.response.status === 413) {
            message = t("The server is down!");
        } else if (error.response.status === 500) {
            message = t("There was some error please try again later!");
        } else if (error.response.status === 502) {
            message = t("got an invalid response");
        } else if (error.response.status === 504) {
            message = t("Gateway timeout. Server could not get response!");
        }
    }

    return message;
    
}