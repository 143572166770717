import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem, generateInvoices} from '../../../store/TimesheetActions';
import {loadGlobals} from '../../../store/DashboardActions';
import {t} from '../../../components/Translation';
import { handleError, formatDate } from '../../../helper';

import {API_BASE_URL, FILES_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import Axios from 'axios';
import SweetAlert  from 'react-bootstrap-sweetalert';

class TimesheetList extends Component {
	state = {
		alert: null,
		baseUrl: '/timesheets',
		addBtn: {
			label: t('New timesheet'),
			url: this.props.formPath + "/create"
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },
			{
				label: 'id',
				field: 'id',
				sort: false
			  },
			{
				label: t('Number'),
				field: 'number',
				sort: true
			},
		    {
		      label: t('Customer'),
		      field: 'customer',
		      sort: false
		    },
		    {
		      label: t('Month'),
		      field: 'month',
		      sort: true
		    },
		    {
		      label: t('No of days'),
		      field: 'days',
		      sort: true
		    },
		    {
		      label: t('Status'),
		      field: 'status',
		      sort: true
		    },
		    {
		      label: t('Created'),
		      field: 'created',
		      sort: true
		    },
		    {
		      label: t('Updated'),
		      field: 'updated',
		      sort: true
		    },
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: [
			{
				label: t("All"),
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: t("Draft"),
				value: 0,
				class: "btn-outline-secondary"
			},
			{
				label: t("Sent"),
				value: 1,
				class: "btn-outline-warning"
			},
			{
				label: t("Approved"),
				value: 2,
				class: "btn-outline-success"
			}
		]
	}

	hideAlert() {
		this.setState({   alert: null  });
	}

	showPreloader = () => {
		const alertBox = () => (
			<SweetAlert
				info
				buttons={false}
				title="In progress..."
			>Opeartion in progress...
			</SweetAlert>
		);
		this.setState({   alert: alertBox()  });
    }

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}

	generateInvoices = (id) => {
		this.props.generateInvoices(this.state.baseUrl + '/' + id + '/generate-invoices')
		.catch((res) => {
			
		});
	}

	sendTimesheetEmail = (id) => {
		this.showPreloader();
        Axios.post(API_BASE_URL + "/timesheets/" + id + "/send-mail", {}, AuthService.getAuthHeader())
        .then(res => {
            const alertBox = () => (
                <SweetAlert
                    success
                    title="Success"
                    onConfirm={() => {
                        this.hideAlert();
                    }}>Timesheet email sent to customer!
                </SweetAlert>
            );
            this.setState({   alert: alertBox()  });
        });
    }

	onClickExport = (id, number) => {
		this.showPreloader();
        Axios.post(API_BASE_URL + "/timesheets/" + id + "/generate", {}, AuthService.getAuthHeader())
        .then(res => {
			this.hideAlert();
            var filename = number + ".pdf";
            var a = document.createElement("a"),
            url = FILES_URL + filename;
            a.href = url;
            a.download = filename;
            a.target = "_blank";
            a.style = "display:none;opacity:0;";
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 100000); 
        })
        .catch(error => {
            const alertBox = () => (
                <SweetAlert
                    danger
                    title="Error"
                    onConfirm={() => {
                        this.hideAlert();
                    }}>{handleError(error)}
                </SweetAlert>
            );
            this.setState({   alert: alertBox()  });
        });
    }

	componentDidMount() {
		this.props.loadGlobals();
	}

	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id':'',
				'number': value.number,
				'customer': value.customer.label,
				'month': formatDate(value.month, this.props.globals.monthFormat),
				'days': value.days,
				'status': <Status type="timesheet" value={value.status} />,
				'created': formatDate(value.created, this.props.globals.dateFormat),
				'updated': formatDate(value.updated, this.props.globals.dateFormat),
				'actions': <Action 
								type="timesheet" 
								state={value} 
								path={this.props.formPath} 
								onDelete={this.deleteItem} 
								onGenerateInvoices={this.generateInvoices} 
								onSendTimesheet={this.sendTimesheetEmail} 
								onClickTimesheetExport={this.onClickExport}
							/>
			};
			items.push( item );
		}
		return items;
	}

	render() {
		let rows = this.mapAttributes(this.props.rows);
		return (
			<AUX>
				{this.state.alert}
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
										rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.timesheet.rows,
	globals: state.dash.globals,
	pagination: state.timesheet.pagination,
	pageSize: state.timesheet.pageSize,
})

export default connect( mapStateToProps, {deleteItem, loadGlobals, generateInvoices} ) ( TimesheetList );