
import React , {Component } from 'react';
import AUX from '../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/action';
import { Link } from 'react-router-dom';
import {t} from '../../components/Translation';

import { handleError } from '../../helper';
import Axios from 'axios';
import {API_BASE_URL} from '../../config';
import {ToastsStore} from 'react-toasts';
import AuthService from './AuthService';

class recover_pass extends Component{
    
    state = {
        email: '',
        token: '',
        password: ''
    }

    onChange = (e) => this.setState( {[e.target.name]: e.target.value });

    componentDidMount() 
    {
        // this.setState({
        //     email: 
        // });

        console.log(this.props);
        
        if (this.props.loginpage === false) 
        {
            this.props.UpdateLogin();
        }
        window.onpopstate  = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    onSubmit = (e)  => {
        e.preventDefault();
            var token = this.props.match.params.token;
            var email = this.props.match.params.email;

        Axios.post(API_BASE_URL+'/auth/reset-password/' + email, {
            token: token,
            password: this.state.password
        }, AuthService.getBasicAuthHeader())
            .then(res => {
                this.setState({
                    password: '',
                });
                ToastsStore.success(t("Your request is submitted successfully!"), 5000);      
            })
            .catch(function (error) {
                var message = handleError(error);
                ToastsStore.error(message, 5000);      
            });
    }

    render(){
        return(
            <AUX>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link onClick={()=> this.props.UpdateLoginAgain()} to="/" className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="text-muted font-18 mb-3 text-center">{t("Reset Password")}</h4>
                                <div className="alert alert-info" role="alert">
                                    {t("Enter your new password and submit!")}
                                </div>

                                <form className="form-horizontal m-t-30" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label for="password">{t("Password")}</label>
                                        <input name="password" value={this.state.password} type="password" onChange={this.onChange} className="form-control" id="password" placeholder={t("Enter password")} />
                                    </div>

                                    <div className="form-group row m-t-20">
                                        <div className="col-12 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{t("Reset")}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                <div className="m-t-40 text-center">
                    <p>{t("Remember It ?")} <Link to ='/login' className="text-primary"> {t("Sign In Here")} </Link> </p>
                    <p>© {new Date().getFullYear()} Smart Office</p>
                </div>

            </div>   
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
        UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false }),
      
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(recover_pass);