import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';

import {SEND_MESSAGE, LIST_FOLDERS, LIST_LABELS, LIST_MESSAGES, LOAD_MESSAGE, SET_FOLDER, CREATE_LABEL, SET_LABEL, SET_LABEL_NULL, LOAD_MESSAGE_PAGE, DELETE_MESSAGES, SET_LABEL_TO_MESSAGE, MARK_MESSAGES_READ_UNREAD, MARK_IMPORTANT} from './action';

export const sendMessage = (message) => dispatch => {
    return Axios.post(API_BASE_URL + '/message', message, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: SEND_MESSAGE,
        status: res.status === 201
    }));
}

export const markImportant = (important, messageUids, viewType) => dispatch => {
    return Axios.put(API_BASE_URL + '/messages/mark/important?important=' + important, {items:messageUids}, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: MARK_IMPORTANT,
        important,
        viewType,
        messageUids
    }));
}

export const setMessageReadStatus = (status, messageUids, viewType) => dispatch => {
    return Axios.put(API_BASE_URL + '/messages/mark/' + status, {items:messageUids}, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: MARK_MESSAGES_READ_UNREAD,
        status,
        viewType,
        messageUids
    }));
}

export const createNewLabel = (label, messageUids, viewType) => dispatch => {
    return Axios.post(API_BASE_URL + '/messages/label', {
        labelName: label.name,
        labelColor: label.color,
        items:messageUids
    }, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CREATE_LABEL,
        label: res.data,
        viewType,
        messageUids
    }));
}

export const setLabelToMessage = (labelId, messageUids, viewType) => dispatch => {
    return Axios.put(API_BASE_URL + '/messages/label/' + labelId, {items:messageUids}, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: SET_LABEL_TO_MESSAGE,
        labelId,
        viewType,
        messageUids
    }));
}

export const deleteMessages = (messageUids, viewType) => dispatch => {
    return Axios.request({
        url: API_BASE_URL + '/messages', 
        method: 'delete',
        data: {items: messageUids},
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + AuthService.getToken()
        }
    })
    .then(res => dispatch({
        type: DELETE_MESSAGES,
        viewType,
        messageUids
    }));
}

export const listFolders = () => dispatch => {
    return Axios.get(API_BASE_URL + '/folders', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: LIST_FOLDERS,
        folders: res.data
    }));
}

export const listLabels = () => dispatch => {
    return Axios.get(API_BASE_URL + '/labels', AuthService.getAuthHeader())
    .then(res => dispatch({
        type: LIST_LABELS,
        labels: res.data
    }));
}

export const setLabelNull = () => dispatch => {
    dispatch({
        type: SET_LABEL_NULL,
        currentLabel: null,
    });
}

export const setLabel = (folderUid, labelUid, pageSize) => dispatch => {
    return Axios.get(API_BASE_URL + '/messages/' + folderUid + '?size=' + pageSize + '&labelUid=' + labelUid, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: SET_LABEL,
        currentLabel: labelUid,
        messages: res.data
    }));
}

export const setFolder = (folderUid, fromCompose) => dispatch => {
    dispatch({
        type: SET_FOLDER,
        currentFolder: folderUid,
        fromCompose
    });
}

export const listMessages = (folderUid, pageSize) => dispatch => {
    return Axios.get(API_BASE_URL + '/messages/' + folderUid + '?size=' + pageSize, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: LIST_MESSAGES,
        messages: res.data
    }));
}

export const loadMessagePage = (folderUid, pageSize, pageNo, labelUid=null) => dispatch => {
    return Axios.get(API_BASE_URL + '/messages/' + folderUid + '?size=' + pageSize+'&page='+pageNo+(labelUid !== null ? '&labelUid='+labelUid : ''), AuthService.getAuthHeader())
    .then(res => dispatch({
        type: LOAD_MESSAGE_PAGE,
        currentLabel: labelUid,
        messages: res.data
    }));
}

export const loadMessage = (folderUid, messageUid) => dispatch => {
    return Axios.get(API_BASE_URL + '/message/' + folderUid + '/id/' + messageUid, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: LOAD_MESSAGE,
        message: res.data
    }));
}