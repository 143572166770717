import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';

import Select from 'react-select';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/TransfersActions';
import {t} from '../../../components/Translation';

import { handleSuccess, handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class TransfersForm extends Component {

	state = {
		id: null,
		date:'',
		amount: '',
		fromAccount: null,
		notes: '',
		paymentMethod:null,
		toAccount: null,
		payment_methods: [],
		account_options: [],
		currency_options: [],

		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/banking/transfers/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
			Axios.get(API_BASE_URL + '/transfers/'+id, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					id: (this.props.path === dpath ? null: st.id),
					date1: new Date(st.date*1000),
					fromAccount: st.fromAccount,
					toAccount: st.toAccount,
					amount: st.amount,
					paymentMethod: st.paymentMethod,
					notes: st.notes,
				});
			});
		}
	
		Axios.get(API_BASE_URL + '/list/account-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({account_options: res.data});
		});

		Axios.get(API_BASE_URL + '/list/paymentmethod-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({payment_methods: res.data});
		});
				
		Axios.get(API_BASE_URL + '/list/currency-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({currency_options: res.data});
		});
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value } );


	//datePicker
	handleDateChange = (date) => {
		this.setState({  date1:date   });  
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onCurrencyChange = (e) => {
		this.setState({currency: e});
	}
	onToAccountChange = (e) => {
		this.setState({toAccount: e});
	}
	
	onCompanyChange = (e) => {
		this.setState({company: e});
	}

	onMethodChange = (e) => {
		this.setState({paymentMethod: e});
	}
	onFromAccountChange = (e) => {
		this.setState({fromAccount: e});
	}

			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var transfer = {
				date: Math.floor(this.state.date1.getTime()/1000),
				fromAccount: this.state.fromAccount.value,
				toAccount: this.state.toAccount.value,
				amount: this.state.amount,
				paymentMethod: this.state.paymentMethod.value,
				notes: this.state.notes,
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.id !== null) {
				promise = this.props.updateItem('/transfers/'+this.state.id, transfer);
			} else {
				promise = this.props.createItem('/transfers', transfer);
			}

			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit'):t('Add')} {t("Transfers")}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="date" className="col-sm-3 col-form-label">{t("Date")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="input-group-prepend" >
														<span className="input-group-text"><i className="ti-calendar"></i></span>
													</div>
													<DatePicker
														name="date1"
														locale="fr"
														className="form-control"
														dateFormat="yyyy-MM-dd"
														selected={this.state.date1}
														onChange={this.handleDateChange}
													/>
												</div>
													{this.validator.message('date1', this.state.date1, 'required')}
											</div>
										</div>

											
										
										<div className="form-group row">
											<label htmlFor="fromAccount" className="col-sm-3 col-form-label">{t("From Account")}</label>
											<div className="col-sm-9"> 
												<Select 
													name="fromAccount"
													value={this.state.fromAccount}
													id="fromAccount"
													onChange={this.onFromAccountChange}
													options = {this.state.account_options} 
												/>
												{this.validator.message('fromAccount', this.state.fromAccount, 'required')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="toAccount" className="col-sm-3 col-form-label">{t("To Account")}</label>
											<div className="col-sm-9">
												<Select 
													name="toAccount"
													value={this.state.toAccount}
													id="account"
													onChange={this.onToAccountChange}
													options = {this.state.account_options} 
												/>
												{this.validator.message('toAccount', this.state.toAccount, 'required')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="amount" className="col-sm-3 col-form-label">{t("Amount")}</label>
											<div className="col-sm-9">
												<input value={this.state.amount} onChange={this.onChange} id="amount" type="text" name="amount" className="form-control" placeholder={t("amount")}></input>
												{this.validator.message('amount', this.state.amount, 'required|numeric')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="paymentMethod" className="col-sm-3 col-form-label">{t("Payment Method")}</label>
											<div className="col-sm-9">
												<Select 
													name="paymentMethod"
													value={this.state.paymentMethod}
													id="category"
													onChange={this.onMethodChange}
													options = {this.state.payment_methods} 
												/>
												{this.validator.message('paymentMethod', this.state.paymentMethod, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="notes" className="col-sm-3 col-form-label">{t("Notes")}</label>
											<div className="col-sm-9">
												<input value={this.state.notes} onChange={this.onChange} id="notes" type="text" name="notes" className="form-control" placeholder={t("Notes")}></input>
												{/* {this.validator.message('notes', this.state.description, 'required|string')} */}
											</div>
										</div>
									
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.transfer.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (TransfersForm);