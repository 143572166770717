import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import SweetAlert  from 'react-bootstrap-sweetalert';
import {t} from '../../../components/Translation';
import Select from 'react-select';

import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

class LocalizationSettings extends Component {

	state = {
	  	date_format: 'DD-MM-YYYY',
		datetime_format: 'DD-MM-YYYY hh:mm:ss',
	  	financial_year_start: new Date(),
		financial_year_end: new Date(),
	  	percent_position: 'right',
	  	timezone: 'Europe/Paris',
		alert: null,

		dateFormats: [
			{label: "DD-MM-YYYY", value: "DD-MM-YYYY"},
			{label: "YYYY-MM-DD", value: "YYYY-MM-DD"},
		],
		datetimeFormats: [
			{label: "DD-MM-YYYY hh:mm:ss", value: "DD-MM-YYYY hh:mm:ss"},
		],
		timezones: [
			{label: "Asia/Karachi", value: "Asia/Karachi"},
			{label: "Europe/Paris", value: "Europe/Paris"}
		],
		percentPositions: [
			{label: t("Left"), value: "left"},
			{label: t("Right"), value: "right"}
		]
	}
	
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		registerLocale('fr', fr);
		Axios.get(API_BASE_URL + '/settings/localization', AuthService.getAuthHeader())
		.then(res => {
			var data = res.data;
			this.setState({
				...data,
				financial_year_start: new Date(data.financial_year_start*1000),
				financial_year_end: new Date(data.financial_year_end*1000),
			});
		});
	}
	
	hideAlert() {
		this.setState({   alert: null  });
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });

	onSelectChange = (field, e) => {
		this.setState({[field]: e.value});
	}

	onDateChange = (field, date) => {
        this.setState({[field]: date});
    }
			
	onSubmit = (e) => {
		e.preventDefault();
		if (this.validator.allValid()) {
			var localization = this.state;			
			var requestUrl = API_BASE_URL + '/settings/localization';
			var requestMethod = 'put';
			Axios.request({
				url: requestUrl, 
				method: requestMethod,
				data: {
					...localization,
					financial_year_start: Math.round(localization.financial_year_start.getTime() / 1000),
					financial_year_end: Math.round(localization.financial_year_end.getTime() / 1000),
				},
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + AuthService.getToken()
				}
			})
			.then(res => {						
				const alertBox = () => ( 
		            <SweetAlert
						success				
						confirmBtnBsStyle="success"
						title={t("Success!")}
						onConfirm={() => {					
							this.hideAlert();
						}}>
					</SweetAlert> 
				);
		        this.setState({   alert: alertBox()  });								
			});
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				{this.state.alert}
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{t("Localization Settings")}</div>
								<div className="card-body">			
									<form className="form-horizontal" method='post' onSubmit={this.onSubmit} >
										
										<div className="form-group row">
											<label htmlFor="date_format" className="col-sm-3 col-form-label">{t("Date format")}</label>
											<div className="col-sm-9">
												<Select 
													name="date_format"
													value={this.state.dateFormats.filter(option => option.value === this.state.date_format)}
													id="date_format"
													onChange={(e) => this.onSelectChange("date_format", e)}
													options = {this.state.dateFormats} 
												/>
												{this.validator.message('date_format', this.state.date_format, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="datetime_format" className="col-sm-3 col-form-label">{t("Datetime format")}</label>
											<div className="col-sm-9">
												<Select 
													name="datetime_format"
													value={this.state.datetimeFormats.filter(option => option.value === this.state.datetime_format)}
													id="datetime_format"
													onChange={(e) => this.onSelectChange("datetime_format", e)}
													options = {this.state.datetimeFormats} 
												/>
												{this.validator.message('datetime_format', this.state.datetime_format, 'required')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="financial_year_start" className="col-sm-3 col-form-label">{t("Financial year start")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="input-group-prepend" >
														<span className="input-group-text"><i className="ti-calendar"></i></span>
													</div>
													<DatePicker
														name="financial_year_start"
														locale="fr"
														dateFormat="yyyy-MM-dd"
														className="form-control"
														selected={this.state.financial_year_start}
														onChange={(date) => this.onDateChange("financial_year_start", date)}
													/>
												</div>
												{this.validator.message('financial_year_start', this.state.financial_year_start, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="financial_year_end" className="col-sm-3 col-form-label">{t("Financial year end")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="input-group-prepend" >
														<span className="input-group-text"><i className="ti-calendar"></i></span>
													</div>
													<DatePicker
														name="financial_year_end"
														locale="fr"
														dateFormat="yyyy-MM-dd"
														className="form-control"
														selected={this.state.financial_year_end}
														onChange={(date) => this.onDateChange("financial_year_end", date)}
													/>
												</div>
												{this.validator.message('financial_year_end', this.state.financial_year_end, 'required')}
											</div>
										</div>                                           

										<div className="form-group row">
											<label htmlFor="percent_position" className="col-sm-3 col-form-label">{t("Percent position")}</label>
											<div className="col-sm-9">
												<Select 
													name="percent_position"
													value={this.state.percentPositions.filter(option => option.value === this.state.percent_position)}
													id="percent_position"
													onChange={(e) => this.onSelectChange("percent_position", e)}
													options = {this.state.percentPositions} 
												/>
												{this.validator.message('percent_position', this.state.percent_position, 'required')}
											</div>
										</div>																	
										
										<div className="form-group row">
											<label htmlFor="timezone" className="col-sm-3 col-form-label">{t("TimeZone")}</label>
											<div className="col-sm-9">
												<Select 
													name="timezone"
													value={this.state.timezones.filter(option => option.value === this.state.timezone)}
													id="timezone"
													onChange={(e) => this.onSelectChange("timezone", e)}
													options = {this.state.timezones} 
												/>
												{this.validator.message('timezone', this.state.timezone, 'required')}
											</div>
										</div>																		
																
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

export default LocalizationSettings;