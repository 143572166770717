import React , {Component } from 'react';
import { Link } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import { t } from '../../../components/Translation';

class View extends Component {

    render() {
        return (
            <AUX>
                <div className="container-fluid">
                   
                    <div className="row">

                        <div className="col-12">

                            <div className="card">
                                <div className="col card-header">
                                    <h6>{t("Revenue")}</h6> 
                                </div>
                                
                                <div className="card-body">

                                        <div className="row">

                                            <div className="col-sm-12 col-md-6 border-right">
                                                <table className="table table-striped table-borderless mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">{t("Date")}</th>
                                                            <td>22-01-2020</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">{t("Amount")}</th>
                                                            <td>$ 9,345,67</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">{t("Invoice number")}</th>
                                                            <td>INV-######</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">{t("Customer")}</th>
                                                            <td>Customer name</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <table className="table table-borderless mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">{t("Account")}</th>
                                                            <td>Bank</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">{t("Category")}</th>
                                                            <td>IT Services</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">{t("Payment Method")}</th>
                                                            <td>Bank transfer</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">{t("Internal Reference")}</th>
                                                            <td>12345678</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                        <div className="row mb-3 mt-3">
                                            <div className="col-12">
                                                <table className="table table-borderless mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">{t("Description")}</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Description notes here ...</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <label className="control-label col-12">{t("Attachment")}</label>
                                            
                                            <div className="col-12">
                                                <div className="col-2 float-left">
                                                    <div className="card m-b-20">
                                                        <img className="card-img-top img-fluid" src="/assets/images/img-3.jpg" alt="Preview" />
                                                            <div className="p-t-10 p-b-10 text-center">
                                                            <Link to="#" className="text-muted font-500">{t("Download")}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 float-left">
                                                    <div className="card m-b-20">
                                                        <img className="card-img-top img-fluid" src="/assets/images/img-4.jpg" alt="Preview" />
                                                            <div className="p-t-10 p-b-10 text-center">
                                                            <Link to="#" className="text-muted font-500">{t("Download")}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="btn-toolbar form-group mt-3">
                                                    <Link to="/income/revenues" className="btn btn-outline-secondary waves-effect waves-light m-r-5"><i className="ti-back-left"></i> {t("Back")}</Link>
                                                    <Link to="/income/revenue/edit/revenueUID" className="btn btn-outline-info waves-effect waves-light m-r-5"><i className="ti-pencil"></i> {t("Edit")}</Link>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </AUX>
        );
    }
}

export default View;