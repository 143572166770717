import React from 'react';
import Aux from '../../hoc/Aux_';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Layout.css';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

const layout = ( props ) => {

    return(
        <Aux>
        {!props.isloginpage ?
        <main>
         {props.topbar ? <Header /> : null}
            <div className="wrapper">
               {props.children}
            </div>
         {props.sidebar ? <Footer /> : null}
         </main>:props.children} 
         <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} lightBackground/>
         </Aux>  
    );
      
}

export default layout;