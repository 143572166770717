import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';

import {CRUD_AGREEMENT_LIST, CRUD_AGREEMENT_SERVICES_OPTIONS, CRUD_AGREEMENT_OPTIONS, CRUD_SERVICE_OPTIONS, CRUD_AGREEMENT_FILTER_STATUS, CRUD_AGREEMENT_FILTER_PAGESIZE, CRUD_AGREEMENT_FILTER_KEYWORD, CRUD_AGREEMENT_SORT_ITEMS, CRUD_AGREEMENT_DELETE_ITEM, CRUD_AGREEMENT_CREATE_ITEM, CRUD_AGREEMENT_UPDATE_ITEM, CRUD_AGREEMENT_NEED_COMPONENT_SET,CRUD_AGREEMENT_FILTER_DATE, CRUD_AGREEMENT_FILTER_AMOUNT} from './action';

export const loadAgreements = (customerId = null) => dispatch => {
    var endpoint = '/agreements/options' + (customerId !== null ? '?customer='+customerId : '');
    return Axios.get(API_BASE_URL + endpoint, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_OPTIONS,
        options: res.data
    }));
}

// Load services for service id
export const loadServices = (agreementId = null) => dispatch => {
    var endpoint = '/services/options' + (agreementId !== null ? '?agreement='+agreementId : '');
    return Axios.get(API_BASE_URL + endpoint, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_SERVICE_OPTIONS,
        options: res.data
    }));
}

export const loadAgreementsServices = (customerId) => dispatch => {
    return Axios.get(API_BASE_URL + '/agreements/services/options?customer='+customerId, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_SERVICES_OPTIONS,
        servicesOptions: res.data
    }));
}

export const listItems = (requestUrl) => dispatch => {
    Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_LIST,
        data: res.data,
    }));
}

export const filterUponStatus = (requestUrl, status) => dispatch => {
    Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_FILTER_STATUS,
        status: status,
        data: res.data
    }));
}

export const filterUponPageSize = (requestUrl, pageSize) => dispatch => {
    Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_FILTER_PAGESIZE,
        pageSize: pageSize,
        data: res.data
    }));
}

export const filterUponKeyword = (requestUrl, query) => dispatch => {
    Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_FILTER_KEYWORD,
        query: query,
        data: res.data
    }));
}

export const sortItems = (requestUrl, sort) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_SORT_ITEMS,
        sort: sort,
        data: res.data
    }));
}
export const filterUponDates = (requestUrl, start, end) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_FILTER_DATE,
        start: start,
        end: end,
        data: res.data
    }));
}

export const filterUponAmounts = (requestUrl, amountMin, amountMax) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_FILTER_AMOUNT,
        amountMin: amountMin,
        amountMax: amountMax,
        data: res.data
    }));
}
export const deleteItem = (requestUrl) => dispatch => {
    return Axios.delete(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_DELETE_ITEM,
        needComponentUpdate:true
    }));
}

export const createItem = (requestUrl, data) => dispatch => {
    return Axios.post(API_BASE_URL + requestUrl, data, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_CREATE_ITEM,
        needComponentUpdate:true
    }));
}

export const updateItem = (requestUrl, data) => dispatch => {
    return Axios.put(API_BASE_URL + requestUrl, data, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_AGREEMENT_UPDATE_ITEM,
        needComponentUpdate:true
    }));
}

export const setNeedComponentUpdate = (status) => dispatch => {
    dispatch({
        type: CRUD_AGREEMENT_NEED_COMPONENT_SET,
        needComponentUpdate:status
    });
}