import React,{Component} from 'react';

import Top from '../Header/Top';
import MegaMenu from '../Header/Menu';

class Header extends Component {

    render() {
        return (
            <header id="topnav">
                <Top />
                <MegaMenu />
            </header>
        );
    }
}

export default Header;