import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, FILE_TYPES, API_IMAGES_URL,IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/UserActions';
import { t } from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class UserForm extends Component {

	state = {
		uid: null,
		username: '',
		
		firstname: '',
		lastname: '',
		email: '',
		language: {},
		role: '',
		status: '',
		photo: null,
		role_options: [
			{label: t('Consultant'), value: 'ROLE_CONSULTANT'},
			{label: t('Biller'), value: 'ROLE_BILLER'},
			{label: t('Employee'), value: 'ROLE_EMPLOYEE'},
		],
		language_options: [],
		status_options: [
			{label: t('Pending'), value: 0},
			{label: t('Active'), value: 1},
			{label: t('Inactive'), value: 2},
			{label: t('Deleted'), value: 3}
		],

		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/settings/user/:uid/duplicate";
		let uid = this.props.match.params.uid;
		if (typeof uid !== 'undefined' && uid !== null) {
			Axios.get(API_BASE_URL + '/users/'+uid, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					uid: (this.props.path === dpath ? null: st.uid),
					username: st.username,
					email: st.email,
					firstname: st.firstname,
					lastname: st.lastname,
					language: st.language,
					role: this.state.role_options.filter(role => role.value === st.role)[0],
					status: this.state.status_options.filter(status => status.value === st.status)[0],
					photo: st.photo
				});
			});
		}		

		Axios.get(API_BASE_URL + '/list/language-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({language_options: res.data});
		});
	}

	onStatusChange = (e) => { this.setState({ status: e });  }

	onLanguageChange = (e) => { this.setState({ language: e });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onRoleChange = (e) => {
		this.setState({role: e});
	}
	
	onChangeFile = (e) => {		
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/image', data, AuthService.getAuthHeader())
		    	.then(res => {			
					this.setState({
						photo: res.data.fileName
					});
				});
		} else {
			
		}
	}
	
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			let user = {
				username: this.state.username,
				email: this.state.email,
				firstname: this.state.firstname,
				lastname: this.state.lastname,
				language: this.state.language.value,
				role: this.state.role.value,
				status: this.state.status.value,
				photo: (this.state.photo !== null) ? this.state.photo : null

			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.uid !== null) {
				promise = this.props.updateItem('/users/'+this.state.uid, user);
			} else {
				promise = this.props.createItem('/users', user);
			}

			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit user'):t('Add New user')} </div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="username" className="col-sm-3 col-form-label">{t("Username")}</label>
											<div className="col-sm-9">
												<input readOnly={this.state.uid !== null} value={this.state.username} onChange={this.onChange} id="username" type="text" name="username" className="form-control" placeholder={t("Username")}></input>
												{this.validator.message('username', this.state.username, 'required|string')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="email" className="col-sm-3 col-form-label">{t("Email")}</label>
											<div className="col-sm-9">
												<input readOnly={this.state.uid !== null} value={this.state.email} onChange={this.onChange} id="email" type="text" name="email" className="form-control" placeholder={t("Email")} ></input>
												{this.validator.message('email', this.state.email, 'required|email')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="firstname" className="col-sm-3 col-form-label">{t("Firstname")}</label>
											<div className="col-sm-9">
												<input value={this.state.firstname} onChange={this.onChange} id="firstname" type="text" name="firstname" className="form-control" placeholder={t("Firstname")}></input>
												{this.validator.message('firstname', this.state.firstname, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="lastname" className="col-sm-3 col-form-label">{t("Lastname")}</label>
											<div className="col-sm-9">
												<input value={this.state.lastname} onChange={this.onChange} id="lastname" type="text" name="lastname" className="form-control" placeholder={t("Lastname")}></input>
												{this.validator.message('lastname', this.state.lastname, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="role" className="col-sm-3 col-form-label">{t("Role")}</label>
											<div className="col-sm-9">
												<Select
													name="role"
													value={this.state.role_options.filter(option => option.value === this.state.role.value)}
													id="role"
													onChange={this.onRoleChange}
													options = {this.state.role_options}
												/>
												{this.validator.message('role', this.state.role, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="status" className="col-sm-3 col-form-label">{t("Status")}</label>
											<div className="col-sm-9">
												<Select
													name="status"
													value={this.state.status_options.filter(option => option.value === this.state.status.value)}
													id="status"
													onChange={this.onStatusChange}
													options = {this.state.status_options}
												/>
												{this.validator.message('status', this.state.status, 'required')}
											</div>
										</div>	

										<div className="form-group row">
											<label htmlFor="language" className="col-sm-3 col-form-label">{t("Language")}</label>
											<div className="col-sm-9">
												<Select 
													name="language"
													value={this.state.language_options.filter(option => option.value === this.state.language.value)}
													id="language"
													onChange={this.onLanguageChange}
													options = {this.state.language_options} 
												/>
												{this.validator.message('language', this.state.language, 'required')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="photo" className="col-sm-3 col-form-label">{t("Photo")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="photo"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="photo"											
														aria-describedby="photo" />
														<label className="custom-file-label" htmlFor="photo">
															{t("Choose file")}
														</label>
													</div>
			                                    </div>
												{(this.state.photo !== null) && <img className='image-width' src={API_IMAGES_URL + this.state.photo} /> }
												<div >{t("Allowed file types are: {0}.", [IMAGE_TYPES.join(', ')])}</div>
											</div>
										</div>
										
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.user.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (UserForm);