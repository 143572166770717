import React  from 'react';
import Moment from 'react-moment';
import {DATETIME_FORMAT} from '../../config';
 
export default class DateTime extends React.Component {
    render() {
        const unixTimestamp = this.props.timestamp;
        return (
            <Moment format={DATETIME_FORMAT} unix>{unixTimestamp}</Moment>
        );
    }
}