import React , {Component } from 'react';
import { Link } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import {API_BASE_URL, FILES_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import Axios from 'axios';
import moment from "moment";
import SweetAlert  from 'react-bootstrap-sweetalert';
import {t} from '../../../components/Translation';
import { formatDate, formatAmount, handleError } from '../../../helper';
import { INVOICE_DRAFT, INVOICE_SENT, INVOICE_PAID, INVOICE_OVERDUE, INVOICE_CANCELLED, INVOICE_PARTIAL_PAID } from '../../../constants';

class View extends Component {

    state = {
        invoice: {
            services: [],
            attachments: [],
            history: [],
            payments: [],
        },
        companyInfo: {},
        customer: {},
        alert: null,
        globals: {
            timezone: "Europe/Paris",
            dateFormat: "DD-MM-YYYY",
            datetimeFormat: "DD-MM-YYYY hh:mm:ss",
            financial_year_start: 1640977200,
            financial_year_end: 1672513199,
            currency: {
                precision: 2,
                symbol: '€',
                symbolPosition: 'left',
                decimalMark: '.',
                thousandSeparator: ','
            }
        },
    };
    
    componentDidMount() {
		Axios.get(API_BASE_URL + '/settings/company-info', AuthService.getAuthHeader())
		.then(res => {
			this.setState({
                ...this.state,
                companyInfo: res.data
            });
        });

        Axios.get(API_BASE_URL + '/dashboard/globals', AuthService.getAuthHeader())
        .then(res => {
            this.setState({
                ...this.state,
                globals: res.data
            })
        });
        
        Axios.get(API_BASE_URL + '/invoices/' + this.props.match.params.id, AuthService.getAuthHeader())
        .then(res => {
            this.setState({
                ...this.state,
                invoice: res.data
            });

            Axios.get(API_BASE_URL + '/contacts/' + this.state.invoice.customer.value + '?roleName=ROLE_CUSTOMER', AuthService.getAuthHeader())
            .then(res => {
                this.setState({
                    ...this.state,
                    customer: res.data 
                })
            });
    
        });
	}

    refreshInvoice = (id) => {
        Axios.get(API_BASE_URL + '/invoices/' + id, AuthService.getAuthHeader())
        .then(res => {
            this.setState({
                ...this.state,
                invoice: res.data
            });
        });
    }

    showPreloader = () => {
		const alertBox = () => (
			<SweetAlert
				info
				buttons={false}
				title="In progress..."
			>Opeartion in progress...
			</SweetAlert>
		);
		this.setState({   alert: alertBox()  });
    }

    onClickPrint = () => {
        window.print();
    }

    getStatusLabel = (status) => {
        switch(status) {
            case INVOICE_DRAFT:
                return t('Draft');
            case INVOICE_SENT: 
                return t('Sent');
            case INVOICE_CANCELLED: 
                return t('Cancelled');
            case INVOICE_PARTIAL_PAID: 
                return t('Partially Paid');
            case INVOICE_PAID:
                return t('Paid');
            case INVOICE_OVERDUE:
                return t('Overdue');
            default:
                return t('Unknown');
        }
    }

    hideAlert() {
		this.setState({   alert: null  });
    }
    
    onDeletePayment = (id) => {
        const alertBox = () => (
            <SweetAlert
				danger
				showCancel
				confirmBtnText={t("Yes, delete it!")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to delete?")}
            	onCancel={() => this.hideAlert()}
				onConfirm={() => {
                    
                    // call del api
                    Axios.delete(API_BASE_URL + "/revenues/" + id, AuthService.getAuthHeader())
                    .then(res => {
                        const invoice = this.state.invoice;
                        invoice.payments = invoice.payments.filter(obj => obj.id !== id);
                        this.setState({
                            ...this.state,
                            invoice
                        });
                    });
					this.hideAlert();
				}} >
			</SweetAlert> 
		);
        this.setState({   alert: alertBox()  });
    }

    markSent = (id) => {

        const alertBox = () => (
        <SweetAlert
				danger
				showCancel
				confirmBtnText={t("Yes")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to perform this action?")}
            	onCancel={() => this.hideAlert()}
				onConfirm={() => {
                    
                    // this.showPreloader();
                    Axios.put(API_BASE_URL + "/invoices/" + id + "/mark-sent", {}, AuthService.getAuthHeader())
                    .then(res => {
                        this.refreshInvoice(id);
                        const alertBox = () => (
                            <SweetAlert
                                success
                                title="Success"
                                onConfirm={() => {
                                    this.hideAlert();
                                }}>Invoice marked as sent!
                            </SweetAlert>
                        );
                        this.setState({   alert: alertBox()  });
                    })
                    .catch(error => {
                        var message = handleError(error);
                        const alertBox = () => (
                            <SweetAlert
                                danger
                                title="Error"
                                onConfirm={() => {
                                    this.hideAlert();
                                }}>{message}
                            </SweetAlert>
                        );
                        this.setState({   alert: alertBox()  });
                    });
				}} >
        </SweetAlert> 
        );
        this.setState({   alert: alertBox()  });   
    }

    onClickExport = (id) => {

        var btn = document.getElementById('btn-export');
        btn.setAttribute('disabled', 'disabled');

        this.showPreloader();

        Axios.post(API_BASE_URL + "/invoices/" + id + "/generate", {}, AuthService.getAuthHeader())
        .then(res => {
            
            this.hideAlert();
            var filename = this.state.invoice.invoiceNumber + ".pdf";
            var a = document.createElement("a"),
            url = FILES_URL + filename;
            a.href = url;
            a.download = filename;
            a.target = "_blank";
            a.style = "display:none;opacity:0;";
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 100000); 
            btn.removeAttribute('disabled');
        })
        .catch(error => {
            const alertBox = () => (
                <SweetAlert
                    danger
                    title="Error"
                    onConfirm={() => {
                        this.hideAlert();
                        btn.removeAttribute('disabled');
                    }}>{handleError(error)}
                </SweetAlert>
            );
            this.setState({   alert: alertBox()  });
        });
    }


    markPaid = (id) => {

        const alertBox = () => (
        <SweetAlert
				danger
				showCancel
				confirmBtnText={t("Yes")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to perform this action?")}
            	onCancel={() => this.hideAlert()}
				onConfirm={() => {
                    
                    // this.showPreloader();
                    Axios.put(API_BASE_URL + "/invoices/" + id + "/mark-paid", {}, AuthService.getAuthHeader())
                    .then(res => {
                        this.refreshInvoice(id);
                        const alertBox = () => (
                            <SweetAlert
                                success
                                title="Success"
                                onConfirm={() => {
                                    this.hideAlert();
                                }}>Invoice marked as paid!
                            </SweetAlert>
                        );
                        this.setState({   alert: alertBox()  });
                    })
                    .catch(error => {
                        var message = handleError(error);
                        const alertBox = () => (
                            <SweetAlert
                                danger
                                title="Error"
                                onConfirm={() => {
                                    this.hideAlert();
                                }}>{message}
                            </SweetAlert>
                        );
                        this.setState({   alert: alertBox()  });
                    });
				}} >
        </SweetAlert> 
        );
        this.setState({   alert: alertBox()  });   
    }

    sendInvoieEmail = (id) => {

        const alertBox = () => (
        <SweetAlert
				danger
				showCancel
				confirmBtnText={t("Yes")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to perform this action?")}
            	onCancel={() => this.hideAlert()}
				onConfirm={() => {
                    
                    // this.showPreloader();
                    Axios.post(API_BASE_URL + "/invoices/" + id + "/send-mail", {}, AuthService.getAuthHeader())
                    .then(res => {
                        this.refreshInvoice(id);
                        const alertBox = () => (
                            <SweetAlert
                                success
                                title="Success"
                                onConfirm={() => {
                                    this.hideAlert();
                                }}>Invoice email sent to customer!
                            </SweetAlert>
                        );
                        this.setState({   alert: alertBox()  });
                    })
                    .catch(error => {
                        var message = handleError(error);
                        const alertBox = () => (
                            <SweetAlert
                                danger
                                title="Error"
                                onConfirm={() => {
                                    this.hideAlert();
                                }}>{message}
                            </SweetAlert>
                        );
                        this.setState({   alert: alertBox()  });
                    });
				}} >
        </SweetAlert> 
        );
        this.setState({   alert: alertBox()  });   
    }

    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    {this.state.alert}
                    <div className="row">

                        <div className="col-12">

                            <div className="card">
                                <div className="col card-header">
                                    <h6>Invoice#  {this.state.invoice.invoiceNumber}
                                    {(this.state.invoice.status === INVOICE_DRAFT) && <span className="stamp float-right text-secondary">{t('Draft')}</span>}
                                    {(this.state.invoice.status === INVOICE_SENT) && <span className="stamp float-right text-warning">{t('Sent')}</span>}
                                    {(this.state.invoice.status === INVOICE_CANCELLED) && <span className="stamp float-right text-danger">{t('Cancelled')}</span>}
                                    {(this.state.invoice.status === INVOICE_PARTIAL_PAID) && <span className="stamp float-right text-success"><span className="text-warning">{t('Partial')}</span> {t("Paid")}</span>}
                                    {(this.state.invoice.status === INVOICE_PAID) && <span className="stamp float-right text-success">{t('Paid')}</span>}
                                    {(this.state.invoice.status === INVOICE_OVERDUE) && <span className="stamp float-right text-danger">{t('Overdue')}</span>}
                                    </h6> 
                                </div>
                                
                                <div className="card-body border-top border-success border-width-3">

                                        <div className="row border-bottom mb-3">

                                            <div className="col-7">
                                                <div className="logo">
                                                        <img src="/assets/images/logo.png" alt="Company Logo" className="logo-large" />
                                                </div>
                                            </div>

                                            <div className="col-5 mb-3">
                                                <div className="font-weight-bold">{this.state.companyInfo.name}</div>
                                                <div>{this.state.companyInfo.address}</div>
                                                <div>{this.state.companyInfo.address2}</div>
                                                <div>{this.state.companyInfo.city}, {this.state.companyInfo.country}</div>
                                                <div className="mt-1">Tax Number: {this.state.companyInfo.tax}</div>
                                                <div className="mt-2">{this.state.companyInfo.email}</div>
                                            </div>

                                        </div>

                                        <div className="row mb-3">
                                            
                                            <div className="col-sm-12 col-md-7">
                                                <div className="control-label">Bill to</div>
                                                <div className="p-1">
                                                    <div className="font-weight-bold">{this.state.customer.fullname}</div>
                                                    <div>{this.state.customer.address}</div>
                                                    {/* <div>Address Line 2 (if any)</div>
                                                    <div>City, COUNTRY</div>
                                                    <div className="mt-1">Tax Number: #######</div> */}
                                                    <div className="mt-2">{this.state.customer.email}</div>
                                                    <div className="mt-1">{this.state.customer.phone}</div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-5">
                                                <div className="font-weight-bold mt-3 mb-3">{t("Invoice Number")}: <span className="font-weight-normal float-right">{this.state.invoice.invoiceNumber}</span></div>
                                                <div className="font-weight-bold mb-3">{t("Order Number")}: <span className="font-weight-normal float-right">{this.state.invoice.orderNumber}</span></div>
                                                <div className="font-weight-bold mb-3">{t("Invoice Date")}: <span className="font-weight-normal float-right">{formatDate(this.state.invoice.issuedate, this.state.globals.dateFormat)}</span></div>
                                                <div className="font-weight-bold mb-3">{t("Payment Due")}: <span className="font-weight-normal float-right">{formatDate(this.state.invoice.duedate, this.state.globals.dateFormat)}</span></div>
                                            </div>

                                        </div>

                                        <div className="row">
                                                
                                            <div className="col-12 mt-3 table-responsive">
                                                <table className="table table-vertical table-striped">
                                                    <thead className="thead-light">
                                                        <tr className="text-right">
                                                            <th className="text-left col-1">{t("Reference")}</th>
                                                            <th className="text-left col-1">{t("Name")}</th>
                                                            <th className="text-center col-md-1">{t("Quantity")}</th>
                                                            <th className="col-md-2">{t("Price")}</th>
                                                            <th className="col-md-2">{t("Tax")}</th>
                                                            <th className="col-md-2">{t("Total")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.invoice.services.map((row, index) => {
                                                            return <tr key={index} className="text-right">
                                                                <td className="text-left">{row.reference}</td>
                                                                <td className="text-left">{row.name}</td>
                                                                <td className="text-center">{row.quantity}</td>
                                                                <td>{formatAmount(row.price, this.state.globals.currency)}</td>
                                                                <td>{formatAmount(row.taxAmount, this.state.globals.currency)}</td>
                                                                <td>{formatAmount(row.total, this.state.globals.currency)}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>

                                            </div>

                                            <div className="offset-md-8 table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr className="text-left">
                                                            <td className="font-weight-bold">{t("Subtotal")}</td>
                                                            <td className="text-right">{formatAmount(this.state.invoice.subtotal, this.state.globals.currency)}</td>
                                                        </tr>
                                                        {(this.state.invoice.discountType !== null && this.state.invoice.discount > 0) && 
                                                            <tr className="text-left">
                                                                <td>Discount (
                                                                    {(this.state.invoice.discountType === 1) ? 
                                                                        (formatAmount(this.state.invoice.discountValue, this.state.globals.currency)) : 
                                                                        (this.state.invoice.discountValue+"%")}
                                                                    )</td>
                                                                <td className="text-right">-{formatAmount(this.state.invoice.discount, this.state.globals.currency)}</td>
                                                            </tr>
                                                        }
                                                        
                                                        {(this.state.invoice.tax > 0) &&
                                                            <tr className="text-left">
                                                                <td className="font-weight-bold">{t("Tax")} <span className="font-weight-normal">(VAT)</span></td>
                                                                <td className="text-right">{formatAmount(this.state.invoice.tax, this.state.globals.currency)}</td>
                                                            </tr>
                                                        }
                                                        
                                                        <tr className="text-left font-weight-bold">
                                                            <td>{t("Total")}</td>
                                                            <td className="text-right">{formatAmount(this.state.invoice.total, this.state.globals.currency)}</td>
                                                        </tr>
                                                        <tr className="text-left text-success">
                                                            <td>{t("Payments")}</td>
                                                            <td className="text-right">{formatAmount(this.state.invoice.amountPaid, this.state.globals.currency)}</td>
                                                        </tr>
                                                        <tr className="text-left font-weight-bold">
                                                            <td>{t("Amount due")}</td>
                                                            <td className="text-right">{formatAmount(this.state.invoice.amountDue, this.state.globals.currency)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-12 form-group">
                                                <label className="control-label">{t("Notes")}</label>
                                                <div>{this.state.invoice.notes}</div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <label className="control-label col-12">{t("Attachment")}</label>
                                            
                                            <div className="col-12">
                                                {this.state.invoice.attachments.map((file, index) => {
                                                    return <div key={index} className="col-2 float-left">
                                                        <div className="card m-b-20 text-center">
                                                            <img className="card-img-top img-fluid dld" src="/assets/images/download.png" alt="Preview" />
                                                                <div className="p-t-10 p-b-10 text-center">
                                                                <a href={FILES_URL + file.name} className="text-muted font-500">{file.originalFilename}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="btn-toolbar form-group mt-3">
                                                    <Link to="/income/invoices" className="btn btn-outline-secondary waves-effect waves-light m-r-5"><i className="ti-back-left"></i> {t("Back")}</Link>
                                                    {(this.state.invoice.status === INVOICE_DRAFT) && <Link to={"/income/invoice/" + this.state.invoice.id + "/update"} className="btn btn-outline-info waves-effect waves-light m-r-5"><i className="ti-pencil"></i> {t("Edit")}</Link>}
                                                    <button onClick={this.onClickPrint} className="btn btn-outline-success waves-effect waves-light m-r-5"><i className="ti-printer"></i> {t("Print")}</button>
                                                    <button id="btn-export" onClick={() => this.onClickExport(this.state.invoice.id)} className="btn btn-outline-primary waves-effect waves-light m-r-5"><i className="ti-back-right"></i> {t("Export PDF")}</button>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row">

                        <div className="col-4">

                            <div className="card">
                                <div className="col card-header">
                                    <h6>{t("Actions")}</h6> 
                                </div>

                                <div className="card-body">

                                    <div className="container">
                                        <div className="row">
                                            <div>
                                                <ul className="timeline">
                                                    <li className="text-secondary">
                                                        <div className="text-dark font-14"> {t("Created")} - <span className="text-secondary font-small">{(moment(this.state.invoice.created*1000).format('DD-MM-YYYY HH:mm:ss'))}</span></div>
                                                        {(this.state.invoice.status === INVOICE_DRAFT) && <div>
                                                            <Link to={"/income/invoice/"+this.state.invoice.id+"/update"} className="btn btn-outline-info btn-sm waves-effect waves-light m-r-5"><i className="ti-pencil"></i> {t("Edit")}</Link>
                                                        </div>}
                                                    </li>
                                                    <li className="text-warning">
                                                        <div className="text-dark font-14"> {t("Sent to customer")} - <span className="text-secondary font-small">{(this.state.invoice.status >= INVOICE_SENT) ? formatDate(this.state.invoice.history.filter(his => his.status === INVOICE_SENT)[0].date, this.state.globals.datetimeFormat) : t('Not Sent')}</span></div>
                                                        {(this.state.invoice.status < INVOICE_SENT) && <div> 
                                                            <button onClick={this.markSent.bind(this, this.state.invoice.id)} className="btn btn-outline-secondary btn-sm waves-effect waves-light m-r-5"><i className="ti-email"></i> {t("Mark sent")}</button>
                                                            <button onClick={this.sendInvoieEmail.bind(this, this.state.invoice.id)} className="btn btn-outline-warning btn-sm waves-effect waves-light m-r-5"><i className="ti-email"></i> {t("Send email")}</button>
                                                        </div>}
                                                    </li>
                                                    {(this.state.invoice.status >= INVOICE_SENT) && 
                                                    <li className="text-success">
                                                        <div className="text-dark font-14"> {t("Payment")} - <span className="text-secondary font-small">{(this.state.invoice.status === INVOICE_PAID) ? formatDate(this.state.invoice.history.filter(his => his.status === INVOICE_PAID)[0].date, this.state.globals.datetimeFormat) : (this.state.invoice.amountDue > 0 && this.state.invoice.amountPaid > 0) ? t('Partially paid') : t('Unpaid')}</span></div>
                                                        <div> 
                                                            {(this.state.invoice.status !== INVOICE_PAID) && <>
                                                                {(this.state.invoice.amountDue < 1) && <button onClick={this.markPaid.bind(this, this.state.invoice.id)} className="btn btn-outline-secondary btn-sm waves-effect waves-light m-r-5"><i className="ti-money"></i> {t("Mark paid")}</button>}
                                                                {(this.state.invoice.amountDue > 0) && <Link to={"/income/invoice/"+ this.state.invoice.id +"/payment"} className="btn btn-outline-success btn-sm waves-effect waves-light m-r-5"><i className="ti-email"></i> {t("Add payment")}</Link>}
                                                            </>}
                                                        </div>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>

                        <div className="col-4">
                            <div className="card">
                                <div className="col card-header">
                                    <h6>{t("History")}</h6>
                                </div>

                                <div className="card-body p-0">

                                    <div className="container">
                                        <div className="row">
                                            <div className="table-responsive">
                                                <table className="table table-vertical mb-1">
                                                    <thead>
                                                        <tr>
                                                            <th>{t("Date")}</th>
                                                            <th>{t("Status")}</th>
                                                            <th>{t("Description")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.invoice.history.map((row, index) => {
                                                            return <tr key={index}>
                                                                <td>{formatDate(row.date, this.state.globals.dateFormat)}</td>
                                                                <td>{this.getStatusLabel(row.status)}</td>
                                                                <td>{row.description}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                        </div>

                        <div className="col-4">

                            <div className="card">
                                <div className="col card-header">
                                    <h6>{t("Payments")}</h6> 
                                </div>

                                <div className="card-body p-0">

                                    <div className="container">
                                        <div className="row">
    
                                            <div className="table-responsive">
                                                <table className="table table-vertical mb-1">
                                                    <thead>
                                                        <tr>
                                                            <th>{t("Date")}</th>
                                                            <th>{t("Amount")}</th>
                                                            <th>{t("Account")}</th>
                                                            <th>{t("Actions")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.invoice.payments.length < 1) && 
                                                            <tr>
                                                                <td colSpan="4" className="text-center">{t("No payments")}!</td>
                                                            </tr>
                                                        }
                                                        {this.state.invoice.payments.map((row, index) => {
                                                            return <tr key={index}>
                                                                <td>{formatDate(row.date, this.state.globals.dateFormat)}</td>
                                                                <td>{formatAmount(row.amount, this.state.globals.currency)}</td>
                                                                <td>{row.account}</td>
                                                                <td>
                                                                    <button onClick={this.onDeletePayment.bind(this, row.id)} className="btn btn-outline-danger font-small waves-effect waves-light m-r-5"><i className="ti-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </AUX>
        );
    }
}

export default View;