import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import {AreaChart} from 'react-easy-chart';
import {connect} from 'react-redux';
import {loadGlobals, loadSalesChart, getDashboardCounters, getUnpaidInvoices, getIncomes, getLatestPayments, getLatestMessages} from '../../../store/DashboardActions';
import { listAllowedPermissions } from '../../../store/PermissionActions';
import {t} from '../../../components/Translation';
import { formatDate, formatAmount } from '../../../helper';
import UnpaidInvoices from './UnpaidInvoices';

class Dashboard extends Component {
  
    state = {
        
    }

    componentDidMount() {
        this.props.listAllowedPermissions();
        this.props.loadGlobals();
        this.props.getDashboardCounters(this.props.globals.financial_year_start, this.props.globals.financial_year_end);
        // this.props.getUnpaidInvoices(this.props.globals.financial_year_start, this.props.globals.financial_year_end);
        this.props.getIncomes(this.props.globals.financial_year_start, this.props.globals.financial_year_end);
        this.props.getLatestPayments(this.props.globals.financial_year_start, this.props.globals.financial_year_end);
        this.props.getLatestMessages();
        this.props.loadSalesChart(this.props.globals.financial_year_start, this.props.globals.financial_year_end);
    }

    render() {
        return(
            <AUX>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="card mini-stat bg-primary">
                                <div className="card-body mini-stat-img">
                                    <div className="mini-stat-icon">
                                        <i className="ti-crown float-right"></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3">{t("Agreement")}</h6>
                                        <h4 className="mb-4">{this.props.agreement}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mini-stat bg-primary">
                                <div className="card-body mini-stat-img">
                                    <div className="mini-stat-icon">
                                        <i className="ti-shopping-cart float-right"></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3">{t("Revenue")}</h6>
                                        <h4 className="mb-4">{(formatAmount(this.props.revenue, this.props.globals.currency))}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mini-stat bg-primary">
                                <div className="card-body mini-stat-img">
                                    <div className="mini-stat-icon">
                                        <i className="ti-money float-right"></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3">{t("Payment")}</h6>
                                        <h4 className="mb-4">{(formatAmount(this.props.payment, this.props.globals.currency))}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mini-stat bg-primary">
                                <div className="card-body mini-stat-img">
                                    <div className="mini-stat-icon">
                                        <i className="ti-cup float-right"></i>
                                    </div>
                                    <div className="text-white">
                                        <h6 className="text-uppercase mb-3">{t("Profit")}</h6>
                                        <h4 className="mb-4">{(formatAmount((this.props.profit < 0 ? 0 : this.props.profit), this.props.globals.currency))}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title">{t("Sales analytics")}</h4>
                
                                    <div className="row text-center m-t-20">
                                        <div className="col-4">
                                            <h5 className="">{(formatAmount(this.props.saleschart.yearTotal, this.props.globals.currency))}</h5>
                                            <p className="text-muted">Last 12 months</p>
                                        </div>
                                        <div className="col-4">
                                            <h5 className="">{(formatAmount(this.props.saleschart.monthTotal, this.props.globals.currency))}</h5>
                                            <p className="text-muted">This month</p>
                                        </div>
                                        <div className="col-4">
                                            <h5 className="">{(formatAmount(this.props.saleschart.weekTotal, this.props.globals.currency))}</h5>
                                            <p className="text-muted">This week</p>
                                        </div>
                                    </div>

                                    <div id="morris-area-example" className="dashboard-charts morris-charts">
                                    <AreaChart
                                        axes
                                        margin={{top: 10, right: 10, bottom: 50, left: 50}}
                                        axisLabels={{x: 'My x Axis', y: 'My y Axis'}}
                                        width={690}
                                        interpolate={'cardinal'}
                                        height={320}
                                        areaColors={['#6dd6cf']}
                                        data={[
                                            this.props.saleschart.data
                                        ]}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div className="col-xl-4">
                            <UnpaidInvoices />
                        </div>

                    </div>
                    
                    <div className="row">
                        
                        <div className="col-xl-4">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 m-b-30 header-title">{t("Latest Incomes")}</h4>
    
                                    <div className="table-responsive" style={{minHeight: 300}}>
                                        <table className="table table-vertical mb-1">
                                            <thead>
                                                <tr>
                                                    <th>{t("Date")}</th>
                                                    <th>{t("Invoice")}</th>
                                                    <th>{t("Amount")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.latestIncomes.map((val, index) => {
                                                    return <tr key={index}>
                                                        <td>{(formatDate(val.date, this.props.globals.dateFormat))}</td>
                                                        <td>{val.invoiceNumber}</td>
                                                        <td>{(formatAmount(val.amount, this.props.globals.currency))}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div className="col-xl-4">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 m-b-30 header-title">{t("Latest Payments")}</h4>
    
                                    <div className="table-responsive" style={{minHeight: 300}}>
                                        <table className="table table-vertical mb-1">
                                            <thead>
                                                <tr>
                                                    <th>{t("Date")}</th>
                                                    <th>{t("Account")}</th>
                                                    <th>{t("Amount")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.latestPayments.map((val, index) => {
                                                    return <tr key={index}>
                                                        <td>{(formatDate(val.date, this.props.globals.dateFormat))}</td>
                                                        <td>{val.account}</td>
                                                        <td>{(formatAmount(val.amount, this.props.globals.currency))}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 m-b-30 header-title">{t("Latest Messages")} 
                                        {(this.props.latestMessages.newMessages > 0) &&
                                        <span className="badge badge-pill badge-danger noti-icon-badge"> {this.props.latestMessages.newMessages} {t("new")}</span> }
                                    </h4>
                                    
                                    <div className="table-responsive" style={{minHeight: 300}}>
                                        <table className="table table-vertical mb-1">
                                            <thead>
                                                <tr>
                                                    <th>{t("Date")}</th>
                                                    <th>{t("From")}</th>
                                                    <th>{t("Subject")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.latestMessages.messages.map((row, index) => {
                                                    return <tr key={index}>
                                                        <td>{(formatDate(row.created, this.props.globals.dateFormat))}</td>
                                                        <td>{row.customer}</td>
                                                        <td><i className="mdi mdi-checkbox-blank-circle" style={{color: (row.color+' !important')}}></i>{row.subject}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStateToProps = state => ({
    agreement: state.dash.agreement,
    revenue: state.dash.revenue,
    payment: state.dash.payment,
    profit: state.dash.profit,

    unpaidInvoices: state.dash.unpaidInvoices,
    latestIncomes: state.dash.latestIncomes,
    latestPayments: state.dash.latestPayments,
    latestMessages: state.dash.latestMessages,
    globals: state.dash.globals,
    saleschart: state.dash.saleschart,
    allowedPermissions: state.permission.allowedPermissions,
});

export default connect( mapStateToProps, {listAllowedPermissions, loadGlobals, loadSalesChart, getDashboardCounters, getUnpaidInvoices, getIncomes, getLatestPayments, getLatestMessages} ) ( Dashboard );