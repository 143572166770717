import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Action from '../../../components/UI/DataTable/Action';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/PaymentMethodActions';
import { t } from '../../../components/Translation';

class PaymentMethodList extends Component {

	state = {	
		alert: null,	
		baseUrl: '/paymentmethods',
		addBtn: {
			label: t('Create new method'),
			url: this.props.formPath + "/create"
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },
		    {
		      label: t('Id'),
		      field: 'id',
		      sort: false
		    },
		    {
		      label: t('Name'),
		      field: 'name',
		      sort: true
		    },
		    {
		      label: t('Iso'),
		      field: 'iso',
		      sort: true
		    },
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: []
	}

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}
	
	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id': value.id,					
				'name': value.name,
				'iso': value.iso,					
				'actions': <Action type="paymentmethod" state={value} path={this.props.formPath} onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}

	render() {

		let rows = this.mapAttributes(this.props.rows);

		return (
			<AUX>
				
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
                                        rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.paymentmethod.rows,
	pagination: state.paymentmethod.pagination,
	pageSize: state.paymentmethod.pageSize,
})

export default connect( mapStateToProps, {deleteItem} ) ( PaymentMethodList );