import React  from 'react';
import {API_IMAGES_URL} from '../../../config';
import '../../Text/String';
 
export default class ImageString extends React.Component {
    render() {
		const image = API_IMAGES_URL + this.props.image;
		const string = this.props.string;//.Capitalize();
		const imgStyle = {
			width: '25px'
		};
		
        return (
            <React.Fragment>
				<img style={imgStyle} src={image} alt="Language icon" /> {string}
			</React.Fragment>
        );
    }
}