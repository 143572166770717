import Axios from 'axios';
import {API_BASE_URL} from '../config';
import AuthService from '../containers/Auth/AuthService';

import {CRUD_TRANSFERS_LIST, CRUD_TRANSFERS_FILTER_STATUS, CRUD_TRANSFERS_FILTER_PAGESIZE, CRUD_TRANSFERS_FILTER_KEYWORD, CRUD_TRANSFERS_SORT_ITEMS, CRUD_TRANSFERS_DELETE_ITEM, CRUD_TRANSFERS_CREATE_ITEM, CRUD_TRANSFERS_UPDATE_ITEM, CRUD_TRANSFERS_NEED_COMPONENT_SET,CRUD_TRANSFERS_FILTER_DATE, CRUD_TRANSFERS_FILTER_AMOUNT} from './action';

export const listItems = (requestUrl) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_LIST,
        data: res.data,
    }));
}

export const filterUponStatus = (requestUrl, status) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_FILTER_STATUS,
        status: status,
        data: res.data
    }));
}

export const filterUponPageSize = (requestUrl, pageSize) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_FILTER_PAGESIZE,
        pageSize: pageSize,
        data: res.data
    }));
}

export const filterUponKeyword = (requestUrl, query) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_FILTER_KEYWORD,
        query: query,
        data: res.data
    }));
}

export const sortItems = (requestUrl, sort) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_SORT_ITEMS,
        sort: sort,
        data: res.data
    }));
}

export const deleteItem = (requestUrl) => dispatch => {
    return Axios.delete(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_DELETE_ITEM,
        needComponentUpdate:true
    }));
}

export const createItem = (requestUrl, data) => dispatch => {
    return Axios.post(API_BASE_URL + requestUrl, data, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_CREATE_ITEM,
        needComponentUpdate:true
    }));
}

export const filterUponDates = (requestUrl, start, end) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_FILTER_DATE,
        start: start,
        end: end,
        data: res.data
    }));
}

export const filterUponAmounts = (requestUrl, amountMin, amountMax) => dispatch => {
    return Axios.get(API_BASE_URL + requestUrl, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_FILTER_AMOUNT,
        amountMin: amountMin,
        amountMax: amountMax,
        data: res.data
    }));
}

export const updateItem = (requestUrl, data) => dispatch => {
    return Axios.put(API_BASE_URL + requestUrl, data, AuthService.getAuthHeader())
    .then(res => dispatch({
        type: CRUD_TRANSFERS_UPDATE_ITEM,
        needComponentUpdate:true
    }));
}

export const setNeedComponentUpdate = (status) => dispatch => {
    dispatch({
        type: CRUD_TRANSFERS_NEED_COMPONENT_SET,
        needComponentUpdate:status
    });
}