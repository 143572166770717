import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import {formatAmount, formatDate} from '../../../helper';
import Tbl from './tbl';
import {connect} from 'react-redux';
import {t} from '../../../components/Translation';
import { loadGlobals } from '../../../store/DashboardActions';

class TransactionsList extends Component {
	state = {

		alert: null,
		baseUrl: '/transactions',
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },
			{
			  label: t('Id'),
			  field: 'id',
			  sort: false
		    },
		    {
		      label: t('Account '),
		      field: 'account ',
		      sort: true,
			  sortCol: 'accountName'
		    },
		    {
		      label: t('Currency '),
		      field: 'currency ',
		      sort: true,
			  sortCol: 'currencyName'
		    },
		    {
		      label: t('Amount'),
		      field: 'amount',
		      sort: true,
			  sortCol: 'amount'
		    },
			{
				label: t('Date'),
				field: 'date',
				sort: true,
				sortCol: 'date'
			},
		    {
		      label: t('Category'),
		      field: 'category',
		      sort: true,
			  sortCol: 'categoryName'
		    },
		    {
		      label: t('Payment method'),
		      field: 'paymentMethod',
		      sort: true,
			  sortCol: 'methodName'
		    },
		    {
		      label: t('Reference'),
		      field: 'reference',
		      sort: false
		    },
		    {
		      label: t('Created'),
		      field: 'created',
		      sort: true,
			  sortCol: 'created'
		    },
	  	],
		statuses: []
	}

	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id': value.id,
				'account': value.account.label,
				'currency': (typeof value.currency !== 'undefined' && value.currency !== null) ? value.currency.label : '',
				'amount': formatAmount(value.amount, this.props.globals.currency),
				'date': formatDate(value.date, this.props.globals.dateFormat),
				'category': (value.category !== null) ? value.category.label : "",
				'paymentMethod': value.paymentMethod.label,
				'reference': value.reference,
				'created': formatDate(value.created, this.props.globals.dateFormat)
			};
			items.push( item );
		}
		return items;
	}

	render() {
		let rows = this.mapAttributes(this.props.rows);
		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
										rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.transactions.rows,
	globals: state.dash.globals,
	pagination: state.transactions.pagination,
	pageSize: state.transactions.pageSize,
})

export default connect( mapStateToProps, {loadGlobals} ) ( TransactionsList );