import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Action from '../../../components/UI/DataTable/Action';
import '../../../components/Text/String';
import Status from '../../../components/UI/DataTable/Status';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/CategoryActions';
import {t} from '../../../components/Translation';

class CategoryList extends Component {

	state = {	
		alert: null,	
		baseUrl: '/categories',
		addBtn: {
			label: t('Create new category'),
			url: this.props.formPath + "/create"
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },
			{
			  label: t('Id'),
			  field: 'id',
			  sort: false
		    },		    
		    {
		      label: t('Reference'),
		      field: 'reference',
		      sort: true
		    },
			{
				label: t('Name'),
				field: 'name',
				sort: true
			},
		    {
		      label: t('Type'),
		      field: 'type',
		      sort: true
		    },
		    {
		      label: t('Color'),
		      field: 'color',
		      sort: false
		    },		   
		    {
		      label: t('Status'),
		      field: 'status',
		      sort: true
		    },		  
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: [
			{
				label: t("All"),
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: t("Enabled"),
				value: "enabled",
				class: "btn-outline-success"
			},
			{
				label: t("Disabled"),
				value: "disabled",
				class: "btn-outline-warning"
			}
		]
	}

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}
	
	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			const color = <div style={{width:'30px',height:'30px', backgroundColor:value.color}} ></div>;
			var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id': value.id,		
				'reference': value.reference,		
				'name': value.name,
				'type': value.type.Capitalize(),
				'color': color,				
				'status': <Status type="category" value={value.status} />,
				'actions': <Action state={value} path={this.props.formPath} onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}

	render() {

		let rows = this.mapAttributes(this.props.rows);

		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">

								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
										rows={rows}
                                    />						
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.category.rows,
	pagination: state.category.pagination,
	pageSize: state.category.pageSize,
})

export default connect( mapStateToProps, {deleteItem} ) ( CategoryList );