import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import DateTime from '../../../components/Timestamp/DateTime';
import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';
import ImageString from '../../../components/UI/DataTable/ImageString';
import SweetAlert  from 'react-bootstrap-sweetalert';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/UserActions';
import { t } from '../../../components/Translation';
import { loadGlobals } from '../../../store/DashboardActions';
import { formatDate } from '../../../helper';

class UserList extends Component {
	state = {	
		alert: null,	
		baseUrl: '/users',
		addBtn: {
			label: t('Create new user'),
			url: this.props.formPath + "/create"
		},
		columns: [
		    {
				label: '#',
				field: 'serial',
				sort: false
		    },
			{
				label: 'id',
				field:'id',
				sort:false,
			},
		    {
				label: t('Username'),
				field: 'username',
				sort: true
		    },
		    {
				label: t('Email'),
				field: 'email',
				sort: true
		    },
		   
			{
				label: t('Role'),
				field: 'role',
				sort: false
			},
		    {
				label: t('Status'),
				field: 'status',
				sort: true
		    },
		    {
				label: t('Created'),
				field: 'created',
				sort: true
		    },
		    {
				label: t('Updated'),
				field: 'updated',
				sort: true
		    },
		    {
				label: t('Actions'),
				field: 'actions',
				sort: false
		    }
	  	],
		statuses: [
			{
				label: t("All"),
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: t("Pending"),
				value: 0,
				class: "btn-outline-warning"
			},
			{
				label: t("Active"),
				value: 1,
				class: "btn-outline-success"
			},
			{
				label: t("Inactive"),
				value: 2,
				class: "btn-outline-dark"
			},
			{
				label: t("Deleted"),
				value: 3,
				class: "btn-outline-danger"
			}
		],
	}

	componentDidMount() {
		this.props.loadGlobals();
	}

	deleteItem = (uid) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + uid);
	}
	
	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id':'',
				'username': <ImageString image={value.photo} string={value.fullname} />,
				'email': value.email,
				'role': value.role,
				'status': <Status type="user" value={value.status} />,
				'created': formatDate(value.created, this.props.globals.dateFormat),
				'updated': formatDate(value.updated, this.props.globals.dateFormat),
				'actions': <Action type="user" state={value} path={this.props.formPath} onDelete={this.deleteItem}
								   onGeneratePassword={this.generateNewPassword}
							/>
			};
			items.push( item );
		}
		return items;
	}

	hideAlert() {
		this.setState({   alert: null  });
	}

	generateNewPassword = (uid) => {
		Axios.put(API_BASE_URL + '/users/'+ uid + '/generate-password', {}, AuthService.getAuthHeader()).then(res => {
			const alertBox = () => (
				<SweetAlert
					success
					confirmBtnBsStyle="success"
					title={t("Success!")}
					onConfirm={() => {
						this.hideAlert();
					}}>
				</SweetAlert>
			);
			this.setState({   alert: alertBox()  });
		})
	}

	render() {

		let rows = this.mapAttributes(this.props.rows);

		return (
			<AUX>
				{this.state.alert}
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">								
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
                                        rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.user.rows,
	globals: state.dash.globals,
	pagination: state.user.pagination,
	pageSize: state.user.pageSize,
})

export default connect( mapStateToProps, {deleteItem, loadGlobals} ) ( UserList );