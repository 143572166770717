import React  from 'react';
import '../../../components/Text/String';
import { t } from '../../../components/Translation';

export default class PermissionCheck extends React.Component {
	
	state = {
		"description": "string",
		"method": "string",
		"name": "string",
		"route": "string",
		"status": false,
		"uid": "string"
	}

	componentDidMount() {
		this.setState({
			...this.props.item,
			status: (this.props.item.status === 1)
		});
	}
	
	onChange = (id) => {
		this.setState({
			status: !this.state.status
		});
		this.props.onChange(id);
	}
	
    render() {
		// const permission = this.props.permission;
		// const fieldName= "permission_" + permission.id;
        return (
            <React.Fragment>
				<div className="form-group row">
					<label className="col-8 col-form-label">{this.state.description.Capitalize()}</label>
					<div className="col-4">												
						<input id={this.state.uid} type="checkbox" checked={this.props.item.status ? "checked"  :  "" } switch="none" onChange={this.onChange.bind(this, this.state.uid)} />
						<label htmlFor={this.state.uid} data-on-label={t("Yes")}  data-off-label={t("No")}></label>
					</div>
				</div>																					
			</React.Fragment>
        );
    }
}