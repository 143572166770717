
import React , {Component } from 'react';
import AUX from '../../hoc/Aux_';
import { connect } from 'react-redux';
import {BarChart,Bar } from 'recharts';
import { loadTinyCharts } from '../../store/TinyChartActions';
import { loadGlobals } from '../../store/DashboardActions';
import { formatAmount } from '../../helper';

class TinyCharts extends Component {
    
    state = {
    }

    componentDidMount() {
        this.props.loadGlobals();
        this.props.loadTinyCharts(this.props.globals.financial_year_start, this.props.globals.financial_year_end);
    }

    render() {
        const revenueData = this.props.revenueData;
        const profitData = this.props.profitData;
        const totalRevenue = this.props.totalRevenue;
        const totalProfit = this.props.totalProfit;

        return (
            <AUX>
                <div className="state-information d-none d-sm-block">

                    <div className="state-graph">
                        <div id="">
                            <BarChart side='0' width={110} height={40} data={profitData}>
                                <Bar dataKey='y' fill='#8884d8'/>
                            </BarChart>
                        </div>
                        <div className="info">Profit {(formatAmount((totalProfit < 0 ? 0 : totalProfit), this.props.globals.currency))}</div>
                    </div>

                    <div className="state-graph">
                        <div id="">
                            <BarChart side='0' width={110} height={40} data={revenueData}>
                                <Bar dataKey='y' fill='#17a2b8'/>
                            </BarChart>
                        </div>
                        <div className="info">Revenue {(formatAmount(totalRevenue, this.props.globals.currency))}</div>
                    </div>

                </div>
            </AUX>
        );
    }
}

const mapStateToProps = state => ({
    revenueData :state.tinyCharts.revenueData,
    profitData: state.tinyCharts.profitData,
    totalRevenue: state.tinyCharts.totalRevenue,
    totalProfit: state.tinyCharts.totalProfit,
    globals: state.dash.globals
})
 
export default connect(mapStateToProps, {loadGlobals, loadTinyCharts})(TinyCharts);