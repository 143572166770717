import React from 'react';
import ReactTimeAgo from 'react-time-ago'
import JavascriptTimeAgo from 'javascript-time-ago'
 
// The desired locales.
import en from 'javascript-time-ago/locale/en'
import fr from 'javascript-time-ago/locale/fr'
 
// Initialize the desired locales.
JavascriptTimeAgo.locale(en)
JavascriptTimeAgo.locale(fr)
 
export default class TimeAgo extends React.Component {
    render() {
        const unixTimestamp = this.props.timestamp * 1000;
        return (
            <ReactTimeAgo date={unixTimestamp} locale="fr" />
        );
    }
}