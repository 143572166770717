import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {loadGlobals, getUnpaidInvoices} from '../../../store/DashboardActions';
import {t} from '../../../components/Translation';
import { formatAmount } from '../../../helper';
import ReactPaginate from 'react-paginate';

class UnpaidInvoices extends Component {
  
    state = {
        
    }

    componentDidMount() {
        this.props.loadGlobals();
        this.props.getUnpaidInvoices(this.props.globals.financial_year_start, this.props.globals.financial_year_end, this.props.unpaidInvoices.pagination.page, this.props.unpaidInvoices.pageSize);
    }

    onChangePage = (event) => {
        this.props.getUnpaidInvoices(this.props.globals.financial_year_start, this.props.globals.financial_year_end, event.selected, this.props.unpaidInvoices.pageSize);
    }

    render() {
        return(
            <AUX>
                <div className="card m-b-20">
                    <div className="card-body">
                        <h4 className="mt-0 m-b-30 header-title">{t("Unpaid Invoices (+{0} days)", [30])}</h4>
                        <div className="table-responsive" style={{minHeight: 372}}>
                            <table className="table table-vertical mb-1">
                                <thead>
                                    <tr>
                                        <th>{t("Age")}</th>
                                        <th>{t("Invoice")}</th>
                                        <th>{t("Amount")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.unpaidInvoices.rows.map((val,index) => {
                                        return <tr key={index}>
                                            <td><i className={"mdi mdi-checkbox-blank-circle text-"+(val.days > 30 ? "danger":"warning")}></i>{val.days} {t("days")}</td>
                                            <td>{val.invoiceNumber}</td>
                                            <td>{(formatAmount(val.amount, this.props.globals.currency))}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="dataTables_paginate">
                            <ReactPaginate
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                nextLabel={t("Next")}
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                onPageChange={this.onChangePage}
                                pageRangeDisplayed={5}
                                pageCount={this.props.unpaidInvoices.pagination.pages}
                                previousLabel={t("Previous")}
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                renderOnZeroPageCount={null}
                                className="pagination float-right"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="active"
                            />
                        </div>
                    </div>                                   
                </div> 
            </AUX>
        );
    }
}

const mapStateToProps = state => ({
    unpaidInvoices: state.dash.unpaidInvoices,
    globals: state.dash.globals,
});

export default connect( mapStateToProps, {loadGlobals, getUnpaidInvoices} ) ( UnpaidInvoices );