
import React , {Component } from 'react';
import AUX from '../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/action';
import { Link } from 'react-router-dom';
import {t} from '../../components/Translation';
import {ToastsStore} from 'react-toasts';
import { handleError } from '../../helper';
import Axios from 'axios';
import {API_BASE_URL} from '../../config';
import AuthService from './AuthService';

class ForgetPassword extends Component{
    
    state = {
        email: ''
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });

    componentDidMount() 
    {
        if (this.props.loginpage === false) 
        {
            this.props.UpdateLogin();
        }
        window.onpopstate  = (e) => {
            this.props.UpdateLoginAgain();
        }
    }

    onSubmit = (e)  => {
        e.preventDefault();
        Axios.post(API_BASE_URL+'/auth/forget-password', this.state, AuthService.getBasicAuthHeader())
            .then(res => {
                this.setState({
                    email: '',
                });
                ToastsStore.success(t("Your request is submitted successfully!"), 5000);      
            })
            .catch(function (error) {
                handleError(error);
            });
    }

    render(){
        return(
            <AUX>
                <div className="wrapper-page">

                    <div className="card">
                        <div className="card-body">

                            <h3 className="text-center m-0">
                                <Link onClick={()=> this.props.UpdateLoginAgain()} to="/" className="logo logo-admin"><img src="assets/images/logo.png" height="30" alt="logo" /></Link>
                            </h3>

                            <div className="p-3">
                                <h4 className="text-muted font-18 mb-3 text-center">{t("Forget Password")}</h4>
                                <div className="alert alert-info" role="alert">
                                    {t("Enter your Email and instructions will be sent to you!")}
                                </div>

                                <form className="form-horizontal m-t-30" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label for="email">{t("Email")}</label>
                                        <input name="email" type="email" className="form-control" onChange={this.onChange} value={this.state.email} id="email" placeholder={t("Enter email")} />
                                    </div>

                                    <div className="form-group row m-t-20">
                                        <div className="col-12 text-right">
                                            <button className="btn btn-primary w-md waves-effect waves-light" type="submit">{t("Reset")}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                <div className="m-t-40 text-center">
                    <p>{t("Remember It ?")} <Link to ='/login' className="text-primary"> {t("Sign In Here")} </Link> </p>
                    <p>© {new Date().getFullYear()} Smart Office</p>
                </div>

            </div>   
            </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
        UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false }),
      
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ForgetPassword);