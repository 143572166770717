import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import ImageString from '../../../components/UI/DataTable/ImageString';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateFromHTML} from 'draft-js-import-html';
import { t } from '../../../components/Translation';
import SweetAlert  from 'react-bootstrap-sweetalert';

import { handleError } from '../../../helper';

class TemplateForm extends Component {
	state = {
		id: null,
		name: '',
		description: '',
		tokens: '',
		translations: [],
		language: '',
		languages: [],
		subject: '',
		content: "",
        editorState: EditorState.createEmpty(),

		alert: null,
	}

	hideAlert = () => {
		this.setState({   alert: null  });
	}
	
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {		
		var st = this.props.location.state;
		if(st) {		
			this.setState({language: st.language});	
			this.setState({languages: st.languages});
			Axios.get(API_BASE_URL + '/emailtemplates/' + st.id, AuthService.getAuthHeader())
			.then(res => {
				this.setState(res.data);
				var tr = res.data.translations.filter(tra => tra.language.id === st.language.id)[0];
				if (tr !== undefined) {
					this.setState({
						subject: tr.subject,
						content: tr.content,
						editorState: EditorState.createWithContent(stateFromHTML(tr.content))
					});
				}
			})						
		}									
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onChangeSubject = (e) => {
		this.setState( {[e.target.name]: e.target.value });
		var translations = this.state.translations;
		var field = translations.filter(trans => trans.language.id === this.state.language.id)[0];
		if (field === undefined) {
			translations.push({
				idtemplate: this.state.id,
				subject: e.target.value,
				content: this.state.content,
				language: this.state.language
			});
		} else {
			translations.filter(trans => trans.language.id === this.state.language.id)[0]['subject'] = e.target.value;
		}
		this.setState({translations: translations});
	}	
	
	onEditorStateChange = (editorState) => {
		var content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

		var translations = this.state.translations;
		var field = translations.filter(trans => trans.language.id === this.state.language.id)[0];
		if (field === undefined) {
			translations.push({
				idtemplate: this.state.id,
				subject: this.state.subject,
				content: content,
				language: this.state.language
			});
		} else {
			translations.filter(trans => trans.language.id === this.state.language.id)[0]['content'] = content;
		}
		this.setState({
			editorState,
          	content,
			translations: translations
		});
    };
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var translations = [];
			for (const [index, value] of this.state.translations.entries()) {
				translations.push({
					idlanguage: value.language.id,
					subject: value.subject,
					content: value.content
				});				
			}

			// Request data
			var emailtemplate = {
				name: this.state.name,
				description: this.state.description,
				tokens: this.state.tokens,
				translations: translations
			}

			// Send request
			var requestUrl = API_BASE_URL + '/emailtemplates';
			var requestMethod = 'post';
			if (this.state.id !== null) {
				requestUrl = API_BASE_URL + '/emailtemplates/' + this.state.id;
				requestMethod = 'put';
			}

			
			const history = this.props.history;
			const listPath = this.props.listPath;
			Axios.request({
				url: requestUrl, 
				method: requestMethod,
				data: emailtemplate,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + AuthService.getToken()
				}
			})
			.then(res => {
				const alertBox = () => ( 
					<SweetAlert
						success				
						confirmBtnBsStyle="success"
						title={t("Success!")}
						onConfirm={() => {					
							this.hideAlert();
							history.push(listPath);
						}}>
					</SweetAlert> 
				);
				this.setState({   alert: alertBox()  });	
			})
			.catch(error => {
				var message = handleError(error);
				const alertBox = () => ( 
					<SweetAlert
						danger				
						confirmBtnBsStyle="danger"
						title={t("Error!")}
						onConfirm={() => {					
							this.hideAlert();
						}}>
							{message}
					</SweetAlert> 
				);
				this.setState({   alert: alertBox()  });
			})
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header"><ImageString image={this.state.language.icon} string={this.state.language.name} /></div>
								<div className="card-body">			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder="Template Name" readOnly="readonly" />
												{this.validator.message('name', this.state.name, 'required|string')}
											</div>
										</div>					
															
										<div className="form-group row">
											<label htmlFor="description" className="col-sm-3 col-form-label">{t("Description")}</label>
											<div className="col-sm-9">
												<input value={this.state.description} onChange={this.onChange} id="description" type="text" name="description" className="form-control" placeholder={t("Template description")} readOnly="readonly" />
												{this.validator.message('description', this.state.description, 'required|string')}
											</div>
										</div>						
															
										<div className="form-group row">
											<label htmlFor="tokens" className="col-sm-3 col-form-label">{t("Tokens")}</label>
											<div className="col-sm-9">
												<input value={this.state.tokens} onChange={this.onChange} id="tokens" type="text" name="tokens" className="form-control" placeholder={t("Template tokens")} readOnly="readonly" />
												{this.validator.message('tokens', this.state.tokens, 'required|string')}
											</div>
										</div>						
													
										<div className="form-group row">
											<label htmlFor="subject" className="col-sm-3 col-form-label">{t("Subject")}</label>
											<div className="col-sm-9">
												<input value={this.state.subject} onChange={this.onChangeSubject} id="subject" type="text" name="subject" className="form-control" placeholder={t("Subject")} />
												{this.validator.message('subject', this.state.subject, 'required|string')}
											</div>
										</div>																						
									
										<div className="form-group row">
											<label htmlFor="content" className="col-sm-3 col-form-label">{t("Content")}</label>
											<div className="col-sm-9">
												<Editor
													name="content"
													editorState={this.state.editorState}
													onEditorStateChange={this.onEditorStateChange}
													toolbarClassName="toolbarClassName"
													wrapperClassName="wrapperClassName"
													editorClassName="editorClassName" 
												/> 
												{this.validator.message('content', this.state.content, 'required')}
											</div>
										</div>			

										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

export default TemplateForm;