import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL } from '../../../config';
import AuthService from '../../Auth/AuthService';
import { MDBDataTable } from 'mdbreact';
import Action from '../../../components/UI/DataTable/Action';
import {connect} from 'react-redux';
import { t } from '../../../components/Translation';
import { loadGlobals } from '../../../store/DashboardActions';

class TemplateList extends Component {

	state = {	
		alert: null,	
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: 'asc'
		    },		    
		    {
		      label: t('Id'),
		      field: 'id',
		      sort: 'asc'
		    },		    
		    {
		      label: t('Name'),
		      field: 'name',
		      sort: 'asc'
		    },
		    {
		      label: t('Description'),
		      field: 'description',
		      sort: 'asc'
		    },		
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: 'asc'
		    }
	  	],
		rows: [],
		languages: []
	}

	componentDidMount() {
		Axios.get(API_BASE_URL + '/list/languages', AuthService.getAuthHeader())
		.then(res => {
			this.setState({languages: res.data});		
			Axios.get(API_BASE_URL + '/list/emailtemplates', AuthService.getAuthHeader())
			.then(res => {
				var items = [];
				for (const [index, value] of res.data.entries()) {
				  	var item = {
						'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
						'id': value.id,				
						'name': value.name,
						'description': value.description,
						'actions': <Action id={value.id} type="emailtemplate" languages={this.state.languages} path={this.props.formPath} onDelete={this.deleteItem} />
					};
				  	items.push( item );
				}
				this.setState({rows: items});			
			});			
		});
	}
	
	deleteItem = (id) => {		
		Axios.delete(API_BASE_URL + '/emailtemplate/'+ id, AuthService.getAuthHeader()).then(res => {
			this.setState({ rows: [...this.state.rows.filter(row => row.id !== id)]});
		})
	}

	render() {

		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								
								<div className="card-body">
									
									<div className="button-items">
										
									</div>
								
									<div className="m-t-30">
										<MDBDataTable
	                                        bordered
											striped
											responsive
	                                        hover
	                                        data={this.state}
	                                    />
									</div>									
									
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
	pagination: state.transactions.pagination,
	pageSize: state.transactions.pageSize,
})

export default connect( mapStateToProps, {loadGlobals} ) ( TemplateList );