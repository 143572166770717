import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import SweetAlert  from 'react-bootstrap-sweetalert';
import { t } from '../../../components/Translation';
import Select from 'react-select';

class Profile extends Component {

	state = {
	  	username: '',
	    email: '',
  	    password: '',
	    firstname: '',
	    lastname: '',
		language: {},
		language_options: [],
		photo: null,
		alert: null
	}
	
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		this.setState(AuthService.getUserInfo());

		Axios.get(API_BASE_URL + '/list/language-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({language_options: res.data});
		});
	}
	
	hideAlert() {
		this.setState({   alert: null  });
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });

	onLanguageChange = (e) => { this.setState({ language: e });  }

	
	onChangeFile = (e) => {		
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/image', data, AuthService.getAuthHeader())
		    	.then(res => {			
					this.setState({photo: res.data.fileName,
					});
				});
		} else {
			
		}
	}
	
			
	onSubmit = (e) => {
		e.preventDefault();		
		const history = this.props.history;
		if (this.validator.allValid()) {
			var profile = this.state;		
			var requestUrl = API_BASE_URL + '/me';
			var requestMethod = 'put';
			Axios.request({
				url: requestUrl, 
				method: requestMethod,
				data: profile,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + AuthService.getToken()
				}
			})
			.then(res => {
				const alertBox = () => ( 
		            <SweetAlert
						success				
						confirmBtnBsStyle="success"
						title={t("Success!")}
						onConfirm={() => {					
							this.hideAlert();
							AuthService.loadUserInfo();
							// history.push("/");
						}}>
					</SweetAlert> 
				);
		        this.setState({   alert: alertBox()  });								
			});
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				{this.state.alert}
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{t("My Profile")}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										
										<div className="form-group row">
											<label htmlFor="username" className="col-sm-3 col-form-label">{t("Username")}</label>
											<div className="col-sm-9">
												<input readOnly={true} value={this.state.username} onChange={this.onChange} id="username" type="text" name="username" className="form-control" placeholder={t("Username")}></input>
												{this.validator.message('username', this.state.username, 'required|string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="email" className="col-sm-3 col-form-label">{t("Email")}</label>
											<div className="col-sm-9">
												<input readOnly={true} value={this.state.email} onChange={this.onChange} id="email" type="text" name="email" className="form-control" placeholder={t("Email")}></input>
												{this.validator.message('email', this.state.email, 'required|string')}
											</div>
										</div>	
										
										<div className="form-group row">
											<label htmlFor="photo" className="col-sm-3 col-form-label">{t("Photo")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="photo"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="photo"											
														aria-describedby="photo" />
														<label className="custom-file-label" htmlFor="photo">
															{t("Choose file")}
														</label>
													</div>
			                                    </div>
												{(this.state.photo !== null) && <img className='image-width' src={API_IMAGES_URL + this.state.photo} /> }
												<div >{t("Allowed file types are: {0}.", [IMAGE_TYPES.join(', ')])}</div>
											</div>
										</div>

																											
										<div className="form-group row">
											<label htmlFor="password" className="col-sm-3 col-form-label">{t("Password")}</label>
											<div className="col-sm-9">
												<input value={this.state.password} onChange={this.onChange} id="password" type="password" name="password" className="form-control" placeholder={t("Password")}></input>
												<div >{t("Enter new password only if you want to change")}</div>
												{this.validator.message('password', this.state.password, 'string')}
											</div>
										</div>																		
										<div className="form-group row">
											<label htmlFor="firstname" className="col-sm-3 col-form-label">{t("First name")}</label>
											<div className="col-sm-9">
												<input value={this.state.firstname} onChange={this.onChange} id="firstname" type="text" name="firstname" className="form-control" placeholder={t("First name")}></input>
												{this.validator.message('firstname', this.state.firstname, 'required|string')}
											</div>
										</div>
																												
										<div className="form-group row">
											<label htmlFor="lastname" className="col-sm-3 col-form-label">{t("Last name")}</label>
											<div className="col-sm-9">
												<input value={this.state.lastname} onChange={this.onChange} id="lastname" type="text" name="lastname" className="form-control" placeholder={t("Last name")}></input>
												{this.validator.message('lastname', this.state.lastname, 'required|string')}
											</div>
										</div>	

										<div className="form-group row">
											<label htmlFor="language" className="col-sm-3 col-form-label">{t("Language")}</label>
											<div className="col-sm-9">
												<Select 
													name="language"
													value={this.state.language_options.filter(option => option.value === this.state.language.value)}
													id="language"
													onChange={this.onLanguageChange}
													options = {this.state.language_options} 
												/>
												{this.validator.message('language', this.state.language, 'required')}
											</div>
										</div>																	

										{/* <div className="form-group row">
											<label htmlFor="language" className="col-sm-3 col-form-label">{t("Language")}</label>
											<div className="col-sm-9">
												<input value={this.state.language} onChange={this.onChange} id="language" type="text" name="language" className="form-control" placeholder={t("Language")}></input>
												{this.validator.message('language', this.state.language, 'required|string')}
											</div>
										</div>																		 */}
																
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

export default Profile;