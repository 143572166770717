
export const API_BASE_URL = 'https://api.offneo.com/smartoffice-accounting';
export const API_IMAGES_URL = 'https://smartoffice.offneo.com/images/';
export const FILES_URL = 'https://smartoffice.offneo.com/files/';
export const TOKEN = "token";
export const IDENTITY = "userIdentity";
export const IMAGE_TYPES = ['png', 'jpg', 'jpeg'];
export const FILE_TYPES = ['doc', 'txt', 'pdf', 'jpg'];
export const DATETIME_FORMAT = "D MMM YYYY";
export const DATE_FORMAT = "D MMM YYYY";
export const IMAGES_URL = process.env.PUBLIC_URL + '/assets/images/';
export const LANG = 'fr';
export const BASIC_AUTH_TOKEN = 'dXNlcm5hbWU6cGFzc3dvcmQ='