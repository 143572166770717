import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import {FILES_URL} from '../../../config';
import Action from '../../../components/UI/DataTable/Action';
import '../../../components/Text/String';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/ExpenseActions';
import { t } from '../../../components/Translation';
import { loadGlobals } from '../../../store/DashboardActions';
import { formatDate, formatAmount } from '../../../helper';

class ExpenseList extends Component {
	state = {
		alert: null,		
		baseUrl: '/expenses',
		addBtn: {
			label: t('Create new expense'),
			url: this.props.formPath + "/create"
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },
		    {
		      label: t('Id'),
		      field: 'id',
		      sort: false
		    },
		    {
		      label: t('Amount'),
		      field: 'amount',
		      sort: true,
			  sortCol: 'amount',
		    },
		    {
		      label: t('Account'),
		      field: 'account',
		      sort: true,
			  sortCol: 'accountName',
		    },
			{
				label: t('Currency'),
				field: 'currency',
				sort: true,
				sortCol: 'currencyName',
			},
		    {
		      label: t('Reference'),
		      field: 'reference',
		      sort: false
		    },
			{
				label: t('Category'),
				field: 'category',
				sort: true,
				sortCol: 'categoryName',
			  },
			
			{
				label: t('Payment method'),
				field: 'paymentMethod',
				sort: true,
				sortCol: 'paymentmethodName',
			},
			{
				label: t('Notes'),
				field: 'notes',
				sort: false
			},
			{
				label: t('Date'),
				field: 'date',
				sort: true,
				sortCol: 'date',
			},
		    {
		      label: t('Attachment'),
		      field: 'attachment',
		      sort: false
		    },
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: []
	}

	componentDidMount() {
		this.props.loadGlobals();
	}

	deleteItem = (id) => {
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}
	
	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			  var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id': value.id,
				'amount': formatAmount(value.amount, this.props.globals.currency),
				'account': value.account.label.Capitalize(),
				'currency': value.currency.label,
				'notes': value.notes,
				'category': value.category.label.Capitalize(),
				'paymentMethod': value.paymentMethod.label.Capitalize(),
				'reference': value.reference,
				'date': formatDate(value.date, this.props.globals.dateFormat), 
				'attachment': <a href={FILES_URL + value.attachment} className="btn btn-outline-secondary waves-effect waves-light">{t("Download")}</a>,
				'actions': <Action state={value} path={this.props.formPath} onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}
	
	render() {
		let rows = this.mapAttributes(this.props.rows);
		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
                                        rows={rows}
                                    />																			
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.expense.rows,
	globals: state.dash.globals,
	pagination: state.expense.pagination,
	pageSize: state.expense.pageSize,
})

export default connect( mapStateToProps, {deleteItem, loadGlobals} ) ( ExpenseList );