import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/TimesheetActions';
import {loadCustomers} from '../../../store/CustomersActions';
import {loadAgreements, loadAgreementsServices} from '../../../store/AgreementActions';
import {t} from '../../../components/Translation';

import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import "react-datepicker/dist/react-datepicker.css";

import { handleSuccess, handleError, getDaysArray, findTimesheetEntryIndex } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class TimesheetForm extends Component {

	state = {
		id: null,
		number: '',
		customer: null,
		month: new Date(),
		days: 0,
		status: 0,
		notes: '',
		attachment: null,
		entries: [],
		status_options: [
			{label: t('Draft'), value: 0},
			{label: t('Sent'), value: 1},
			{label: t('Approved'), value: 2},
		],
		daysInMonth: getDaysArray(new Date()),
		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		this.props.loadCustomers();
		
		let dpath = "/timesheet/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
			Axios.get(API_BASE_URL + '/timesheets/'+id, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					id: (this.props.path === dpath ? null: st.id),
					number: st.number,
					customer: st.customer,
					month: new Date(st.month * 1000),
					days: st.days,
					status: st.status,
					notes: st.notes,
					attachment: st.attachment,
					daysInMonth: getDaysArray(new Date(st.month * 1000)),
					entries: st.entries,
				});
				this.props.loadAgreements(st.customer.value);
				this.props.loadAgreementsServices(st.customer.value);
				this.calculate();
			});

			if (this.props.path === dpath) {
				this.generateTimesheetNumber();
			}
		} else {
            this.generateTimesheetNumber();
        }
	}

	generateTimesheetNumber = () => {
		Axios.get(API_BASE_URL + '/settings/timesheet', AuthService.getAuthHeader())
            .then(res => {
                let setting = res.data;
                this.setState({
                    ...this.state,
                    number: setting.number_prefix + setting.next_number.padStart(setting.number_digit, '0')
                });
            });
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });

	onChangeEntry = (e, agreement, service) => {
		var dayVal = 0;

		if ( parseFloat(e.target.value) <= 1 ) {
			dayVal = e.target.value;
		} else {
			dayVal = 1;
		}

		const entries = this.state.entries;
		var field = e.target.name;
		var fieldParts = field.split('_');
		var index = findTimesheetEntryIndex(entries, parseInt(fieldParts[1]), parseInt(fieldParts[2]), parseInt(fieldParts[3]));
		if (index !== null) {
			entries[index].day = dayVal;
		} else {
			entries.push({
				id: null,
				agreement,
				service,
				dayOfMonth: parseInt(fieldParts[3]),
				day: dayVal
			});
		}

		this.setState({
			...this.state,
			entries
		});

		this.calculate();
	}

	calculate = () => {
		const entries = this.state.entries;
		const daysInMonth = this.state.daysInMonth;
		var totalDays = 0;

		for ( let d = 0; d < daysInMonth.length; d ++) {
			var colTotal = 0;
			for (let i = 0; i < entries.length; i++) {
				if ( entries[i].dayOfMonth === daysInMonth[d].dayNo ) {
					colTotal = colTotal + parseFloat(entries[i].day);
				}
			}
			daysInMonth[d].total = colTotal;
			totalDays = totalDays + colTotal;
		}
		this.setState({
			...this.state,
			daysInMonth,
			days: totalDays
		});
	}
	
	onCustomerChange = (e) => {
		
		this.props.loadAgreements(e.value);
		this.props.loadAgreementsServices(e.value);
		
		// this.calculate();
		this.setState({customer: e});
	}
			
	onMonthChange = (date) => {
		const daysInMonth = getDaysArray(date);
		this.setState({  
			month: date,
			daysInMonth
		});  
	}

	onSelectChange = (field, e) => {
		this.setState({[field]: e.value});
	}

	calculateRowTotal = (agreement, service) => {
		const entries = this.state.entries;
		var rowTotal = 0;

		for ( let i = 0; i < entries.length; i ++) {
			var entry = entries[i];
			if ( entry.agreement.value === agreement && entry.service.value === service ) {
				rowTotal = rowTotal + parseFloat(entry.day);
			}
		}
		return rowTotal;
	}

	onChangeFile = (e) => {		
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/image', data, AuthService.getAuthHeader())
		    	.then(res => {			
					this.setState({
						attachment: res.data.fileName
					});
				});
		} else {
			
		}
	}

	showEmptyEntriesError = () => {
		const alertBox = () => (
			<SweetAlert
				danger
				title="Error"
				onConfirm={() => {
					this.hideAlert();
				}}>{t('Timesheet entries are required!')}
			</SweetAlert>
		);
		this.setState({   alert: alertBox()  });
	}

	onSubmit = (e) => {
		e.preventDefault();	
		if (this.state.entries.length < 1) {
			this.showEmptyEntriesError();
			return false;
		}
			
		var objectConstructor = ({}).constructor;
		if (this.validator.allValid()) {
			var timesheet = {
				...this.state,
				customer: this.state.customer.value,
				month: Math.floor(this.state.month.getTime() / 1000),
				attachment: (this.state.attachment !== null) ? this.state.attachment : null
			}
			
			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.id !== null) {
				promise = this.props.updateItem('/timesheets/'+this.state.id, timesheet);				
			} else {
				promise = this.props.createItem('/timesheets', timesheet);
			}
			
			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});
				
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit'):t('Add')} {t("Timesheet")}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										
										<div className="form-group row">
											<label htmlFor="number" className="col-sm-3 col-form-label">{t("Number")}</label>
											<div className="col-sm-9">
												<input value={this.state.number} onChange={this.onChange} id="number" type="text" name="number" className="form-control" placeholder={t("Number")}></input>
												{this.validator.message('number', this.state.number, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="customer" className="col-sm-3 col-form-label">{t("Customer")}</label>
											<div className="col-sm-9">
												<Select 
													name="customer"
													value={this.state.customer}
													id="customer"
													onChange={this.onCustomerChange}
													options = {this.props.customers}
												/>
												{this.validator.message('customer', this.state.customer, 'required')}
											</div>
										</div>
										
										
										<div className="form-group row">
											<label htmlFor="month" className="col-sm-3 col-form-label">{t("Month")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="input-group-prepend" >
														<span className="input-group-text"><i className="ti-calendar"></i></span>
													</div>
													<DatePicker
														name="month"
														locale="fr"
														className="form-control"
														dateFormat="yyyy-MM"
														showMonthYearPicker={true}
														placeholderText='2022-02'
														selected={this.state.month}
														onChange={(date) => this.onMonthChange(date)}
													/>
                                            	</div>
											</div>
											{this.validator.message('month', this.state.month, 'required')}
										</div>

										<div className="form-group row">
											<label htmlFor="number" className="col-sm-12 col-form-label">{t("Timsheet")}</label>
											<div className="col-sm-12 table-responsive">
												<table className='table table-bordered table-hovered'>
													<tbody>
														<tr className='month-name'>
															<td colSpan={2}>&nbsp;</td>
															{this.state.daysInMonth.map((entry, idx) => {
																return <td key={idx}>{entry.month}<br />{entry.dayNo}</td>
															})}
															<td>&nbsp;</td>
														</tr>
														<tr className='day-name'>
															<td>{t('Agreement')}</td>
															<td>{t('Service')}</td>
															{this.state.daysInMonth.map((entry, idx) => {
																return <td key={idx}>{entry.day}</td>
															})}
															<td>{t('Total days')}</td>
														</tr>

														{this.props.agreements.map((agreement, index) => {
															return <React.Fragment key={index}>
																{this.props.agreementsServices.filter(s => s.agreement === agreement.value).map((service, idx) => {
																	return <tr key={idx} className="entries-list">
																		<td>{agreement.label}</td>
																		<td>{service.label}</td>
																		{this.state.daysInMonth.map((dayEntry, idd) => {
																			return <td key={idd}>
																				<input 
																					name={"day_"+agreement.value+"_"+service.value+"_"+dayEntry.dayNo} 
																					type="text"
																					className="form-control"
																					readOnly={dayEntry.isOff}
																					onChange={(e) => this.onChangeEntry(e, agreement, service)}
																					value={(this.state.entries
																						.filter(e => e.agreement.value === agreement.value)
																							.filter(e => e.service.value === service.value)
																								.filter(e => e.dayOfMonth === dayEntry.dayNo)[0] !== undefined) ? 
																								this.state.entries
																									.filter(e => e.agreement.value === agreement.value)
																									.filter(e => e.service.value === service.value)
																										.filter(e => e.dayOfMonth === dayEntry.dayNo)[0].day : 0}
																				/>
																			</td>
																		})}
																		<td>{this.calculateRowTotal(agreement.value, service.value)}</td>
																	</tr>
																})}
															</React.Fragment>
														})}

														<tr>
															<td colSpan={2}>{t('Total days')}</td>
															{this.state.daysInMonth.map((entry, idx) => {
																return <td key={idx}>{entry.total}</td>
															})}
															<td>{this.state.days}</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="status" className="col-sm-3 col-form-label">{t("Status")}</label>
											<div className="col-sm-9">
												<Select 
													name="status"
													value={this.state.status_options.filter(s => s.value === this.state.status)[0]}
													id="status"
													onChange={(e) => this.onSelectChange('status', e)}
													options = {this.state.status_options}
												/>
												{this.validator.message('status', this.state.status, 'required')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="notes" className="col-sm-3 col-form-label">{t("Notes")}</label>
											<div className="col-sm-9">
												<textarea value={this.state.notes} onChange={this.onChange} id="notes" name="notes" className="form-control" placeholder={t("Notes")}></textarea>
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="attachment" className="col-sm-3 col-form-label">{t("Attachment")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="attachment"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="attachment"											
														aria-describedby="attachment" />
														<label className="custom-file-label" htmlFor="attachment">
															{t("Choose file")}
														</label>
													</div>
			                                    </div>
												{(this.state.attachment !== null) && <img className="image-width" src={API_IMAGES_URL + this.state.attachment} /> }
												<div >{t("Allowed file types are: {0}.", [IMAGE_TYPES.join(', ')])}</div>
											</div>
										</div>
										
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.timesheet.needComponentUpdate,
	customers: state.customers.options,
	agreements: state.agreement.options,
	agreementsServices: state.agreement.servicesOptions
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate, loadCustomers, loadAgreements, loadAgreementsServices}) (TimesheetForm);