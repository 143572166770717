import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import '../../../components/Text/String';

import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {setFolder, setLabel, setLabelNull} from '../../../store/MessageActions';
import { t } from '../../../components/Translation';

class Left extends Component {
    state = {
        
    }

    componentDidMount() {
        if (this.props.type === "list") {
            let box = this.props.box;
            this.props.setFolder(box, false);
            this.props.setLabelNull();
        } else if (this.props.type === "read") {
            
        }
    }

    componentDidUpdate() {
        let box = this.props.box;
        if ( (box !== this.props.currentFolder && this.props.type === "list") || (this.props.fromCompose && this.props.type === "list")) {
            this.props.setFolder(box, false); // (folderUid, fromCompose)
            this.props.setLabelNull();
        }
    }

    onLabelOpen = (labelUid) => {
        this.props.setLabel(this.props.currentFolder, labelUid, this.props.pageSize);
    }

    render() {

        const composeTo = {
            pathname: "/message/compose",
            state: {
                labels: this.props.labels,
                folders: this.props.folders,
            }
        }

        return (
            <AUX>

                <div>
                    <Link to={composeTo} className="btn btn-outline-primary btn-rounded btn-custom btn-block waves-effect waves-light">{t("Compose")}</Link>

                    <div className="mail-list m-t-20">
                        {this.props.folders.map((value, index) => {
                            return <Link key={index} to={{
                                pathname: "/message/" + value.uid,
                                state: {
                                    labels: this.props.labels,
                                    folders: this.props.folders,
                                    fromCompose: (this.props.type === "compose")
                                }
                            }} className={value.uid === this.props.currentFolder ? "active": ""} >
                                {value.name.Capitalize()} {value.messages > 0 ? <span className="ml-1">({value.messages})</span> : ""}
                            </Link>
                        })} 
                    </div>

                    {this.props.labels.length > 0 ? <h6 className="m-t-30">Labels</h6> : ""}

                    <div className="mail-list m-t-20">
                        {this.props.labels.map((value, index) => {
                            return <button key={index} className={"btn btn-default btn-block" + ((this.props.currentLabel !== null ? ( this.props.currentLabel === value.uid ? " active": "" ) : "" ))} onClick={this.onLabelOpen.bind(this, value.uid)} style={{textAlign:'left', paddingLeft:'0'}}><span className="mdi mdi-checkbox-blank-circle float-right mt-1  m-l-10" style={{color: value.color}}></span>{value.name.Capitalize()}</button>
                        })}
                    </div>
                </div>

            </AUX>
        );
    }
}

Left.propTypes = {
    setFolder: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    currentFolder: state.msg_store.currentFolder,
    currentLabel: state.msg_store.currentLabel,
    fromCompose: state.msg_store.fromCompose,
    pageSize: state.msg_store.pageSize,
    labels: state.msg_store.labels,
});

export default connect( mapStateToProps, {setFolder, setLabel, setLabelNull} ) ( Left );