import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, IMAGE_TYPES, FILE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/LanguageActions';
import { t } from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class LanguageForm extends Component {

	state = {
		id: null,
		name: '',
		code: '',
		icon: null,
		status: true,
		isdefault: true	,
		
		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/settings/language/:id/duplicate";

		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
		Axios.get(API_BASE_URL + '/languages/'+id, AuthService.getAuthHeader())
		.then(res => {			
			var st = res.data;
			this.setState({
				id: (this.props.path === dpath ? null: st.id),
				name: st.name,
				code: st.code,
				icon: st.icon,
				status: (st.status === "enabled"),
				isdefault: (st.isdefault === 1)		
			});
		});
	}
}
	changeStatus = () => { this.setState({ status: !this.state.status });  }
	changeIsdefault = () => { this.setState({ isdefault: !this.state.isdefault });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });

	onChangeFile = (e) => {
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( FILE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData()
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/file', data, AuthService.getAuthHeader())
		    	.then(res => {
					this.setState({icon: res.data});
				});
		} else {
			
		}
	}
	
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var language = {
				name: this.state.name,
				code: this.state.code,
				icon: (this.state.icon !== null) ? this.state.icon.fileName : null,
				status: this.state.status ? "enabled" : "disabled",
				isdefault: this.state.isdefault ? 1 : 0
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.id !== null) {
				promise = this.props.updateItem('/languages/'+this.state.id, language);
			} else {
				promise = this.props.createItem('/languages', language);
			}
			promise
			.then(response => {
				
				const alertBox = () => ( 
					<SweetAlert
						success				
						confirmBtnBsStyle="success"
						title={t("Success!")}
						onConfirm={() => {					
							this.hideAlert();
							history.push(listPath);
						}}>
					</SweetAlert> 
				);
				this.setState({   alert: alertBox()  });		
				
			})
			.catch(error => {
				var message = handleError(error);
				
				const alertBox = () => ( 
					<SweetAlert
						danger				
						confirmBtnBsStyle="danger"
						title={t("Error!")}
						onConfirm={() => {					
							this.hideAlert();
						}}>
							{message}
					</SweetAlert> 
				);
				this.setState({   alert: alertBox()  });
			});
		
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit Language'):t('Add New Language')}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|alpha')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="code" className="col-sm-3 col-form-label">{t("Code")}</label>
											<div className="col-sm-9">
												<input value={this.state.code} onChange={this.onChange} id="code" type="text" name="code" className="form-control" placeholder={t("Code")}></input>
												{this.validator.message('code', this.state.code, 'required|alpha')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="icon" className="col-sm-3 col-form-label">{t("Icon")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="icon"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="icon"											
														aria-describedby="icon" />
														<label className="custom-file-label" htmlFor="icon">
														{(this.state.icon !== null) ? this.state.icon.originalFilename : t("Choose file")}
														</label>
													</div>
			                                    </div>
												<div >Allowed file types are: {FILE_TYPES.join(', ')}.</div>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled")}?</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>																		
									
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Is default")}?</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="isdefault" checked={this.state.isdefault ? "checked"  :  "" } switch="none" onChange={this.changeIsdefault} />
												<label htmlFor="isdefault" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>
			
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.language.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (LanguageForm);