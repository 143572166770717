import React , { Component } from 'react';
import { Route,Switch } from 'react-router-dom';
import PrivateRoute from '../Auth/PrivateRoute';


import SuppliersList from '../MainContent/Suppliers/SuppliersList';
import SuppliersForm from '../MainContent/Suppliers/SuppliersForm';

import VendorsList from '../MainContent/Vendors/VendorsList';
import VendorsForm from '../MainContent/Vendors/VendorsForm';

import login from '../Auth/Login';
// import register from '../Auth/Register';
import recover_pass from '../Auth/Recover_pass';
import ActivateAccount from '../Auth/ActivateAccount';
import ForgetPassword from '../Auth/ForgetPassword';

import Dashboard from '../MainContent/Dashboard/Dashboard';

import MessageList from '../MainContent/Message/List';
import MessageCompose from '../MainContent/Message/Compose';
import MessageRead from '../MainContent/Message/Read';

import LanguageList from '../MainContent/Language/LanguageList';
import LanguageForm from '../MainContent/Language/LanguageForm';

import PermissionList from '../MainContent/Permissions/PermissionList';
import PermissionForm from '../MainContent/Permissions/PermissionForm';


import ApiPermissionList from '../MainContent/ApiPermissions/ApiPermissionList';
import ApiPermissionForm from '../MainContent/ApiPermissions/ApiPermissionForm';

import CurrencyList from '../MainContent/Currency/CurrencyList';
import CurrencyForm from '../MainContent/Currency/CurrencyForm';

import AccountList from '../MainContent/Account/AccountList';
import AccountForm from '../MainContent/Account/AccountForm';

import TimesheetList from '../MainContent/Timesheet/TimesheetList';
import TimesheetForm from '../MainContent/Timesheet/TimesheetForm';

import TransfersList from '../MainContent/Transfers/TransfersList';
import TransfersForm from '../MainContent/Transfers/TransfersForm';

import TransactionsList from '../MainContent/Transactions/TransactionsList';

import CategoryList from '../MainContent/Category/CategoryList';
import CategoryForm from '../MainContent/Category/CategoryForm';

import CompanyList from '../MainContent/Company/CompanyList';
import CompanyForm from '../MainContent/Company/CompanyForm';

import EmailTemplateList from '../MainContent/EmailTemplate/TemplateList';
import EmailTemplateForm from '../MainContent/EmailTemplate/TemplateForm';

import ExpenseList from '../MainContent/Expense/ExpenseList';
import ExpenseForm from '../MainContent/Expense/ExpenseForm';

import SupplierPaymentList from '../MainContent/SupplierPayment/SupplierPaymentList';
import SupplierPaymentForm from '../MainContent/SupplierPayment/SupplierPaymentForm';

import VendorPaymentList from '../MainContent/VendorPayment/VendorPaymentList';
import VendorPaymentForm from '../MainContent/VendorPayment/VendorPaymentForm';

import CustomersList from '../MainContent/Customers/CustomersList';
import CustomersForm from '../MainContent/Customers/CustomersForm';

import AgreementsList from '../MainContent/Agreements/AgreementsList';
import AgreementsForm from '../MainContent/Agreements/AgreementsForm';

import PaymentMethodList from '../MainContent/PaymentMethod/PaymentMethodList';
import PaymentMethodForm from '../MainContent/PaymentMethod/PaymentMethodForm';

import RoleList from '../MainContent/Role/RoleList';
import RoleForm from '../MainContent/Role/RoleForm';
import RoleApiPermissions from '../MainContent/Role/ApiPermissions';
import RoleWebPermissions from '../MainContent/Role/WebPermissions';

import TaxList from '../MainContent/Tax/TaxList';
import TaxForm from '../MainContent/Tax/TaxForm';

import LocalizationSettings from '../MainContent/Configuration/LocalizationSettings';
import InvoiceSettings from '../MainContent/Configuration/InvoiceSettings';
import CompanyInfo from '../MainContent/Configuration/CompanyInfo';

import Profile from "../MainContent/Profile/Profile";

import UserList from "../MainContent/User/UserList";
import UserForm from "../MainContent/User/UserForm";

import Invoices from "../MainContent/Invoice/Invoices";
import InvoiceNew from "../MainContent/Invoice/New";
import InvoiceView from "../MainContent/Invoice/View";

import Revenues from "../MainContent/Revenue/RevenueList";
import RevenueForm from "../MainContent/Revenue/RevenueForm";
import RevenueView from "../MainContent/Revenue/View";
import AgreementSettings from '../MainContent/Configuration/AgreementSettings';
import TimesheetSettings from '../MainContent/Configuration/TimesheetSettings';
import ContactSettings from '../MainContent/Configuration/ContactSettings';

class mainbuilder extends Component {
    render() {
        return (
                <Switch>
                    {/* Authentication */}
                    <Route path="/login" component={login} />
                    {/* <Route path="/register" component={register} /> */}
					<Route path="/forget-password" component={ForgetPassword} />
                    <Route path="/reset-password/:email/token/:token" component={recover_pass} />
					<Route path="/account/activation/:token/email/:email" component={ActivateAccount} />
                    
                    {/* Message */}
                    <PrivateRoute path="/message/compose" component={MessageCompose} />
                    <PrivateRoute path="/message/read/:message" component={MessageRead} />
                    <PrivateRoute path="/message/:box" component={MessageList} />

                    {/* Customers */}
                    <PrivateRoute path="/business/customers" exact={true} formPath="/business/customers" component={CustomersList} />
					<PrivateRoute path="/business/customers/create" listPath="/business/customers" component={CustomersForm} />
					<PrivateRoute path="/business/customers/:uid/update" listPath="/business/customers" component={CustomersForm} />
					<PrivateRoute path="/business/customers/:uid/duplicate" listPath="/business/customers" component={CustomersForm} />

					 {/* Suppliers */}
					<PrivateRoute path="/business/suppliers"   exact={true} formPath="/business/suppliers" component={SuppliersList} />
					<PrivateRoute path="/business/suppliers/create" listPath="/business/suppliers" component={SuppliersForm} />
					<PrivateRoute path="/business/suppliers/:uid/update" listPath="/business/suppliers" component={SuppliersForm} />
					<PrivateRoute path="/business/suppliers/:uid/duplicate" listPath="/business/suppliers" component={SuppliersForm} />

					 {/* Vendors */}
					<PrivateRoute path="/business/vendors"  exact={true} formPath="/business/vendors" component={VendorsList} />
					<PrivateRoute path="/business/vendors/create" listPath="/business/vendors" component={VendorsForm} />
					<PrivateRoute path="/business/vendors/:uid/update" listPath="/business/vendors" component={VendorsForm} />
					<PrivateRoute path="/business/vendors/:uid/duplicate" listPath="/business/vendors" component={VendorsForm} />
					
					{/* Languages */}
					<PrivateRoute path="/settings/languages" formPath="/settings/language" component={LanguageList} />
					<PrivateRoute path="/settings/language/create" listPath="/settings/languages" component={LanguageForm} />
					<PrivateRoute path="/settings/language/:id/update" listPath="/settings/languages" component={LanguageForm} />
					<PrivateRoute path="/settings/language/:id/duplicate" listPath="/settings/languages" component={LanguageForm} />

					{/* Permission */}
					<PrivateRoute path="/settings/permission"  exact={true}formPath="/settings/permission" component={PermissionList} />
					<PrivateRoute path="/settings/permission/create" listPath="/settings/permission" component={PermissionForm} />
					<PrivateRoute path="/settings/permission/:uid/update" listPath="/settings/permission" component={PermissionForm} />
					<PrivateRoute path="/settings/permission/:uid/duplicate" listPath="/settings/permission" component={PermissionForm} />
					
					{/* ApiPermission .*/}
					<PrivateRoute path="/settings/apipermission"  exact={true}formPath="/settings/apipermission" component={ApiPermissionList} />
					<PrivateRoute path="/settings/apipermission/create" listPath="/settings/apipermission" component={ApiPermissionForm} />
					<PrivateRoute path="/settings/apipermission/:uid/update" listPath="/settings/apipermission" component={ApiPermissionForm} />
					<PrivateRoute path="/settings/apipermission/:uid/duplicate" listPath="/settings/apipermission" component={ApiPermissionForm} />
					

					{/* Currencies */}
					<PrivateRoute path="/business/currencies" formPath="/business/currency" component={CurrencyList} />
					<PrivateRoute path="/business/currency/create" listPath="/business/currencies" component={CurrencyForm} />
					<PrivateRoute path="/business/currency/:id/update" listPath="/business/currencies" component={CurrencyForm} />
					<PrivateRoute path="/business/currency/:id/duplicate" listPath="/business/currencies" component={CurrencyForm} />
					
					{/* Transfers */}
					<PrivateRoute path="/banking/transfers" exact={true} formPath="/banking/transfers" component={TransfersList} />
					<PrivateRoute path="/banking/transfers/create" listPath="/banking/transfers" component={TransfersForm} />
					<PrivateRoute path="/banking/transfers/:id/update" listPath="/banking/transfers" component={TransfersForm} />
					<PrivateRoute path="/banking/transfers/:id/duplicate" listPath="/banking/transfers" component={TransfersForm} />

					{/* Transactions */}
					<PrivateRoute path="/banking/transactions" exact={true} formPath="/banking/transactions" component={TransactionsList} />

					{/* Timesheets */}
					<PrivateRoute path="/timesheet" exact={true} formPath="/timesheet" component={TimesheetList} />
					<PrivateRoute path="/timesheet/create" listPath="/timesheet" component={TimesheetForm} />
					<PrivateRoute path="/timesheet/:id/update" listPath="/timesheet" component={TimesheetForm} />
					<PrivateRoute path="/timesheet/:id/duplicate" listPath="/timesheet" component={TimesheetForm} />

					{/* Accounts */}
					<PrivateRoute path="/banking/accounts" formPath="/banking/account" component={AccountList} />
					<PrivateRoute path="/banking/account/create" listPath="/banking/accounts" component={AccountForm} />
					<PrivateRoute path="/banking/account/:id/update" listPath="/banking/accounts" component={AccountForm} />
					<PrivateRoute path="/banking/account/:id/duplicate" listPath="/banking/accounts" component={AccountForm} />
					
					{/* Categories */}
					<PrivateRoute path="/business/categories" formPath="/business/category" component={CategoryList} />
					<PrivateRoute path="/business/category/create" listPath="/business/categories" component={CategoryForm} />
					<PrivateRoute path="/business/category/:id/update" listPath="/business/categories" component={CategoryForm} />
					<PrivateRoute path="/business/category/:id/duplicate" listPath="/business/categories" component={CategoryForm} />
					
					{/* Companies */}
					<PrivateRoute path="/settings/companies" formPath="/settings/company" component={CompanyList} />
					<PrivateRoute path="/settings/company/create" listPath="/settings/companies" component={CompanyForm} />
					<PrivateRoute path="/settings/company/:id/update" listPath="/settings/companies" component={CompanyForm} />
					<PrivateRoute path="/settings/company/:id/duplicate" listPath="/settings/companies" component={CompanyForm} />
					
					{/* Email Templates */}
					<PrivateRoute path="/settings/emailtemplates" formPath="/settings/emailtemplate" component={EmailTemplateList} />
					<PrivateRoute path="/settings/emailtemplate" listPath="/settings/emailtemplates" component={EmailTemplateForm} />
					
					{/* Expenses */}
					<PrivateRoute path="/payments/expenses" exact={true} formPath="/payments/expense" component={ExpenseList} />
					<PrivateRoute path="/payments/expense/create" listPath="/payments/expenses" component={ExpenseForm} />
					<PrivateRoute path="/payments/expense/:id/update" listPath="/payments/expenses" component={ExpenseForm} />
					<PrivateRoute path="/payments/expense/:id/duplicate" listPath="/payments/expenses" component={ExpenseForm} />

					{/* Supplier payments */}
					<PrivateRoute path="/payments/suppliers"  exact={true} formPath="/payments/suppliers" component={SupplierPaymentList} />
					<PrivateRoute path="/payments/suppliers/create" listPath="/payments/suppliers" component={SupplierPaymentForm} />
					<PrivateRoute path="/payments/suppliers/:id/update" listPath="/payments/suppliers" component={SupplierPaymentForm} />
					<PrivateRoute path="/payments/suppliers/:id/duplicate" listPath="/payments/suppliers" component={SupplierPaymentForm} />

					{/* Vendor payments */}
					<PrivateRoute path="/payments/vendors"  exact={true} formPath="/payments/vendors" component={VendorPaymentList} />
					<PrivateRoute path="/payments/vendors/create" listPath="/payments/vendors" component={VendorPaymentForm} />
					<PrivateRoute path="/payments/vendors/:id/update" listPath="/payments/vendors" component={VendorPaymentForm} />
					<PrivateRoute path="/payments/vendors/:id/duplicate" listPath="/payments/vendors" component={VendorPaymentForm} />

					{/* Agreements */}
					<PrivateRoute path="/business/agreements"  exact={true} formPath="/business/agreements" component={AgreementsList} />
					<PrivateRoute path="/business/agreements/create" listPath="/business/agreements" component={AgreementsForm} />
					<PrivateRoute path="/business/agreements/:id/update" listPath="/business/agreements" component={AgreementsForm} />
					<PrivateRoute path="/business/agreements/:id/duplicate" listPath="/business/agreements" component={AgreementsForm} />
					
					{/* Payment methods */}
					<PrivateRoute path="/business/payments" formPath="/business/payment" component={PaymentMethodList} />
					<PrivateRoute path="/business/payment/create" listPath="/business/payments" component={PaymentMethodForm} />
					<PrivateRoute path="/business/payment/:id/update" listPath="/business/payments" component={PaymentMethodForm} />
					<PrivateRoute path="/business/payment/:id/duplicate" listPath="/business/payments" component={PaymentMethodForm} />
					
					{/* Roles */}
					<PrivateRoute path="/settings/roles" formPath="/settings/role" component={RoleList} />
					<PrivateRoute path="/settings/role/create" listPath="/settings/roles" component={RoleForm} />
					<PrivateRoute path="/settings/role/:uid/update" exact={true} listPath="/settings/roles" component={RoleForm} />
					<PrivateRoute path="/settings/role/:uid/update/api-permissions" exact={true} listPath="/settings/roles" component={RoleApiPermissions} />
					<PrivateRoute path="/settings/role/:uid/update/permissions" exact={true} listPath="/settings/roles" component={RoleWebPermissions} />
				
					
					{/* Taxes */}
					<PrivateRoute path="/business/taxes" formPath="/business/tax" component={TaxList} />
					<PrivateRoute path="/business/tax/create" listPath="/business/taxes" component={TaxForm} />
					<PrivateRoute path="/business/tax/:id/update" listPath="/business/taxes" component={TaxForm} />
					<PrivateRoute path="/business/tax/:id/duplicate" listPath="/business/taxes" component={TaxForm} />
					
					{/* Configurations */}
					<PrivateRoute path="/settings/localization" component={LocalizationSettings} />
					<PrivateRoute path="/settings/invoice" component={InvoiceSettings} />
					<PrivateRoute path="/settings/companyinfo" component={CompanyInfo} />
					<PrivateRoute path="/settings/agreement" component={AgreementSettings} />
					<PrivateRoute path="/settings/contacts" component={ContactSettings} />
					<PrivateRoute path="/settings/timesheet" component={TimesheetSettings} />

					{/* Invoice */}
                    <PrivateRoute path="/income/invoices" formPath="/income/invoice" component={Invoices} />
                    <PrivateRoute path="/income/invoice/create" listPath="/income/invoices" component={InvoiceNew} />
                    <PrivateRoute path="/income/invoice/:id/update" listPath="/income/invoices" component={InvoiceNew} />
					<PrivateRoute path="/income/invoice/:id/duplicate" listPath="/income/invoices" component={InvoiceNew} />
                    <PrivateRoute path="/income/invoice/:id/view" listPath="/income/invoices" component={InvoiceView} />
					<PrivateRoute path="/income/invoice/:id/payment" component={RevenueForm} />

                    {/* Revenues */}
                    <PrivateRoute path="/income/revenues" formPath="/income/revenue" component={Revenues} />
                    <PrivateRoute path="/income/revenue/create" listPath="/income/revenue" component={RevenueForm} />
                    <PrivateRoute path="/income/revenue/:id/update" listPath="/income/revenue" component={RevenueForm} />
                    {/* <PrivateRoute path="/income/revenue/view/:invoice" component={RevenueView} /> */}

					{/* Profile form */}
					<PrivateRoute path="/me" component={Profile} />

					{/* Users */}
					<PrivateRoute path="/settings/users" formPath="/settings/user" component={UserList} />
					<PrivateRoute path="/settings/user/create" listPath="/settings/users" component={UserForm} />
					<PrivateRoute path="/settings/user/:uid/update" listPath="/settings/users" component={UserForm} />
					<PrivateRoute path="/settings/user/:uid/duplicate" listPath="/settings/users" component={UserForm} />

					{/* Dashboard .....*/}
                    <PrivateRoute path="/" exact={true} component={Dashboard} />
					<PrivateRoute path="/index" exact={true} component={Dashboard} />
                </Switch>
        );
    }
}

export default mainbuilder;