import React , {Component } from 'react';
import { Link } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import ItemRow from './ItemRow';
import SimpleReactValidator from 'simple-react-validator';
import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/InvoiceActions';
    
import {loadCustomers} from '../../../store/CustomersActions';
import {loadAgreements} from '../../../store/AgreementActions';
import {loadCurrencies} from '../../../store/CurrencyActions';
import {loadCreditCategories} from '../../../store/CategoryActions';
import {loadTaxes} from '../../../store/TaxActions';
import { loadServices } from '../../../store/AgreementActions';
import {loadGlobals} from '../../../store/DashboardActions';


import Axios from 'axios';
import {API_BASE_URL, FILE_TYPES, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';

import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import $ from 'jquery';
import { t } from '../../../components/Translation';
import Select from 'react-select';

import { handleSuccess, handleError, formatAmount } from '../../../helper';
import { DISCOUNT_FIXED, DISCOUNT_PERCENT, INVOICE_DRAFT } from '../../../constants';
import SweetAlert  from 'react-bootstrap-sweetalert';

class New extends Component {

    state = {
        id: null,
        alert: null,
        customer: {},
        currency: {},
        issuedate: new Date(),
        duedate: new Date(),
        invoiceNumber: '',
        orderNumber: '',
        subtotal: 0,
        tax: 0,
        total: 0,
        status: 0,
        notes: '',
        category: {},
        agreement: {},
        attachments: [], // {"name":"asdfad","type":"asdfa","size":345345}
        services: [], // {"item":1,"quantity":334,"price":43,"tax":43,"total":43} 

    }

    componentWillMount() {
		this.validator = new SimpleReactValidator();
    }
    
    componentDidMount() {
        registerLocale('fr', fr);
        let dpath = "/income/invoice/:id/duplicate";
		let id = this.props.match.params.id;

        this.props.loadCustomers();
        this.props.loadCreditCategories();
        this.props.loadCurrencies();
        this.props.loadGlobals();
        this.props.loadTaxes();
        
        if (typeof id !== 'undefined') {
            Axios.get(API_BASE_URL + '/invoices/'+id, AuthService.getAuthHeader())
            .then(res => {			
                let inv = res.data;
                this.setState({
                    ...inv,
                    id: (this.props.path === dpath ? null: inv.id),
                    customer: inv.customer,
                    currency: inv.currency,
                    category: inv.category,
                    agreement: inv.agreement,
                    issuedate: new Date(inv.issuedate*1000),
                    duedate: new Date(inv.duedate*1000),
                    status: (this.props.path === dpath ? INVOICE_DRAFT: inv.status),
                });

                if (this.props.path === dpath) {
                    this.generateInvoiceNumber();
                }

                this.props.loadAgreements(inv.customer.value);
                this.props.loadServices(inv.agreement.value);
            });
        } else {
            this.generateInvoiceNumber();
            this.onAddClick();
        }
    }

    generateInvoiceNumber = () => {
        Axios.get(API_BASE_URL + '/settings/invoice', AuthService.getAuthHeader())
            .then(res => {
                let setting = res.data;
                this.setState({
                    ...this.state,
                    invoiceNumber: setting.number_prefix + setting.next_number.padStart(setting.number_digit, '0')
                });
                
            });
    }

    onCustomerChange = (e) => {
        if (e.value === '') {
            this.setState({
                customer: '',
                currency: '',
                agreement: ''
            })
        } else {
            let customer = e.value;
            // this.setState({customer: customer});
            
            Axios.get(API_BASE_URL + '/contacts/' + customer + '?roleName=ROLE_CUSTOMER', AuthService.getAuthHeader())
            .then(res => {
                this.setState({
                    customer: e,
                    currency: res.data.currency,
                    agreement: ''
                })
            });

            this.props.loadAgreements(customer);

        }
        // Auto update currency & agreement

    }
    
    onCurrencyChange = (e) => {
		this.setState({currency: e});
        this.props.loadGlobals(e.value);
    }

    onAgreementsChange = (e) => {
		this.setState({agreement: e});
        this.props.loadServices(e.value);
    }
    
    onCategoryChange = (e) => {
		this.setState({category: e});
    }

    onItemChange = (index, e) => {
        const services = this.state.services;
        if (e === null) {
            services[index].service = null;
            services[index].name = "";
            services[index].price = 0;
            services[index].quantity = 0;
            services[index].tax = null;
        } else {
            services[index].service = {value: e.value, label: e.label};
            services[index].name = e.name;
            services[index].price = e.price;
            services[index].quantity = e.quantity;
            services[index].tax = (e !== null) ? this.props.taxes.filter(t => t.value === e.tax)[0] : null;
        }
        this.setState({services});
        this.calculate();
    }

    onSelectInputChange = (index, e) => {
        const services = this.state.services;
        if ( e.target.value !== null && e.target.value !== "") {
            services[index].service = {value: e.target.value, label: e.target.value};
        } else {
            services[index].service = null;
        }
        this.setState({services});
        this.calculate();
    }

    onTaxChange = (index, e) => {
		const services = this.state.services;
        services[index].tax = (e !== null) ? e : null;
        this.setState({services});
        this.calculate();
    }

    onQuantityChange = (index, e) => {
        const services = this.state.services;
        services[index].quantity = parseInt(e.target.value);
        this.setState({services});
        this.calculate();
    }

    onPriceChange = (index, e) => {
        const services = this.state.services;
        services[index].price = parseFloat(e.target.value);
        this.setState({services});
        this.calculate();
    }

    onNameChange = (index, e) => {
        const services = this.state.services;
        services[index].name = e.target.value;
        this.setState({services});
        this.calculate();
    }

    onAddClick = () => {
        const services = this.state.services;
        services.push({
            service: null,
            name: '',
            price: 0,
            quantity: 1,
            tax: null,
        });
        this.setState({services});
        this.calculate();
    }

    onRemoveClick = (index) => {
        const services = this.state.services;
        services.splice(index, 1);
        this.setState({services});
        this.calculate();
    }
    
    removeAttachment = (index) => {
        const attachments = this.state.attachments;
        attachments.splice(index, 1);
        this.setState({attachments});
    }

    //datePicker
    handleIssuedateChange = (date) => {
        const res = new Date(date);
        res.setDate(res.getDate() + 45)
        this.setState({  
            issuedate: date,
            duedate: res
        });  
    }

    handleDuedateChange = (date) => {
        this.setState({duedate: date});
    }

    onChange = (e) => this.setState( {[e.target.name]: e.target.value } );

    calculate() {
        let subtotal = 0;
        let tax = 0;
        let total = 0;
        let discount = 0;
        const services = this.state.services;
        let discountType = $('#discountType').val();
        discountType = (discountType !== null ? parseInt(discountType) : DISCOUNT_FIXED);
        let discountValue = $('#discountValue').val();
        discountValue = ((discountValue !== null && discountValue !== "") ? parseInt(discountValue) : 0.0);
        services.forEach((element, ix) => {
            subtotal += element.price * element.quantity;
            let itemTax = 0;
            if (typeof element.tax !== 'undefined' && element.tax !== null) {
                var rate = element.tax.value2;
                itemTax = (element.price * ( rate / 100));
            } 
            tax += (itemTax * element.quantity);
            services[ix].total = ((element.price * element.quantity) + (itemTax * element.quantity)).toFixed(2);
        });

        if (discountType === DISCOUNT_FIXED) {
            discount = discountValue;
        } else {
            discount = (subtotal + tax) * discountValue / 100;
        }

        this.setState({
            subtotal: subtotal.toFixed(2),
            tax: tax.toFixed(2),
            discount: discount.toFixed(2),
            total: (subtotal + tax - discount).toFixed(2),
            services
        })
    }

    onChangeFile = (e) => {
        document.getElementById('attachment').disabled = "disabled";
        const attachments = this.state.attachments;
        var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.concat(FILE_TYPES).indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/file', data, AuthService.getAuthHeader())
		    	.then(res => {			
					let obj = {
                        name: res.data.fileName,
                        originalFilename: res.data.originalFilename,
                        type: res.data.fileType,
                        size: res.data.size
                    }
                    attachments.push(obj);
                    this.setState({attachments});
                    document.getElementById('attachment').value = null;
                    document.getElementById('attachment').disabled = "";
				});
		} else {
			console.error("Extension: not allowed: " + ext)
		}
    }
    
    onChangeDiscountVal = (e) => {
        let discount = e.target.value !== "" ? parseInt(e.target.value) : 0;
        this.setState( {[e.target.name]: discount } );
        this.calculate();
    }

    onChangeDiscountType = (e) => {
        this.setState( {[e.target.name]: parseInt(e.target.value) } );
        this.calculate();
    }
    
    getTimestamp = (date) => {
        return Math.floor(date.getTime() / 1000);
    }

	onSubmit = (e) => {
		e.preventDefault();
		if (this.validator.allValid()) {
            const services2 = [];
            this.state.services.forEach((element, ix) => {
                if (element.service !== null) {
                    services2[ix] = {
                        reference: element.service.label,
                        name: element.name,
                        quantity: element.quantity,
                        price: element.price,
                        tax: (element.tax !== null) ? element.tax.value : null,
                        total: element.total
                    };
                }
            });
            
            let obj = {
                ...this.state,
                customer: this.state.customer.value,
                agreement: this.state.agreement.value,
                currency: this.state.currency.value,
                category: this.state.category.value,
                issuedate: this.getTimestamp(this.state.issuedate),
                duedate: this.getTimestamp(this.state.duedate),
                services: services2
            };

            var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.id !== null) {
				promise = this.props.updateItem('/invoices/'+this.state.id, obj);
			} else {
				promise = this.props.createItem('/invoices', obj);
			}

            promise
            .then(response => {
                
                const alertBox = () => ( 
                    <SweetAlert
                        success				
                        confirmBtnBsStyle="success"
                        title={t("Success!")}
                        onConfirm={() => {					
                            // this.hideAlert();
                            history.push(listPath);
                        }}>
                    </SweetAlert> 
                );
                this.setState({   alert: alertBox()  });		
                
            })
            .catch(error => {
                var message = handleError(error);
                
                const alertBox = () => ( 
                    <SweetAlert
                        danger				
                        confirmBtnBsStyle="danger"
                        title={t("Error!")}
                        onConfirm={() => {					
                            this.hideAlert();
                        }}>
                            {message}
                    </SweetAlert> 
                );
                this.setState({   alert: alertBox()  });
            });        

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}	

    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    {this.state.alert}
                    <div className="row">

                        <div className="col-12">

                            <div className="card">
                                <div className="col card-header">
                                    <h6>{this.state.id === null ? t('New Invoice'):t('Edit Invoice')}</h6>
                                </div>
                                
                                <div className="card-body">
                                    <form onSubmit={this.onSubmit}>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                            
                                                <div className="form-group">
                                                    <label className="control-label">{t("Customer")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-user"></i></span>
                                                        </div>                                                       
                                                        <Select 
                                                            name="customer"
                                                            value={this.state.customer}
                                                            id="customer"
                                                            onChange={this.onCustomerChange}
                                                            options = {this.props.customers}
                                                            className="form-control"
                                                        />
                                                        {this.validator.message('customer', this.state.customer, 'required')}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label">{t("Currency")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-money"></i></span>
                                                        </div>
                                                        <Select 
                                                            name="currency"
                                                            value={this.state.currency}
                                                            id="currency"
                                                            onChange={this.onCurrencyChange}
                                                            options = {this.props.currencies}
                                                            className="form-control"
                                                        />
                                                        {this.validator.message('currency', this.state.currency, 'required')}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-sm-12 col-md-6">

                                                <div className="form-group">
                                                    <label className="control-label">{t("Agreement")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-file"></i></span>
                                                        </div>
                                                        <Select 
                                                            name="agreement"
                                                            value={this.state.agreement}
                                                            id="agreement"
                                                            onChange={this.onAgreementsChange}
                                                            options = {this.props.agreements} 
                                                            className="form-control"
                                                        />
                                                        {this.validator.message('agreement', this.state.agreement, 'required')}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label">{t("Category")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-folder"></i></span>
                                                        </div>
                                                        <Select 
                                                            name="category"
                                                            value={this.state.category}
                                                            id="category"
                                                            onChange={this.onCategoryChange}
                                                            options = {this.props.creditCategories} 
                                                            className="form-control"
                                                        />
                                                        {this.validator.message('category', this.state.category, 'required')}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Invoice Number")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-notepad"></i></span>
                                                        </div>
                                                        <input name="invoiceNumber" onChange={this.onChange} value={this.state.invoiceNumber} type="text" className="form-control" placeholder={t("Invoice Number")} />
                                                        {this.validator.message('invoiceNumber', this.state.invoiceNumber, 'required|string')}
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Order Number")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-clipboard"></i></span>
                                                        </div>
                                                        <input name="orderNumber" onChange={this.onChange} value={this.state.orderNumber} type="text" className="form-control" placeholder={t("Order Number")} />
                                                        {this.validator.message('orderNumber', this.state.orderNumber, 'string')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Invoice Date")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-calendar"></i></span>
                                                        </div>
                                                        <DatePicker
                                                            name="issuedate"
                                                            locale="fr"
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            selected={this.state.issuedate}
                                                            onChange={this.handleIssuedateChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Due Date")} <span className="text-danger">*</span> <span className="font-small">(45 {t("days")})</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-alarm-clock"></i></span>
                                                        </div>
                                                        <DatePicker
                                                            name="duedate"
                                                            locale="fr"
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control"
                                                            selected={this.state.duedate}
                                                            onChange={this.handleDuedateChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                                
                                            <div className="col-12 mt-3 table-responsive">
                                                <table className="table table-vertical table-bordered">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th className="text-left col-md-1">{t("Action")}</th>
                                                            <th className="text-left col-md-2">{t("Reference")}</th>
                                                            <th className="text-left col-md-auto">{t("Name")}</th>
                                                            <th className="text-center col-md-1">{t("Quantity")}</th>
                                                            <th className="col-md-1">{t("Price")}</th>
                                                            <th className="col-md-2">{t("Tax")}</th>
                                                            <th className="col-md-2">{t("Total")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {this.state.services.map((item, index) => {                                        
                                                            return <ItemRow 
                                                                key={index}
                                                                index={index}
                                                                item={item}
                                                                onRemoveClick={this.onRemoveClick}
                                                                onItemChange={this.onItemChange}
                                                                onSelectInputChange={this.onSelectInputChange}
                                                                onTaxChange={this.onTaxChange}
                                                                onQuantityChange={this.onQuantityChange}
                                                                onNameChange={this.onNameChange}
                                                                onPriceChange={this.onPriceChange}
                                                            />
                                                        })}

                                                        <tr>
                                                            <td><button onClick={this.onAddClick.bind(this)} type="button" className="btn btn-primary waves-light waves-effect" title="Add"><i className="ti-plus"></i></button></td>
                                                            <td colSpan="6">&nbsp;</td>
                                                        </tr>
                                                        <tr className="text-right">
                                                            <td colSpan="6" className="font-weight-bold">{t("Subtotal")}</td>
                                                            <td>{formatAmount(this.state.subtotal, this.props.globals.currency)}</td>
                                                        </tr>
                                                        <tr className="text-right">
                                                            <td colSpan="6">
                                                                {t("Add Discount")}:&nbsp; 
                                                                <input id="discountValue" type="text" name="discountValue" value={this.state.discountValue} onChange={this.onChangeDiscountVal} />
                                                                &nbsp;<select id="discountType" name="discountType" onChange={this.onChangeDiscountType} >
                                                                    <option value={DISCOUNT_FIXED}>{t("Fixed")}</option>
                                                                    <option value={DISCOUNT_PERCENT}>{t("Percent")}</option>
                                                                </select>
                                                            </td>
                                                            <td>{formatAmount(this.state.discount, this.props.globals.currency)}</td>
                                                        </tr>
                                                        <tr className="text-right">
                                                            <td colSpan="6" className="font-weight-bold">{t("Tax")}</td>
                                                            <td>{formatAmount(this.state.tax, this.props.globals.currency)}</td>
                                                        </tr>
                                                        <tr className="text-right font-weight-bold">
                                                            <td colSpan="6">{t("Total")}</td>
                                                            <td>{formatAmount(this.state.total, this.props.globals.currency)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 form-group">
                                                <label className="control-label">{t("Notes")}</label>
                                                <textarea name="notes" value={this.state.notes} onChange={this.onChange} className="form-control" placeholder={t("Custom Notes")} />
                                                {/* {this.validator.message('notes', this.state.notes, 'required')} */}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4">
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input
                                                        multiple="multiple"
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="attachment"
                                                        onChange={this.onChangeFile}
                                                        aria-describedby="inputGroupFileAddon01" />
                                                        <label className="custom-file-label" htmlFor="attachment">
                                                        {t("Add attachment")}
                                                        </label>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-12">
                                                <ul className="list-group list-group-horizontal">
                                                    {this.state.attachments.map((obj, idx) => {                                                    
                                                        return <li key={idx} className="list-group-item">{obj.originalFilename}<span onClick={this.removeAttachment.bind(this, idx)} className="crossBtn">X</span></li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div className="row float-right">
                                            <div className="col-12">
                                                <div className="btn-toolbar form-group mt-3">
                                                    <Link to="/income/invoices" className="btn btn-outline-secondary waves-effect waves-light m-r-5"><i className="far fa-trash-alt"></i> {t("Cancel")}</Link>
                                                    <button type="submit" className="btn btn-outline-success waves-effect waves-light"> <span>{t("Save invoice")}</span> <i className="fab fa-telegram-plane m-l-10"></i> </button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStateToProps = state => ({
    needComponentUpdate: state.invoice.needComponentUpdate,
    customers: state.customers.options,
    agreements: state.agreement.options,
    currencies: state.currency.options,
    creditCategories: state.category.creditCategories,
    taxes: state.tax.options,
    globals: state.dash.globals,
})

export default connect (mapStateToProps, {
    createItem, updateItem, setNeedComponentUpdate, loadCustomers, loadAgreements, loadCurrencies,
    loadCreditCategories, loadTaxes, loadServices, loadGlobals}) (New);