import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/AccountActions';
import {t} from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class AccountForm extends Component {

	state = {
		id: null,
		name: '',
		number: '',
		currency: null,
		balance: 0.0,
		hasTransactions: false,
		bankName: '',
		bankPhone: '',
		bankAddress: '',		
		status: true,
		isdefault: false,
		currency_options: [],
		company_options: [],
		
		alert: null,
	}

	hideAlert = () => {
		this.setState({   alert: null  });
	}
	
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/banking/account/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
			Axios.get(API_BASE_URL + '/accounts/'+id, AuthService.getAuthHeader())
			.then(res => {			
				var st = res.data;
				this.setState({
					id: (this.props.path === dpath ? null: st.id),
					name: st.name,
					number: st.number,
					currency: st.currency,
					balance: st.balance,
					hasTransactions: st.hasTransactions,
					bankName: st.bankName,
					bankPhone: st.bankPhone,
					bankAddress: st.bankAddress,
					status: (st.status === "enabled"),
					isdefault: (st.isdefault === 1)
				});
			});
		}		
		Axios.get(API_BASE_URL + '/list/currency-options', AuthService.getAuthHeader())
		.then(res => {
			this.setState({currency_options: res.data});
		});
	}

	changeStatus = () => { this.setState({ status: !this.state.status });  }

	changeIsdefault = () => { this.setState({ isdefault: !this.state.isdefault });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onCurrencyChange = (e) => {
		this.setState({currency: e});
	}
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var account = {
				name: this.state.name,
				number: this.state.number,
				currency: this.state.currency.value,
				balance: this.state.balance,
				bankName: this.state.bankName,
				bankPhone: this.state.bankPhone,
				bankAddress: this.state.bankAddress,
				status: this.state.status ? "enabled" : "disabled",
				isdefault: this.state.isdefault ? 1 : 0
			}
			
			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;
			
			if (this.state.id !== null) {
				promise = this.props.updateItem('/accounts/'+this.state.id, account);				
			} else {
				promise = this.props.createItem('/accounts', account);
			}	
			
			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});
				
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit'):t('Add')} {t("Account")}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|alpha_space')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="number" className="col-sm-3 col-form-label">{t("Number")}</label>
											<div className="col-sm-9">
												<input value={this.state.number} onChange={this.onChange} id="number" type="text" name="number" className="form-control" placeholder={t("Number")}></input>
												{this.validator.message('number', this.state.number, 'required|string')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="currency" className="col-sm-3 col-form-label">{t("Currency")}</label>
											<div className="col-sm-9">
												<Select 
													name="currency"
													value={this.state.currency}
													id="currency"
													onChange={this.onCurrencyChange}
													options = {this.state.currency_options}
												/>
												{this.validator.message('currency', this.state.currency, 'required')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="balance" className="col-sm-3 col-form-label">{t("Balance")}</label>
											<div className="col-sm-9">
												<input readOnly={this.state.hasTransactions} value={this.state.balance} onChange={this.onChange} id="balance" type="text" name="balance" className="form-control" placeholder={t("Balance")}></input>
												{this.validator.message('balance', this.state.balance, 'required|numeric')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="bankName" className="col-sm-3 col-form-label">{t("Bank Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.bankName} onChange={this.onChange} id="bankName" type="text" name="bankName" className="form-control" placeholder={t("Bank Name")}></input>
												{this.validator.message('bankName', this.state.bankName, 'required|string')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="bankPhone" className="col-sm-3 col-form-label">{t("Bank Phone")}</label>
											<div className="col-sm-9">
												<input value={this.state.bankPhone} onChange={this.onChange} id="bankPhone" type="text" name="bankPhone" className="form-control" placeholder={t("Bank phone")}></input>
												{this.validator.message('bankPhone', this.state.bankPhone, 'required|phone')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="bankAddress" className="col-sm-3 col-form-label">{t("Bank Address")}</label>
											<div className="col-sm-9">
												<input value={this.state.bankAddress} onChange={this.onChange} id="bankAddress" type="text" name="bankAddress" className="form-control" placeholder={t("Bank address")}></input>
												{this.validator.message('bankAddress', this.state.bankAddress, 'required|string')}
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled?")}</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>																		
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Is default?")}</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="isdefault" checked={this.state.isdefault ? "checked"  :  "" } switch="none" onChange={this.changeIsdefault} />
												<label htmlFor="isdefault" data-on-label={t("Yes")}  data-off-label={t("No")}></label>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.account.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (AccountForm);