// Layout actions
export const SIDE_BAR = 'side_bar';
export const TOP_BAR = 'top_bar';
export const LOGINPAGE = 'loginpage';
export const REG_PAGE = 'reg_page';
export const SWITCHTOREG = 'switch_reg';
export const SWITCHTOLOGIN = 'switch_login';
export const RECOVER_PASS = 'recover_pass';
export const TINY_CHART_DATA = 'TINY_CHART_DATA';

// Dashboard
export const DASH_COUNTERS = 'DASH_COUNTERS';
export const DASH_UNPAID_INVOICES = 'DASH_UNPAID_INVOICES';
export const DASH_LATEST_INCOMES = 'DASH_LATEST_INCOMES';
export const DASH_LATEST_PAYMENTS = 'DASH_LATEST_PAYMENTS';
export const DASH_LATEST_MESSAGES = 'DASH_LATEST_MESSAGES';
export const DASH_LOAD_GLOBALS = 'DASH_LOAD_GLOBALS';
export const DASH_SALES_CHART = 'DASH_SALES_CHART';

// Messages
export const LIST_FOLDERS = 'list_folders';
export const LIST_LABELS = 'list_labels';
export const LIST_MESSAGES = 'list_messages';
export const LOAD_MESSAGE = 'load_message';
export const SET_FOLDER = 'set_folder';
export const LOAD_MESSAGE_PAGE = 'load_message_page';
export const DELETE_MESSAGES = 'delete_messages';
export const SET_LABEL_TO_MESSAGE = 'set_label_to_message';
export const MARK_MESSAGES_READ_UNREAD = 'mark_messages_read_unread';
export const MARK_IMPORTANT = 'mark_important';
export const SET_LABEL = 'set_label';
export const SET_LABEL_NULL = 'set_label_null';
export const CREATE_LABEL = 'create_label';
export const SEND_MESSAGE = 'send_message';

// USER CRUD
export const CRUD_USER_LIST = 'CRUD_USER_list';
export const CRUD_USER_FILTER_STATUS = 'CRUD_USER_filter_upon_status';
export const CRUD_USER_FILTER_PAGESIZE = 'CRUD_USER_filter_upon_pageSize';
export const CRUD_USER_FILTER_KEYWORD = 'CRUD_USER_filter_upon_keyword';
export const CRUD_USER_SORT_ITEMS = 'CRUD_USER_sort_items';
export const CRUD_USER_CREATE_ITEM = 'CRUD_USER_create_item';
export const CRUD_USER_UPDATE_ITEM = 'CRUD_USER_update_item';
export const CRUD_USER_DELETE_ITEM = 'CRUD_USER_delete_item';
export const CRUD_USER_NEED_COMPONENT_SET = 'CRUD_USER_need_componentUpdate_set';

// API_PERMISSION CRUD
export const CRUD_API_PERMISSION_LIST = 'CRUD_API_PERMISSION_list';
export const CRUD_API_PERMISSION_GENERATE = 'CRUD_API_PERMISSION_GENERATE';
export const CRUD_API_PERMISSION_FILTER_STATUS = 'CRUD_API_PERMISSION_filter_upon_status';
export const CRUD_API_PERMISSION_FILTER_PAGESIZE = 'CRUD_API_PERMISSION_filter_upon_pageSize';
export const CRUD_API_PERMISSION_FILTER_KEYWORD = 'CRUD_API_PERMISSION_filter_upon_keyword';
export const CRUD_API_PERMISSION_SORT_ITEMS = 'CRUD_API_PERMISSION_sort_items';
export const CRUD_API_PERMISSION_CREATE_ITEM = 'CRUD_API_PERMISSION_create_item';
export const CRUD_API_PERMISSION_UPDATE_ITEM = 'CRUD_API_PERMISSION_update_item';
export const CRUD_API_PERMISSION_DELETE_ITEM = 'CRUD_API_PERMISSION_delete_item';
export const CRUD_API_PERMISSION_NEED_COMPONENT_SET = 'CRUD_API_PERMISSION_need_componentUpdate_set';

// PERMISSION CRUD
export const CRUD_PERMISSION_LIST = 'CRUD_PERMISSION_list';
export const ALLOWED_PERMISSION_LIST = 'ALLOWED_PERMISSION_LIST';
export const CRUD_PERMISSION_FILTER_STATUS = 'CRUD_PERMISSION_filter_upon_status';
export const CRUD_PERMISSION_FILTER_PAGESIZE = 'CRUD_PERMISSION_filter_upon_pageSize';
export const CRUD_PERMISSION_FILTER_KEYWORD = 'CRUD_PERMISSION_filter_upon_keyword';
export const CRUD_PERMISSION_SORT_ITEMS = 'CRUD_PERMISSION_sort_items';
export const CRUD_PERMISSION_CREATE_ITEM = 'CRUD_PERMISSION_create_item';
export const CRUD_PERMISSION_UPDATE_ITEM = 'CRUD_PERMISSION_update_item';
export const CRUD_PERMISSION_DELETE_ITEM = 'CRUD_PERMISSION_delete_item';
export const CRUD_PERMISSION_NEED_COMPONENT_SET = 'CRUD_PERMISSION_need_componentUpdate_set';

// Timesheet 
export const CRUD_TIMESHEET_LIST = 'CRUD_TIMESHEET_list';
export const CRUD_TIMESHEET_ITEM_INVOICE_GENERATE = 'CRUD_TIMESHEET_ITEM_INVOICE_GENERATE';
export const CRUD_TIMESHEET_FILTER_STATUS = 'CRUD_TIMESHEET_filter_upon_status';
export const CRUD_TIMESHEET_FILTER_PAGESIZE = 'CRUD_TIMESHEET_filter_upon_pageSize';
export const CRUD_TIMESHEET_FILTER_KEYWORD = 'CRUD_TIMESHEET_filter_upon_keyword';
export const CRUD_TIMESHEET_FILTER_DATE = 'CRUD_TIMESHEET_FILTER_DATE';
export const CRUD_TIMESHEET_FILTER_MONTH = 'CRUD_TIMESHEET_FILTER_MONTH';
export const CRUD_TIMESHEET_FILTER_AMOUNT = 'CRUD_TIMESHEET_FILTER_AMOUNT';
export const CRUD_TIMESHEET_SORT_ITEMS = 'CRUD_TIMESHEET_sort_items';
export const CRUD_TIMESHEET_CREATE_ITEM = 'CRUD_TIMESHEET_create_item';
export const CRUD_TIMESHEET_UPDATE_ITEM = 'CRUD_TIMESHEET_update_item';
export const CRUD_TIMESHEET_DELETE_ITEM = 'CRUD_TIMESHEET_delete_item';
export const CRUD_TIMESHEET_NEED_COMPONENT_SET = 'CRUD_TIMESHEET_need_componentUpdate_set';

// Account 
export const CRUD_ACCOUNT_LIST = 'CRUD_ACCOUNT_list';
export const CRUD_ACCOUNT_FILTER_STATUS = 'CRUD_ACCOUNT_filter_upon_status';
export const CRUD_ACCOUNT_FILTER_PAGESIZE = 'CRUD_ACCOUNT_filter_upon_pageSize';
export const CRUD_ACCOUNT_FILTER_KEYWORD = 'CRUD_ACCOUNT_filter_upon_keyword';
export const CRUD_ACCOUNT_FILTER_DATE = 'CRUD_ACCOUNT_FILTER_DATE';
export const CRUD_ACCOUNT_FILTER_AMOUNT = 'CRUD_ACCOUNT_FILTER_AMOUNT';
export const CRUD_ACCOUNT_SORT_ITEMS = 'CRUD_ACCOUNT_sort_items';
export const CRUD_ACCOUNT_CREATE_ITEM = 'CRUD_ACCOUNT_create_item';
export const CRUD_ACCOUNT_UPDATE_ITEM = 'CRUD_ACCOUNT_update_item';
export const CRUD_ACCOUNT_DELETE_ITEM = 'CRUD_ACCOUNT_delete_item';
export const CRUD_ACCOUNT_NEED_COMPONENT_SET = 'CRUD_ACCOUNT_need_componentUpdate_set';

// Trasnfers 
export const CRUD_TRANSFERS_LIST = 'CRUD_TRANSFERS_list';
export const CRUD_TRANSFERS_FILTER_STATUS = 'CRUD_TRANSFERS_filter_upon_status';
export const CRUD_TRANSFERS_FILTER_PAGESIZE = 'CRUD_TRANSFERS_filter_upon_pageSize';
export const CRUD_TRANSFERS_FILTER_KEYWORD = 'CRUD_TRANSFERS_filter_upon_keyword';
export const CRUD_TRANSFERS_FILTER_DATE = 'CRUD_TRANSFERS_FILTER_DATE';
export const CRUD_TRANSFERS_FILTER_AMOUNT = 'CRUD_TRANSFERS_FILTER_AMOUNT';
export const CRUD_TRANSFERS_SORT_ITEMS = 'CRUD_TRANSFERS_sort_items';
export const CRUD_TRANSFERS_CREATE_ITEM = 'CRUD_TRANSFERS_create_item';
export const CRUD_TRANSFERS_UPDATE_ITEM = 'CRUD_TRANSFERS_update_item';
export const CRUD_TRANSFERS_DELETE_ITEM = 'CRUD_TRANSFERS_delete_item';
export const CRUD_TRANSFERS_NEED_COMPONENT_SET = 'CRUD_TRANSFERS_need_componentUpdate_set';

// Transactions 
export const CRUD_TRANSACTIONS_LIST = 'CRUD_TRANSACTIONS_list';
export const CRUD_TRANSACTIONS_FILTER_STATUS = 'CRUD_TRANSACTIONS_filter_upon_status';
export const CRUD_TRANSACTIONS_FILTER_PAGESIZE = 'CRUD_TRANSACTIONS_filter_upon_pageSize';
export const CRUD_TRANSACTIONS_FILTER_KEYWORD = 'CRUD_TRANSACTIONS_filter_upon_keyword';
export const CRUD_TRANSACTIONS_SORT_ITEMS = 'CRUD_TRANSACTIONS_sort_items';
export const CRUD_TRANSACTIONS_FILTER_DATE = 'CRUD_TRANSACTIONS_FILTER_DATE';
export const CRUD_TRANSACTIONS_FILTER_AMOUNT = 'CRUD_TRANSACTIONS_FILTER_AMOUNT';
export const CRUD_TRANSACTIONS_NEED_COMPONENT_SET = 'CRUD_TRANSACTIONS_need_componentUpdate_set';


// Category 
export const CRUD_CATEGORY_LIST = 'CRUD_CATEGORY_list';
export const CRUD_CATEGORY_OPTIONS = 'CRUD_CATEGORY_OPTIONS';
export const CRUD_CATEGORY_FILTER_STATUS = 'CRUD_CATEGORY_filter_upon_status';
export const CRUD_CATEGORY_FILTER_PAGESIZE = 'CRUD_CATEGORY_filter_upon_pageSize';
export const CRUD_CATEGORY_FILTER_KEYWORD = 'CRUD_CATEGORY_filter_upon_keyword';
export const CRUD_CATEGORY_SORT_ITEMS = 'CRUD_CATEGORY_sort_items';
export const CRUD_CATEGORY_CREATE_ITEM = 'CRUD_CATEGORY_create_item';
export const CRUD_CATEGORY_UPDATE_ITEM = 'CRUD_CATEGORY_update_item';
export const CRUD_CATEGORY_DELETE_ITEM = 'CRUD_CATEGORY_delete_item';
export const CRUD_CATEGORY_NEED_COMPONENT_SET = 'CRUD_CATEGORY_need_componentUpdate_set';

// Company CRUD
export const CRUD_COMPANY_LIST = 'CRUD_COMPANY_list';
export const CRUD_COMPANY_FILTER_STATUS = 'CRUD_COMPANY_filter_upon_status';
export const CRUD_COMPANY_FILTER_PAGESIZE = 'CRUD_COMPANY_filter_upon_pageSize';
export const CRUD_COMPANY_FILTER_KEYWORD = 'CRUD_COMPANY_filter_upon_keyword';
export const CRUD_COMPANY_SORT_ITEMS = 'CRUD_COMPANY_sort_items';
export const CRUD_COMPANY_CREATE_ITEM = 'CRUD_COMPANY_create_item';
export const CRUD_COMPANY_UPDATE_ITEM = 'CRUD_COMPANY_update_item';
export const CRUD_COMPANY_DELETE_ITEM = 'CRUD_COMPANY_delete_item';
export const CRUD_COMPANY_NEED_COMPONENT_SET = 'CRUD_COMPANY_need_componentUpdate_set';

// Currency CRUD
export const CRUD_CURRENCY_LIST = 'CRUD_CURRENCY_list';
export const CRUD_CURRENCY_OPTIONS = 'CRUD_CURRENCY_OPTIONS';
export const CRUD_CURRENCY_FILTER_STATUS = 'CRUD_CURRENCY_filter_upon_status';
export const CRUD_CURRENCY_FILTER_PAGESIZE = 'CRUD_CURRENCY_filter_upon_pageSize';
export const CRUD_CURRENCY_FILTER_KEYWORD = 'CRUD_CURRENCY_filter_upon_keyword';
export const CRUD_CURRENCY_SORT_ITEMS = 'CRUD_CURRENCY_sort_items';
export const CRUD_CURRENCY_CREATE_ITEM = 'CRUD_CURRENCY_create_item';
export const CRUD_CURRENCY_UPDATE_ITEM = 'CRUD_CURRENCY_update_item';
export const CRUD_CURRENCY_DELETE_ITEM = 'CRUD_CURRENCY_delete_item';
export const CRUD_CURRENCY_NEED_COMPONENT_SET = 'CRUD_CURRENCY_need_componentUpdate_set';

// CUSTOMERS CRUD
export const CRUD_CUSTOMERS_LIST = 'CRUD_CUSTOMERS_list';
export const CRUD_CUSTOMERS_OPTIONS = 'CRUD_CUSTOMERS_OPTIONS';
export const CRUD_CUSTOMERS_FILTER_STATUS = 'CRUD_CUSTOMERS_filter_upon_status';
export const CRUD_CUSTOMERS_FILTER_PAGESIZE = 'CRUD_CUSTOMERS_filter_upon_pageSize';
export const CRUD_CUSTOMERS_FILTER_KEYWORD = 'CRUD_CUSTOMERS_filter_upon_keyword';
export const CRUD_CUSTOMERS_SORT_ITEMS = 'CRUD_CUSTOMERS_sort_items';
export const CRUD_CUSTOMERS_CREATE_ITEM = 'CRUD_CUSTOMERS_create_item';
export const CRUD_CUSTOMERS_UPDATE_ITEM = 'CRUD_CUSTOMERS_update_item';
export const CRUD_CUSTOMERS_DELETE_ITEM = 'CRUD_CUSTOMERS_delete_item';
export const CRUD_CUSTOMERS_NEED_COMPONENT_SET = 'CRUD_CUSTOMERS_need_componentUpdate_set';

// SUPPLIERS CRUD
export const CRUD_SUPPLIERS_LIST = 'CRUD_SUPPLIERS_list';
export const CRUD_SUPPLIERS_OPTIONS = 'CRUD_SUPPLIERS_OPTIONS';
export const CRUD_SUPPLIERS_FILTER_STATUS = 'CRUD_SUPPLIERS_filter_upon_status';
export const CRUD_SUPPLIERS_FILTER_PAGESIZE = 'CRUD_SUPPLIERS_filter_upon_pageSize';
export const CRUD_SUPPLIERS_FILTER_KEYWORD = 'CRUD_SUPPLIERS_filter_upon_keyword';
export const CRUD_SUPPLIERS_SORT_ITEMS = 'CRUD_SUPPLIERS_sort_items';
export const CRUD_SUPPLIERS_CREATE_ITEM = 'CRUD_SUPPLIERS_create_item';
export const CRUD_SUPPLIERS_UPDATE_ITEM = 'CRUD_SUPPLIERS_update_item';
export const CRUD_SUPPLIERS_DELETE_ITEM = 'CRUD_SUPPLIERS_delete_item';
export const CRUD_SUPPLIERS_NEED_COMPONENT_SET = 'CRUD_SUPPLIERS_need_componentUpdate_set';

// VENDORS CRUD
export const CRUD_VENDORS_OPTIONS = 'CRUD_VENDORS_OPTIONS';
export const CRUD_VENDORS_LIST = 'CRUD_VENDORS_list';
export const CRUD_VENDORS_FILTER_STATUS = 'CRUD_VENDORS_filter_upon_status';
export const CRUD_VENDORS_FILTER_PAGESIZE = 'CRUD_VENDORS_filter_upon_pageSize';
export const CRUD_VENDORS_FILTER_KEYWORD = 'CRUD_VENDORS_filter_upon_keyword';
export const CRUD_VENDORS_SORT_ITEMS = 'CRUD_VENDORS_sort_items';
export const CRUD_VENDORS_CREATE_ITEM = 'CRUD_VENDORS_create_item';
export const CRUD_VENDORS_UPDATE_ITEM = 'CRUD_VENDORS_update_item';
export const CRUD_VENDORS_DELETE_ITEM = 'CRUD_VENDORS_delete_item';
export const CRUD_VENDORS_NEED_COMPONENT_SET = 'CRUD_VENDORS_need_componentUpdate_set';

// EXPENSES CRUD
export const CRUD_EXPENSE_LIST = 'CRUD_EXPENSE_list';
export const CRUD_EXPENSE_FILTER_STATUS = 'CRUD_EXPENSE_filter_upon_status';
export const CRUD_EXPENSE_FILTER_PAGESIZE = 'CRUD_EXPENSE_filter_upon_pageSize';
export const CRUD_EXPENSE_FILTER_KEYWORD = 'CRUD_EXPENSE_filter_upon_keyword';
export const CRUD_EXPENSE_SORT_ITEMS = 'CRUD_EXPENSE_sort_items';
export const CRUD_EXPENSE_FILTER_DATE = 'CRUD_EXPENSE_FILTER_DATE';
export const CRUD_EXPENSE_FILTER_AMOUNT = 'CRUD_EXPENSE_FILTER_AMOUNT';
export const CRUD_EXPENSE_CREATE_ITEM = 'CRUD_EXPENSE_create_item';
export const CRUD_EXPENSE_UPDATE_ITEM = 'CRUD_EXPENSE_update_item';
export const CRUD_EXPENSE_DELETE_ITEM = 'CRUD_EXPENSE_delete_item';
export const CRUD_EXPENSE_NEED_COMPONENT_SET = 'CRUD_EXPENSE_need_componentUpdate_set';

// SUPPLIER_PAYMENT CRUD
export const CRUD_SUPPLIER_PAYMENT_LIST = 'CRUD_SUPPLIER_PAYMENT_list';
export const CRUD_SUPPLIER_PAYMENT_FILTER_STATUS = 'CRUD_SUPPLIER_PAYMENT_filter_upon_status';
export const CRUD_SUPPLIER_PAYMENT_FILTER_PAGESIZE = 'CRUD_SUPPLIER_PAYMENT_filter_upon_pageSize';
export const CRUD_SUPPLIER_PAYMENT_FILTER_KEYWORD = 'CRUD_SUPPLIER_PAYMENT_filter_upon_keyword';
export const CRUD_SUPPLIER_PAYMENT_SORT_ITEMS = 'CRUD_SUPPLIER_PAYMENT_sort_items';
export const CRUD_SUPPLIER_PAYMENT_FILTER_DATE = 'CRUD_SUPPLIER_PAYMENT_FILTER_DATE';
export const CRUD_SUPPLIER_PAYMENT_FILTER_AMOUNT = 'CRUD_SUPPLIER_PAYMENT_FILTER_AMOUNT';
export const CRUD_SUPPLIER_PAYMENT_CREATE_ITEM = 'CRUD_SUPPLIER_PAYMENT_create_item';
export const CRUD_SUPPLIER_PAYMENT_UPDATE_ITEM = 'CRUD_SUPPLIER_PAYMENT_update_item';
export const CRUD_SUPPLIER_PAYMENT_DELETE_ITEM = 'CRUD_SUPPLIER_PAYMENT_delete_item';
export const CRUD_SUPPLIER_PAYMENT_NEED_COMPONENT_SET = 'CRUD_SUPPLIER_PAYMENT_need_componentUpdate_set';

// VENDOR_PAYMENT CRUD
export const CRUD_VENDOR_PAYMENT_LIST = 'CRUD_VENDOR_PAYMENT_list';
export const CRUD_VENDOR_PAYMENT_FILTER_STATUS = 'CRUD_VENDOR_PAYMENT_filter_upon_status';
export const CRUD_VENDOR_PAYMENT_FILTER_PAGESIZE = 'CRUD_VENDOR_PAYMENT_filter_upon_pageSize';
export const CRUD_VENDOR_PAYMENT_FILTER_KEYWORD = 'CRUD_VENDOR_PAYMENT_filter_upon_keyword';
export const CRUD_VENDOR_PAYMENT_SORT_ITEMS = 'CRUD_VENDOR_PAYMENT_sort_items';
export const CRUD_VENDOR_PAYMENT_FILTER_DATE = 'CRUD_VENDOR_PAYMENT_FILTER_DATE';
export const CRUD_VENDOR_PAYMENT_FILTER_AMOUNT = 'CRUD_VENDOR_PAYMENT_FILTER_AMOUNT';
export const CRUD_VENDOR_PAYMENT_CREATE_ITEM = 'CRUD_VENDOR_PAYMENT_create_item';
export const CRUD_VENDOR_PAYMENT_UPDATE_ITEM = 'CRUD_VENDOR_PAYMENT_update_item';
export const CRUD_VENDOR_PAYMENT_DELETE_ITEM = 'CRUD_VENDOR_PAYMENT_delete_item';
export const CRUD_VENDOR_PAYMENT_NEED_COMPONENT_SET = 'CRUD_VENDOR_PAYMENT_need_componentUpdate_set';

// INVOICES CRUD
export const CRUD_INVOICE_LIST = 'CRUD_INVOICE_list';
export const CRUD_INVOICE_OPTIONS = 'CRUD_INVOICE_OPTIONS';
export const CRUD_INVOICE_FILTER_STATUS = 'CRUD_INVOICE_filter_upon_status';
export const CRUD_INVOICE_FILTER_PAGESIZE = 'CRUD_INVOICE_filter_upon_pageSize';
export const CRUD_INVOICE_FILTER_KEYWORD = 'CRUD_INVOICE_filter_upon_keyword';
export const CRUD_INVOICE_SORT_ITEMS = 'CRUD_INVOICE_sort_items';
export const CRUD_INVOICE_FILTER_DATE = 'CRUD_INVOICE_FILTER_DATE';
export const CRUD_INVOICE_FILTER_AMOUNT = 'CRUD_INVOICE_FILTER_AMOUNT';
export const CRUD_INVOICE_CREATE_ITEM = 'CRUD_INVOICE_create_item';
export const CRUD_INVOICE_UPDATE_ITEM = 'CRUD_INVOICE_update_item';
export const CRUD_INVOICE_DELETE_ITEM = 'CRUD_INVOICE_delete_item';
export const CRUD_INVOICE_NEED_COMPONENT_SET = 'CRUD_INVOICE_need_componentUpdate_set';

// ITEMS CRUD
export const CRUD_ITEM_LIST = 'CRUD_ITEM_list';
export const CRUD_ITEM_FILTER_STATUS = 'CRUD_ITEM_filter_upon_status';
export const CRUD_ITEM_FILTER_PAGESIZE = 'CRUD_ITEM_filter_upon_pageSize';
export const CRUD_ITEM_FILTER_KEYWORD = 'CRUD_ITEM_filter_upon_keyword';
export const CRUD_ITEM_SORT_ITEMS = 'CRUD_ITEM_sort_items';
export const CRUD_ITEM_CREATE_ITEM = 'CRUD_ITEM_create_item';
export const CRUD_ITEM_UPDATE_ITEM = 'CRUD_ITEM_update_item';
export const CRUD_ITEM_DELETE_ITEM = 'CRUD_ITEM_delete_item';
export const CRUD_ITEM_NEED_COMPONENT_SET = 'CRUD_ITEM_need_componentUpdate_set';

// LANGUAGE CRUD
export const CRUD_LANGUAGE_LIST = 'CRUD_LANGUAGE_list';
export const CRUD_LANGUAGE_FILTER_STATUS = 'CRUD_LANGUAGE_filter_upon_status';
export const CRUD_LANGUAGE_FILTER_PAGESIZE = 'CRUD_LANGUAGE_filter_upon_pageSize';
export const CRUD_LANGUAGE_FILTER_KEYWORD = 'CRUD_LANGUAGE_filter_upon_keyword';
export const CRUD_LANGUAGE_SORT_ITEMS = 'CRUD_LANGUAGE_sort_items';
export const CRUD_LANGUAGE_CREATE_ITEM = 'CRUD_LANGUAGE_create_item';
export const CRUD_LANGUAGE_UPDATE_ITEM = 'CRUD_LANGUAGE_update_item';
export const CRUD_LANGUAGE_DELETE_ITEM = 'CRUD_LANGUAGE_delete_item';
export const CRUD_LANGUAGE_NEED_COMPONENT_SET = 'CRUD_LANGUAGE_need_componentUpdate_set';

// PAYMENT METHOD CRUD
export const CRUD_PM_LIST = 'CRUD_PM_list';
export const CRUD_PM_FILTER_STATUS = 'CRUD_PM_filter_upon_status';
export const CRUD_PM_FILTER_PAGESIZE = 'CRUD_PM_filter_upon_pageSize';
export const CRUD_PM_FILTER_KEYWORD = 'CRUD_PM_filter_upon_keyword';
export const CRUD_PM_SORT_ITEMS = 'CRUD_PM_sort_items';
export const CRUD_PM_CREATE_ITEM = 'CRUD_PM_create_item';
export const CRUD_PM_UPDATE_ITEM = 'CRUD_PM_update_item';
export const CRUD_PM_DELETE_ITEM = 'CRUD_PM_delete_item';
export const CRUD_PM_NEED_COMPONENT_SET = 'CRUD_PM_need_componentUpdate_set';

// REVENUE CRUD
export const CRUD_REVENUE_LIST = 'CRUD_REVENUE_list';
export const CRUD_REVENUE_FILTER_STATUS = 'CRUD_REVENUE_filter_upon_status';
export const CRUD_REVENUE_FILTER_PAGESIZE = 'CRUD_REVENUE_filter_upon_pageSize';
export const CRUD_REVENUE_FILTER_KEYWORD = 'CRUD_REVENUE_filter_upon_keyword';
export const CRUD_REVENUE_SORT_ITEMS = 'CRUD_REVENUE_sort_items';
export const CRUD_REVENUE_FILTER_DATE = 'CRUD_REVENUE_FILTER_DATE';
export const CRUD_REVENUE_FILTER_AMOUNT = 'CRUD_REVENUE_FILTER_AMOUNT';
export const CRUD_REVENUE_CREATE_ITEM = 'CRUD_REVENUE_create_item';
export const CRUD_REVENUE_UPDATE_ITEM = 'CRUD_REVENUE_update_item';
export const CRUD_REVENUE_DELETE_ITEM = 'CRUD_REVENUE_delete_item';
export const CRUD_REVENUE_NEED_COMPONENT_SET = 'CRUD_REVENUE_need_componentUpdate_set';

// ROLE CRUD
export const CRUD_ROLE_LIST = 'CRUD_ROLE_list';
export const CRUD_ROLE_FILTER_STATUS = 'CRUD_ROLE_filter_upon_status';
export const CRUD_ROLE_FILTER_PAGESIZE = 'CRUD_ROLE_filter_upon_pageSize';
export const CRUD_ROLE_FILTER_KEYWORD = 'CRUD_ROLE_filter_upon_keyword';
export const CRUD_ROLE_SORT_ITEMS = 'CRUD_ROLE_sort_items';
export const CRUD_ROLE_CREATE_ITEM = 'CRUD_ROLE_create_item';
export const CRUD_ROLE_UPDATE_ITEM = 'CRUD_ROLE_update_item';
export const CRUD_ROLE_DELETE_ITEM = 'CRUD_ROLE_delete_item';
export const CRUD_ROLE_NEED_COMPONENT_SET = 'CRUD_ROLE_need_componentUpdate_set';

// TAX CRUD
export const CRUD_TAX_LIST = 'CRUD_TAX_list';
export const CRUD_TAX_OPTIONS = 'CRUD_TAX_OPTIONS';
export const CRUD_TAX_FILTER_STATUS = 'CRUD_TAX_filter_upon_status';
export const CRUD_TAX_FILTER_PAGESIZE = 'CRUD_TAX_filter_upon_pageSize';
export const CRUD_TAX_FILTER_KEYWORD = 'CRUD_TAX_filter_upon_keyword';
export const CRUD_TAX_SORT_ITEMS = 'CRUD_TAX_sort_items';
export const CRUD_TAX_CREATE_ITEM = 'CRUD_TAX_create_item';
export const CRUD_TAX_UPDATE_ITEM = 'CRUD_TAX_update_item';
export const CRUD_TAX_DELETE_ITEM = 'CRUD_TAX_delete_item';
export const CRUD_TAX_NEED_COMPONENT_SET = 'CRUD_TAX_need_componentUpdate_set';

// REVENUE FORM ACTIONS
export const CRUD_REVENUE_LOAD_CUSTOMERS = 'CRUD_REVENUE_LOAD_CUSTOMERS';
export const CRUD_REVENUE_LOAD_ACCOUNTS = 'CRUD_REVENUE_LOAD_ACCOUNTS';
export const CRUD_REVENUE_LOAD_CATEGORIES = 'CRUD_REVENUE_LOAD_CATEGORIES';
export const CRUD_REVENUE_LOAD_PAYMETHODS = 'CRUD_REVENUE_LOAD_PAYMETHODS';

// CONFIGURATION
export const LOAD_CONFIGURATION_LOCALIZATION = 'LOAD_CONFIGURATION_LOCALIZATION';

// AGREEMENTS
export const CRUD_AGREEMENT_LIST = 'CRUD_AGREEMENT_list';
export const CRUD_AGREEMENT_SERVICES_OPTIONS = 'CRUD_AGREEMENT_SERVICES_OPTIONS';
export const CRUD_AGREEMENT_OPTIONS = 'CRUD_AGREEMENT_OPTIONS';
export const CRUD_SERVICE_OPTIONS = 'CRUD_SERVICE_OPTIONS';
export const CRUD_AGREEMENT_FILTER_STATUS = 'CRUD_AGREEMENT_filter_upon_status';
export const CRUD_AGREEMENT_FILTER_PAGESIZE = 'CRUD_AGREEMENT_filter_upon_pageSize';
export const CRUD_AGREEMENT_FILTER_KEYWORD = 'CRUD_AGREEMENT_filter_upon_keyword';
export const CRUD_AGREEMENT_SORT_ITEMS = 'CRUD_AGREEMENT_sort_items';
export const CRUD_AGREEMENT_FILTER_DATE = 'CRUD_AGREEMENT_FILTER_DATE';
export const CRUD_AGREEMENT_FILTER_AMOUNT = 'CRUD_AGREEMENT_FILTER_AMOUNT';
export const CRUD_AGREEMENT_CREATE_ITEM = 'CRUD_AGREEMENT_create_item';
export const CRUD_AGREEMENT_UPDATE_ITEM = 'CRUD_AGREEMENT_update_item';
export const CRUD_AGREEMENT_DELETE_ITEM = 'CRUD_AGREEMENT_delete_item';
export const CRUD_AGREEMENT_NEED_COMPONENT_SET = 'CRUD_AGREEMENT_need_componentUpdate_set';