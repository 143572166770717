import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import DateTime from '../../../components/Timestamp/DateTime';
import Action from '../../../components/UI/DataTable/Action';
import Status from '../../../components/UI/DataTable/Status';

import Tbl from './tbl';
import {connect} from 'react-redux';
import {deleteItem} from '../../../store/TransfersActions';
import {t} from '../../../components/Translation';
import { formatDate, formatAmount } from '../../../helper';
import { loadGlobals } from '../../../store/DashboardActions';


class TransfersList extends Component {
	state = {
		alert: null,
		baseUrl: '/transfers',
		addBtn: {
			label: t('Create new Transfers'),
			url: this.props.formPath + "/create"
		},
		columns: [
		    {
			  label: '#',
			  field: 'serial',
			  sort: false
		    },
			{
			  label: t('Id'),
			  field: 'id',
			  sort: false
		    },
		    {
				label: t('Date'),
				field: 'date',
				sort: true,
				sortCol: 'date'
			},
			
			{
				label: t('From Account'),
				field: 'fromaccount',
				sort: true,
				sortCol: 'fromAccountName'
			},
			{
				label: t('To Account'),
				field: 'toaccount',
				sort: true,
				sortCol: 'toAccountName'
			},
			{
				label: t('Amount'),
				field: 'amount',
				sort: true,
				sortCol: 'amount'
			},
			{
				label: t('Payment method'),
				field: 'paymentMethod',
				sort: true,
				sortCol: 'paymentMethodName',
			},
			{
				label: t('notes'),
				field: 'notes',
				sort: false
			},
			
		    {
		      label: t('Actions'),
		      field: 'actions',
		      sort: false
		    }
	  	],
		statuses: [
			{
				label: t("All"),
				value: null,
				class: "btn-outline-dark"
			},
			{
				label: t("Enabled"),
				value: "enabled",
				class: "btn-outline-success"
			},
			{
				label: t("Disabled"),
				value: "disabled",
				class: "btn-outline-warning"
			}
		]
	}

	componentDidMount() {
		this.props.loadGlobals();
	}

	deleteItem = (id) => {		
		this.props.deleteItem(this.state.baseUrl + '/' + id);
	}

	mapAttributes(rows) {
		var items = [];
		for (const [index, value] of rows.entries()) {
			var item = {
				'serial': (this.props.pageSize * this.props.pagination.page)+1+index,
				'id': value.id,
				'date': formatDate(value.date, this.props.globals.dateFormat), 
				'fromaccount': value.fromAccount.label.Capitalize(),
				'toaccount': value.toAccount.label.Capitalize(),
				'amount': formatAmount(value.amount, this.props.globals.currency),
				'paymentMethod': value.paymentMethod.label.Capitalize(),
				'notes': value.notes,
				'actions': <Action state={value} path={this.props.formPath} onDelete={this.deleteItem} />
			};
			items.push( item );
		}
		return items;
	}

	render() {
		let rows = this.mapAttributes(this.props.rows);
		return (
			<AUX>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-body">			
									<Tbl 
										baseUrl={this.state.baseUrl}
										addBtn={this.state.addBtn}
										statuses={this.state.statuses}
                                        columns={this.state.columns}
										rows={rows}
                                    />
								</div>
							</div>
						</div>
					</div>								
				</div>
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    rows: state.transfer.rows,
	globals: state.dash.globals,
	pagination: state.transfer.pagination,
	pageSize: state.transfer.pageSize,
})

export default connect( mapStateToProps, {deleteItem, loadGlobals} ) ( TransfersList );