import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';
import '../../../components/Text/String';
import TimeAgo from '../../../components/Timestamp/TimeAgo';
import { t } from '../../../components/Translation';

class RowItem extends Component {
    
    // constructor(props) {
    //     super(props);
    // }

    render() {

        const row = this.props.row;
        let label = ( row.labelId !== 0 ) ? this.props.labels.filter(lbl => lbl.id === row.labelId)[0] : null;
        let labelHtml = ( label !== null ) ? <span className="badge mr-2" style={{color:'white', backgroundColor:label.color}}>{label.name.Capitalize()}</span> : "";
        
        const to = {
            pathname: "/message/read/"+row.uid,
            state: {
                currentFolder: this.props.currentFolder,
                // currentLabel: this.props.currentLabel,
                labels: this.props.labels,
                folders: this.props.folders,
                userUid: this.props.userUid,
            }
        }

        return (
            <AUX>
                
                <li className={row.readStatus ? "" : "unread"}>
                    <div className="col-mail col-mail-1">
                        <div className="checkbox-wrapper-mail">
                            <input type="checkbox" id={"chk_"+row.uid} data-id={row.uid} />
                            <label htmlFor={"chk_"+row.uid} className="toggle"></label>
                        </div>
                        <Link to={to} className="title">{(this.props.userUid === row.receiverId ? row.senderName : row.receiverName)}</Link><span onClick={this.props.onToggleSingleImportant.bind(this, row.uid, !row.important )} className={"star-toggle far fa-star "+ (row.important ? "text-danger": "") } ></span>
                    </div>
                    <div className="col-mail col-mail-2">
                        <Link to={to} className="subject">{labelHtml}{row.subject.Truncate(30)}</Link>
                        <div className="date"><TimeAgo timestamp={row.created} /></div>
                    </div>
                </li>

            </AUX>
        );
    }
}

export default RowItem;