import {TINY_CHART_DATA} from '../action';

const initialState = {
    revenueData: [
        {x: '1', y: 0},
        {x: '2', y: 0},
        {x: '3', y: 0},
        {x: '4', y: 0},
        {x: '5', y: 0},
        {x: '6', y: 0},
        {x: '7', y: 0},
        {x: '8', y: 0},
        {x: '9', y: 0},
        {x: '10', y: 0},
        {x: '11', y: 0},
        {x: '12', y: 0},
    ],
    profitData: [
        {x: '1', y: 0},
        {x: '2', y: 0},
        {x: '3', y: 0},
        {x: '4', y: 0},
        {x: '5', y: 0},
        {x: '6', y: 0},
        {x: '7', y: 0},
        {x: '8', y: 0},
        {x: '9', y: 0},
        {x: '10', y: 0},
        {x: '11', y: 0},
        {x: '12', y: 0},
    ],
    totalRevenue: 0,
    totalProfit: 0
}

const tinyreducer = (state = initialState, action) =>{
    switch(action.type) {
        case TINY_CHART_DATA:            
            return {
                ...state,
                revenueData: action.data.revenueData,
                profitData: action.data.profitData,
                totalRevenue: action.data.totalRevenue,
                totalProfit: action.data.totalProfit
            };
        default :
            return state;
    }
}

export default tinyreducer;