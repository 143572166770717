import {LANG} from '../../config';

export const t = (key, params = []) => {
    var lang = localStorage.getItem('language') || LANG;
    const strings = require('../../../public/locales/' + lang + '/translation.json');
    let message = strings[key] || key;
    for (var i = 0; i < params.length; i++) {
        message = message.replace("{"+i+"}", params[i]);
    }
    return message;
}

export const setLang = (lang) => {
    localStorage.setItem('language', lang);
}