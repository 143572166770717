import React  from 'react';
import {Link} from 'react-router-dom';
import ImageString from '../../../components/UI/DataTable/ImageString';
import SweetAlert  from 'react-bootstrap-sweetalert';

import {t} from '../../Translation';
import { INVOICE_DRAFT } from '../../../constants';
 
export default class Action extends React.Component {

	constructor(props) {
		super(props);
		this.type = this.props.type;
		this.state = {
	      	alert: null
	    };
	}

	componentDidMount() {
		const duplicate = { ...this.props.state };
		duplicate.id = null;
		this.setState({
			obj: this.props.state,
			duplicate: duplicate
		});
	}

	hideAlert() {
		this.setState({   alert: null  });
	}
	
	onDelete = (id) => {
		const alertBox = () => (
            <SweetAlert
				danger
				showCancel
				confirmBtnText={t("Yes, delete it!")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to delete?")}
            	onCancel={() => this.hideAlert()}
				onConfirm={() => {
					this.props.onDelete(id);
					this.hideAlert();
				}}>
			</SweetAlert> 
		);
        this.setState({   alert: alertBox()  });
	}

	onGeneratePassword = (id) => {
		const alertBox = () => (
			<SweetAlert
				warning
				showCancel
				confirmBtnText={t("Yes, do it!")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to reset password?")}
				onCancel={() => this.hideAlert()}
				onConfirm={() => {
					this.props.onGeneratePassword(id);
					this.hideAlert();
				}}>
			</SweetAlert>
		);
		this.setState({   alert: alertBox()  });
	}

	onGenerateInvoices = (id) => {
		const alertBox = () => (
			<SweetAlert
				warning
				showCancel
				confirmBtnText={t("Yes, do it!")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to generate invoice? You can do it only once!")}
				onCancel={() => this.hideAlert()}
				onConfirm={() => {
					this.props.onGenerateInvoices(id);
					this.hideAlert();
				}}>
			</SweetAlert>
		);
		this.setState({   alert: alertBox()  });
	}

	onSendTimesheet = (id) => {
		const alertBox = () => (
			<SweetAlert
				warning
				showCancel
				confirmBtnText={t("Yes, do it!")}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				title={t("Are you sure to send email to customer?")}
				onCancel={() => this.hideAlert()}
				onConfirm={() => {
					this.props.onSendTimesheet(id);
					this.hideAlert();
				}}>
			</SweetAlert>
		);
		this.setState({   alert: alertBox()  });
	}
			
    render() {
        return (
			<React.Fragment>
				{(() => {
					switch (this.type) {
						case 'contact':
							return <React.Fragment>
								{this.state.alert}
								<div className="btn-group">
									<Link className="btn btn-outline-secondary waves-effect waves-light" to={{pathname: this.props.path + "/" + this.props.state.uid + "/update"}}>{t("Edit")}</Link>
									<button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										{/* <a className="dropdown-item" href="#">{t("Report")}</a> */}
										{/* <a className="dropdown-item" href="#">{t("Disable")}</a> */}
										{/* <div className="dropdown-divider"></div> */}
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.uid + "/duplicate"}}>{t("Duplicate")}</Link>
										<div className="dropdown-divider"></div>
										<button onClick={this.onDelete.bind(this, this.props.state.uid)} className="dropdown-item text-danger">{t("Delete")}</button>
									</div>
								</div>
							</React.Fragment>
						
						case 'emailtemplate':
							return <React.Fragment>
								{this.state.alert}
								<div className="btn-group">
									<button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										{t("Edit")}
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										{this.props.languages.map((value, index) => {
									        return <Link key={index} className="dropdown-item" to={{
										      pathname: this.props.path,
										      state: {id: this.props.id, language: value, languages: this.props.languages}
										    }}> <ImageString image={value.icon} string={value.name} /> </Link>;
									    })}	
									</div>
								</div>
							</React.Fragment>

						case 'invoice':
							return <React.Fragment>
								{this.state.alert}
								<div className="btn-group">
									<Link className="btn btn-outline-secondary waves-effect waves-light" to={{pathname: this.props.path + "/" + this.props.state.id + "/view"}}>{t("Show")}</Link>
									<button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div className="dropdown-menu">
										{(this.props.state.status === INVOICE_DRAFT) && <><Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.id + "/update"}}>{t("Edit")}</Link>
										<div className="dropdown-divider"></div></>}
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.id + "/duplicate"}}>{t("Duplicate")}</Link>
										{(this.props.state.status === INVOICE_DRAFT) && <><div className="dropdown-divider"></div>
										<button onClick={this.onDelete.bind(this, this.props.state.id)} className="dropdown-item text-danger">{t("Delete")}</button></>}
									</div>
								</div>
							</React.Fragment>

						case 'user':
							return <React.Fragment>
								{this.state.alert}
								<div className="btn-group">
									<Link className="btn btn-outline-secondary waves-effect waves-light" to={{pathname: this.props.path + "/" + this.props.state.uid + "/update"}}>{t("Edit")}</Link>
									<button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.uid + "/duplicate"}}>{t("Duplicate")}</Link>
										<div className="dropdown-divider"></div>
										<button onClick={this.onGeneratePassword.bind(this, this.props.state.uid)} className="dropdown-item">{t("Generate new password")}</button>
										<div className="dropdown-divider"></div>
										<button onClick={this.onDelete.bind(this, this.props.state.uid)} className="dropdown-item text-danger">{t("Delete")}</button>
									</div>
								</div>
							</React.Fragment>

						case 'revenue':
							return <React.Fragment>
								action col
							</React.Fragment>

						case 'role':
							return (this.props.state.name !== "ROLE_SUPERADMIN") ? <React.Fragment>
								{this.state.alert}
								<div className="btn-group">
									<Link className="btn btn-outline-secondary waves-effect waves-light" to={{pathname: this.props.path + "/" + this.props.state.uid + "/update"}}>{t("Edit")}</Link>
									<button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.uid + "/update/api-permissions"}}>{t("Api permissions")}</Link>&nbsp;
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.uid + "/update/permissions"}}>{t("Web permissions")}</Link>
									</div>
								</div>
							</React.Fragment> :
							<React.Fragment />

						case 'permission':
						case 'apipermission':
							return <React.Fragment>
								{this.state.alert}
								<div className="btn-group">
									<Link className="btn btn-outline-secondary waves-effect waves-light" to={{pathname: this.props.path + "/" + this.props.state.uid + "/update"}}>{t("Edit")}</Link>
									<button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.uid + "/duplicate"}}>{t("Duplicate")}</Link>
										<div className="dropdown-divider"></div>
										<button onClick={this.onDelete.bind(this, this.props.state.uid)} className="dropdown-item text-danger">{t("Delete")}</button>
									</div>
								</div>
							</React.Fragment>

						case 'timesheet':
							return <React.Fragment>
								{this.state.alert}
								
								<div className="btn-group">
									{(this.props.state.status < 2) ? 
									<Link className="btn btn-outline-secondary waves-effect waves-light" to={{pathname: this.props.path + "/" + this.props.state.id + "/update"}}>{t("Edit")}</Link> :
									<span className="btn btn-outline-secondary waves-effect waves-light">{t("Edit")}</span>
									}
									<button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.id + "/duplicate"}}>{t("Duplicate")}</Link>
										{(!this.props.state.invoiceGenerated) && <><div className="dropdown-divider"></div>
										<button onClick={this.onGenerateInvoices.bind(this, this.props.state.id)} className="dropdown-item text-warning">{t("Generate Invoices")}</button></>}
										<div className="dropdown-divider"></div>
										<button onClick={this.onSendTimesheet.bind(this, this.props.state.id)} className="dropdown-item text-warning">{t("Send to customer")}</button>
										<div className="dropdown-divider"></div>
										<button onClick={this.props.onClickTimesheetExport.bind(this, this.props.state.id, this.props.state.number)} className="dropdown-item text-primary">{t("Export")}</button>
										<div className="dropdown-divider"></div>
										<button onClick={this.onDelete.bind(this, this.props.state.id)} className="dropdown-item text-danger">{t("Delete")}</button>
									</div>
								</div>
							</React.Fragment>
						default:
							return <React.Fragment>
								{this.state.alert}
								<div className="btn-group">
									<Link className="btn btn-outline-secondary waves-effect waves-light" to={{pathname: this.props.path + "/" + this.props.state.id + "/update"}}>{t("Edit")}</Link>
									<button type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="sr-only">Toggle Dropdown</span>
									</button>
									<div className="dropdown-menu dropdown-menu-right">
										<Link className="dropdown-item" to={{pathname: this.props.path + "/" + this.props.state.id + "/duplicate"}}>{t("Duplicate")}</Link>
										<div className="dropdown-divider"></div>
										<button onClick={this.onDelete.bind(this, this.props.state.id)} className="dropdown-item text-danger">{t("Delete")}</button>
									</div>
								</div>
							</React.Fragment>
					}
				})()}
			</React.Fragment>
        );
    }
}