import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Left from './Left';
import SimpleReactValidator from 'simple-react-validator';

import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import Select from 'react-select';

import {sendMessage} from '../../../store/MessageActions';

import Axios from 'axios';
import {API_BASE_URL, FILE_TYPES, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import $ from 'jquery';
import { t } from '../../../components/Translation';

class Compose extends Component {

    state = {        
        to: "",
        subject: "",
        content: "",
        editorState: EditorState.createEmpty(),
        attachments: [],

        alert: null,
    }

    componentWillMount() {
		this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        let st = this.props.location.state;
        if (st && st.to !== undefined) {
            this.setState({to: st.to});
        }
    }

    componentDidUpdate() {
        
        if (this.props.messageStatus) {
            // show success message
            console.log('message sent')
            this.props.history.push('/message/' + this.props.location.state.folders[0].uid)
        } else {

        }
        // this.props.history.push('/message/' + this.props.location.state.folders[0].uid)
    }
    
    onChange = (e) => this.setState( {[e.target.name]: e.target.value });


    onSupplierChange = (e) => {
		this.setState({supplier: e});
	}

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
          content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        });
    };

    
    removeAttachment = (index) => {
        let attachments = this.state.attachments;
        attachments.splice(index, 1);
        this.setState({attachments});
    }

    onAttachBtnClick = (e) => {
        $('#attachment').click();
    }

    onChangeFile = (e) => {
        document.getElementById('attachment').disabled = "disabled";
        document.getElementById('attachBtn').disabled = "disabled";
        let attachments = this.state.attachments;
        var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.concat(FILE_TYPES).indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/file', data, AuthService.getAuthHeader())
		    	.then(res => {			
					let obj = {
                        name: res.data.fileName,
                        originalName: res.data.originalFilename,
                        type: res.data.fileType,
                        size: res.data.size
                    }
                    attachments.push(obj);
                    this.setState({attachments});
                    document.getElementById('attachment').value = null;
                    document.getElementById('attachment').disabled = "";
                    document.getElementById('attachBtn').disabled = "";
				});
		} else {
			console.error("Extension: not allowed: " + ext)
		}
    }

    emptyForm = () => {
        this.setState({
            to: "",
            subject: "",
            content: "",
            editorState: EditorState.createEmpty(),
            attachments: []
        });
    }

     onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var message = {
				to: this.state.to,
				subject: this.state.subject,
                content: this.state.content,
                attachments: this.state.attachments
			}
            this.props.sendMessage(message);
            this.emptyForm();
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
        }
    }

    render() {

        let st = this.props.location.state;

        return (
            <AUX>
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12">
                            <div className="email-leftbar card">
                            <Left 
                                type="compose"
                                folders={st.folders} 
                                labels={st.labels} 
                                />
                            </div>

                            <div className="email-rightbar mb-3">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.onSubmit}>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                   
                                                        <Select 
                                                            name="to"
                                                            value={this.state.supplier}
                                                            id="to"
                                                            onChange={this.onSupplierChange}
                                                            options = {this.state.supplier_options} 
                                                        />
                                                        {this.validator.message('to', this.state.to, 'required')}
                                                    </div>
                                                   <div className="form-group">
                                                        <input name="subject" type="text" className="form-control" placeholder={t("Subject")} value={this.state.subject} onChange={this.onChange} />
                                                        {this.validator.message('subject', this.state.subject, 'required|alpha_num_space')}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <ul id="attachments1">
                                                        {this.state.attachments.map((file, index) => {
                                                            return <li key={index}>
                                                                {file.originalName}
                                                                <span onClick={this.removeAttachment.bind(this, index)} className="float-right">x</span>
                                                            </li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <Editor
                                            name="content"
                                            editorState={this.state.editorState}
                                            onEditorStateChange={this.onEditorStateChange}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName" /> 
                                            {this.validator.message('content', this.state.content, 'required')}
                                            <br></br>
                                            
                                            <input
                                                multiple="multiple"
                                                type="file"
                                                className="custom-file-input"
                                                id="attachment"
                                                style={{ display: "none" }}
                                                onChange={this.onChangeFile}
                                                aria-describedby="inputGroupFileAddon01" />

                                            <div className="btn-toolbar form-group mb-0">
                                                <div className="">
                                                    <button onClick={this.emptyForm.bind(this)} type="button" className="btn btn-outline-secondary waves-effect waves-light m-r-5"><i className="far fa-trash-alt"></i></button>
                                                    <button type="submit" className="btn btn-outline-primary waves-effect waves-light m-r-5"> <span>{t("Send")}</span> <i className="fab fa-telegram-plane m-l-10"></i> </button>
                                                    <button type="button" id="attachBtn" onClick={this.onAttachBtnClick} className="btn btn-outline-primary waves-effect waves-light"> <span>{t("Attach file")}</span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> 
                        </div>

                    </div>
                </div>
            </AUX>
        );
    }
}

Compose.propTypes = {
    sendMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    messageStatus: state.msg_store.messageStatus
})

export default connect ( mapStateToProps, {sendMessage}) (Compose);