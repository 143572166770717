import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import {PropTypes} from 'prop-types';
import httpBuildQuery from 'http-build-query';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {listItems, filterUponStatus, filterUponPageSize, filterUponKeyword, sortItems, setNeedComponentUpdate} from '../../../store/PaymentMethodActions';
import { t } from '../../../components/Translation';
import ReactPaginate from 'react-paginate';

class Tbl extends Component {

    state = {
        query: ""
    }

    componentDidMount() {
        this.props.listItems(this.props.baseUrl + '?' + this.buildQueryString());
    }

    componentDidUpdate() {
        if (this.props.needComponentUpdate) {
            this.props.listItems(this.props.baseUrl + '?' + this.buildQueryString());
            this.props.setNeedComponentUpdate(false);
        }
    }

    buildQueryString(attrs) {
        let queryParams = {
            query: this.props.query,
            status: this.props.status,
            size: this.props.pageSize,
            page: this.props.pagination.page,
            sort_by: this.props.sort.col,
            sort_dir: this.props.sort.order
        };
        for (var key in attrs) {
            queryParams[key] = attrs[key];         
        }
        let params = new Array();
        for (const [index, value] of Object.entries(queryParams)) {
            if (value !== null && value !== "")
                params[index] = value;
        }
		return httpBuildQuery(params);
    }

    filterStatus = (status) => {
		this.props.filterUponStatus(this.props.baseUrl + '?' + this.buildQueryString({status:status}), status);
    }

    onChangePageSize = (e) => {
        this.props.filterUponPageSize(this.props.baseUrl + '?' + this.buildQueryString({size: e.target.value}), e.target.value);
    }

    onSearchInputChange = (e) => {
        this.setState({query:e.target.value});
        if (e.target.value.length > 3) {
            this.props.filterUponKeyword(this.props.baseUrl + '?' + this.buildQueryString({query: e.target.value}), e.target.value);
        }
    }

    onSortClick = (col) => {
        let sort = this.props.sort;
        if ( sort.col === col ) {
            sort.order = (sort.order === "ASC" ? "DESC": "ASC");
        } else {
            sort = {
                col: col,
                order: "ASC"
            }
        }
        this.props.sortItems(this.props.baseUrl + '?' + this.buildQueryString({sort_by:sort.col, sort_dir:sort.order}), sort);
    }

    onChangePage = (page) => {
        this.props.listItems(this.props.baseUrl + '?' + this.buildQueryString({page: page.selected}));
    }

    render() {

        return(
            <AUX>
                <div className="button-items">
                    <Link to={this.props.addBtn.url} className="btn btn-outline-primary waves-effect waves-light">{this.props.addBtn.label}</Link>																					
                    {this.props.statuses.length > 0 ?
                    <div className="float-right">
                        <span className="btn btn-outline-default" >{t("Show per status")}: </span>
                        {this.props.statuses.map((st, idx) => {
                            return <button key={idx} onClick={this.filterStatus.bind(this, st.value)} type="button" className={"btn " + st.class + " waves-effect waves-light" + (st.value===this.props.status ? " active":"")}>{st.label}</button>;
                        })}
                    </div>	
                    : ""}	
                </div>
                <div className="m-t-30">
                    <div className="dataTables_wrapper dt-bootstrap4">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                {this.props.pageSizeOptions ?
                                <div className="dataTables_length bs-select">
                                    <label>{t("Show entries")}
                                        <select value={this.props.pageSize} onChange={this.onChangePageSize.bind(this)} className="custom-select custom-select-sm form-control form-control-sm">
                                            {this.props.pageSizeOptions.map((value, index) => {
                                                return <option key={index} value={value} >{value}</option>
                                            })}
                                        </select>
                                    </label>
                                </div> : ''}
                            </div>
                            {this.props.searchable ? 
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_filter">
                                    <label>{t("Search")}
                                        <input type="search" className="form-control form-control-sm" placeholder={t("Search")} value={this.state.query} onChange={this.onSearchInputChange.bind(this)} />
                                    </label>
                                </div>
                            </div> : ""}
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="table-responsive">
                                    <table
                                        className={"table" + (this.props.bordered ? ' table-bordered' : '') + (this.props.hover ? ' table-hover' : '') + (this.props.striped ? ' table-striped' : '') + " dataTable"}>
                                        <thead>
                                            <tr>
                                                {this.props.columns.map((value, index) => {
                                                    return <th key={index} onClick={value.sort ? this.onSortClick.bind(this, value.field) : null } >{value.label}
                                                        {value.sort ? 
                                                            <i aria-hidden="true" className="fa fa-sort float-right"></i>
                                                        : "" }
                                                    </th>
                                                })}                                            
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.rows.map((fields, index) => {
                                                return <tr key={index}>
                                                    {Object.values(fields).map((v, i) => {
                                                        return <td key={i}>{v}</td>;
                                                    })}
                                                </tr>
                                            })}                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {this.props.pagination.pages > 1 ? 
                        <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" role="status" aria-live="polite">
                                    {t("Showing {0} to {1} of {2} entries", [
                                        (this.props.pagination.page * this.props.pageSize + 1),
                                        (this.props.pagination.page * this.props.pageSize + this.props.rows.length),
                                        (this.props.pagination.total)
                                    ])} 
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <div className="dataTables_paginate">
                                    <ReactPaginate
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    nextLabel={t("Next")}
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    onPageChange={this.onChangePage}
                                    pageRangeDisplayed={5}
                                    pageCount={this.props.pagination.pages}
                                    previousLabel={t("Previous")}
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    renderOnZeroPageCount={null}
                                    className="pagination float-right"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                />
                                </div>
                            </div>
                        </div> : ""}
                    </div>
                </div>
            </AUX>
        )
    }
}

Tbl.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    addBtn: PropTypes.object.isRequired,
    statuses: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    bordered: state.paymentmethod.bordered,
    striped: state.paymentmethod.striped,
    hover: state.paymentmethod.hover,
    searchable: state.paymentmethod.searchable,
    query: state.paymentmethod.query,
    pageSize: state.paymentmethod.pageSize,
    pageSizeOptions: state.paymentmethod.pageSizeOptions,
    pagination: state.paymentmethod.pagination,
    sort: state.paymentmethod.sort,
    // rows: state.paymentmethod.rows,
    status: state.paymentmethod.status,
    needComponentUpdate: state.paymentmethod.needComponentUpdate,
})

export default connect( mapStateToProps, {listItems, filterUponStatus, filterUponPageSize, filterUponKeyword, sortItems, setNeedComponentUpdate} ) ( Tbl );