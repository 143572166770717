import React from 'react';
import AuthService from './AuthService';
import {
    Route,
    Redirect
  } from "react-router-dom";
  
  
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
         AuthService.loggedIn() ? ( 
          <Component {...rest} {...props} />
         ) : (
           <Redirect
             to={{
               pathname: '/login',
               state: { from: props.location }
             }}
           />
         )
      }
    />
);
  
export default PrivateRoute