import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import SweetAlert  from 'react-bootstrap-sweetalert';
import {t} from '../../../components/Translation';
import Select from 'react-select';
import {loadCurrencies} from '../../../store/CurrencyActions';
import {connect} from 'react-redux';

class CompanyInfo extends Component {

	state = {
	  	name: '',
		email: '',
		domain:'',
		currency: null,
	    address1: '',
  	    address2: '',
		logo: '',
	    city: '',
	    country: '',
	    tax: '',
	    
		alert: null
	}
	
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		Axios.get(API_BASE_URL + '/settings/company-info', AuthService.getAuthHeader())
		.then(res => {
			this.setState(res.data);
		});
		this.props.loadCurrencies();
	}
	
	hideAlert() {
		this.setState({   alert: null  });
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onChangeFile = (e) => {		
		var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/image', data, AuthService.getAuthHeader())
		    	.then(res => {			
					this.setState({logo: res.data.fileName});
				});
		} else {
			
		}
	}
	onSubmit = (e) => {
		e.preventDefault();
		if (this.validator.allValid()) {
			var info = {
				...this.state,
				
			};			
			var requestUrl = API_BASE_URL + '/settings/company-info';
			var requestMethod = 'put';
			Axios.request({
				url: requestUrl, 
				method: requestMethod,
				data: info,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + AuthService.getToken()
				}
			})
			.then(res => {						
				const alertBox = () => ( 
		            <SweetAlert
						success				
						confirmBtnBsStyle="success"
						title={t("Success!")}
						onConfirm={() => {					
							this.hideAlert();
						}}>
					</SweetAlert> 
				);
		        this.setState({   alert: alertBox()  });
			});
		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				{this.state.alert}
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{t("My Company")}</div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="email" className="col-sm-3 col-form-label">{t("Email")}</label>
											<div className="col-sm-9">
												<input value={this.state.email} onChange={this.onChange} id="email" type="text" name="email" className="form-control" placeholder={t("Email")}></input>
												{this.validator.message('email', this.state.email, 'required|string|email')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="domain" className="col-sm-3 col-form-label">{t("Domain")}</label>
											<div className="col-sm-9">
												<input value={this.state.domain} onChange={this.onChange} id="domain" type="text" name="domain" className="form-control" placeholder={t("domain")}></input>
												{this.validator.message('domain', this.state.domain, 'required|string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="currency" className="col-sm-3 col-form-label">{t("Currency")}</label>
											<div className="col-sm-9">
												<Select 
													name="currency"
													value={this.state.currency}
													id="currency"
													onChange={this.onCurrencyChange}
													options = {this.props.currencies} 
												/>
												{this.validator.message('currency', this.state.currency, 'required')}
											</div>
										</div>
										
										<div className="form-group row">
											<label htmlFor="address1" className="col-sm-3 col-form-label">{t("Address line 1")}</label>
											<div className="col-sm-9">
												<input value={this.state.address} onChange={this.onChange} id="address" type="text" name="address" className="form-control" placeholder={t("Address1")}></input>
												{this.validator.message('address', this.state.address, 'required|string')}
											</div>
										</div>	

										<div className="form-group row">
											<label htmlFor="address2" className="col-sm-3 col-form-label">{t("Address line 2")}</label>
											<div className="col-sm-9">
												<input value={this.state.address2} onChange={this.onChange} id="address2" type="text" name="address2" className="form-control" placeholder={t("Address2")}></input>
												{this.validator.message('address2', this.state.address2, 'string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="city" className="col-sm-3 col-form-label">{t("City")}</label>
											<div className="col-sm-9">
												<input value={this.state.city} onChange={this.onChange} id="city" type="text" name="city" className="form-control" placeholder={t("City")}></input>
												{this.validator.message('city', this.state.city, 'required|string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="country" className="col-sm-3 col-form-label">{t("Country")}</label>
											<div className="col-sm-9">
												<input value={this.state.country} onChange={this.onChange} id="country" type="text" name="country" className="form-control" placeholder={t("Country")}></input>
												{this.validator.message('country', this.state.country, 'required|string')}
											</div>
										</div>		

										<div className="form-group row">
											<label htmlFor="tax number" className="col-sm-3 col-form-label">{t("Tax")}</label>
											<div className="col-sm-9">
												<input value={this.state.tax} onChange={this.onChange} id="tax" type="text" name="tax" className="form-control" placeholder={t("Tax number")}></input>
												{this.validator.message('tax', this.state.tax, 'required|string')}
											</div>
										</div>		
																										
												
										<div className="form-group row">
											<label htmlFor="logo" className="col-sm-3 col-form-label">{t("Logo")}</label>
											<div className="col-sm-9">
												<div className="input-group">
													<div className="custom-file">
														<input
														name="logo"
														onChange={this.onChangeFile}
														type="file"
														className="custom-file-input"
														id="logo"											
														aria-describedby="logo" />
														<label className="custom-file-label" htmlFor="logo">
															{t("Choose file")}
														</label>
													</div>
			                                    </div>
												{(this.state.logo !== null) && <img className='image-width' src={API_IMAGES_URL + this.state.logo} /> }
												<div >{t("Allowed file types are: {0}.", [IMAGE_TYPES.join(', ')])}</div>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    currencies: state.currency.options
})

export default connect (mapStateToProps, {loadCurrencies}) (CompanyInfo);