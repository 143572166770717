import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import { Route,Switch } from 'react-router-dom';
import mainbuilder from './containers/mainbuilder/smartoffice';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AUX from './hoc/Aux_';
import './App.css';

class App extends Component {

  render() {
    let layout = null;

      layout = (
        <Layout topbar={this.props.topbar} sidebar = {this.props.sidebar} isloginpage={this.props.loginpage}>
          <Switch>  
            <Route path="/" component={mainbuilder} />
          </Switch>
        </Layout>);
    
    return (
      <AUX>
        {layout}
      </AUX>
    );
  }
}
const mapStatetoProps = state =>{
  return {
      topbar: state.ui_red.top_bar,
      sidebar:state.ui_red.side_bar,
      loginpage:state.ui_red.loginpage
  };
}

export default withRouter(connect(mapStatetoProps)(App))  ;
