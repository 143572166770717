import React , {Component } from 'react';
import { Link } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import {loadCustomers, loadAccounts, loadCategories, loadPaymentMethods} from '../../../store/RevenueFormActions';
import {loadInvoices} from '../../../store/InvoiceActions';

import Axios from 'axios';
import {API_BASE_URL, API_IMAGES_URL, FILE_TYPES, IMAGE_TYPES } from '../../../config';
import AuthService from '../../Auth/AuthService';
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import "react-datepicker/dist/react-datepicker.css";
import { t } from '../../../components/Translation';
import Select from 'react-select';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';


class RevenueForm extends Component {

    state = {
        invoice: null,
        customer: null,
        account: null,
        category: null,
        paymentMethod: null,
        date: new Date(),
        amount: 0,
        status: 0,
        description: '',
        attachment: null,
        reference: ''
    }

    componentWillMount() {
		this.validator = new SimpleReactValidator();
    }
    
    loadInvoiceValues = (idinvoice) => {
        Axios.get(API_BASE_URL + '/invoices/' + idinvoice, AuthService.getAuthHeader())
        .then(res => {
            let invoice = res.data;
            this.setState({
                ...this.state,
                amount: invoice.amountDue,
                invoice: {value: idinvoice, label: invoice.invoiceNumber},
                customer: invoice.customer,
                category: invoice.category,
            });
        });
    }
    componentDidMount() {
        var idinvoice = parseInt(this.props.match.params.id);
        this.loadInvoiceValues(idinvoice);        

        this.props.loadCustomers();
        this.props.loadInvoices();
        this.props.loadAccounts();
        this.props.loadCategories('credit');
        this.props.loadPaymentMethods();
    }

    onCustomerChange = (e) => {
        this.setState({
            customer: e,
            invoice: null,
            amount: 0
        });
        this.props.loadInvoices(e.value);
    }

    onCategoryChange = (e) => {
		this.setState({category: e});
    }

    onInvoiceChange = (e) => {
        this.setState({invoice: e});
        this.loadInvoiceValues(e.value);
    }

    onAccountChange = (e) => {
        this.setState({account: e});
    }

    onPaymentMethodChange = (e) => {
        this.setState({paymentMethod: e});
    }

    handleDateChange = (date) => {
        this.setState({date: date});
    }

    onChangeFile = (e) => {
        document.getElementById('attachment').disabled = "disabled";
        var file = e.target.files[0];
		var ext = file.name.split('.').pop();
		if ( IMAGE_TYPES.concat(FILE_TYPES).indexOf(ext) !== -1 ) {
			const data = new FormData() 
	    	data.append('file', e.target.files[0]);
			Axios.post(API_BASE_URL + '/upload/file', data, AuthService.getAuthHeader())
		    	.then(res => {
                    this.setState({attachment: res.data.fileName});
                    document.getElementById('attachment').value = null;
                    document.getElementById('attachment').disabled = "";
				});
		} else {
			console.error("Extension: not allowed: " + ext)
		}
    }

    getSqlDate = (date) => {
        let day = (date.getDate()).toString().padStart(2, '0');
        let month = (date.getMonth()+1).toString().padStart(2, '0');
        let year = (date.getFullYear()).toString().padStart(2, '0');
        return year+'-'+month+'-'+day;
    }

    onChange = (e) => this.setState( {[e.target.name]: e.target.value } );

    onSubmit = (e) => {
        e.preventDefault();
		if (this.validator.allValid()) {
            let obj = {
                ...this.state,
                date: (new Date(this.state.date)).getTime()/1000,
                amount: parseFloat(this.state.amount),
                account: this.state.account.value,
                category: this.state.category.value,
                customer: this.state.customer.value,
                paymentMethod: this.state.paymentMethod.value,
                attachment: this.state.attachment
            }

            Axios.post(API_BASE_URL + '/invoice/' + this.state.invoice.value + '/revenues', obj, AuthService.getAuthHeader())
            .then(res => {
                this.props.history.push("/income/invoice/" + this.state.invoice.value + "/view");
            })
            .catch(error => {
                var message = handleError(error);
                
                const alertBox = () => ( 
                    <SweetAlert
                        danger				
                        confirmBtnBsStyle="danger"
                        title={t("Error!")}
                        onConfirm={() => {					
                            this.hideAlert();
                        }}>
                            {message}
                    </SweetAlert> 
                );
                this.setState({   alert: alertBox()  });
            });
        } else {
            this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
        }
    }

    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    
                    <div className="row">

                        <div className="col-12">

                            <div className="card">
                                <div className="col card-header">
                                    <h6>{t("New Revenue")}</h6>
                                </div>
                                
                                <div className="card-body">
                                    <form onSubmit={this.onSubmit}>

                                    <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label">Customer <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-user"></i></span>
                                                        </div>  
                                                        <Select 
                                                            name="customer"
                                                            value={this.state.customer}
                                                            id="customer"
                                                            onChange={this.onCustomerChange}
                                                            options = {this.props.customers}
                                                            className="form-control"
                                                        />                                                     
                                                        {this.validator.message('customer', this.state.customer, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Invoice Number")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-notepad"></i></span>
                                                        </div>
                                                        <Select 
                                                            name="invoice"
                                                            value={this.state.invoice}
                                                            id="invoice"
                                                            onChange={this.onInvoiceChange}
                                                            options = {this.props.invoices}
                                                            className="form-control"
                                                        />
                                                        {this.validator.message('invoice', this.state.invoice, 'required')}
                                                    </div>
                                                    {/* <span className="smaller">{t("(auto update on Amount +/- Customer)")}</span> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Date")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-calendar"></i></span>
                                                        </div>
                                                        <DatePicker
                                                            name="date"
                                                            locale="fr"
                                                            className="form-control"
                                                            dateFormat="yyyy-MM-dd"
                                                            selected={this.state.date}
                                                            onChange={this.handleDateChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Amount")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-money"></i></span>
                                                        </div>
                                                        <input name="amount" value={this.state.amount} onChange={this.onChange} type="text" className="form-control" placeholder={t("Amount")} />
                                                        {this.validator.message('amount', this.state.amount, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-12 form-group">
                                                <label className="control-label">{t("Description")}</label>
                                                <textarea name="description" onChange={this.onChange} type="text" className="form-control" placeholder={t("Description")} />
                                                {/* {this.validator.message('description', this.state.description, 'required')} */}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Account")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-layers"></i></span>
                                                        </div>  
                                                        <Select 
                                                            name="account"
                                                            value={this.state.account}
                                                            id="account"
                                                            onChange={this.onAccountChange}
                                                            options = {this.props.accounts}
                                                            className="form-control"
                                                        />                                                     
                                                        {this.validator.message('account', this.state.account, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <label className="control-label">Category <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-folder"></i></span>
                                                        </div>
                                                        <Select 
                                                            name="category"
                                                            value={this.state.category}
                                                            id="category"
                                                            onChange={this.onCategoryChange}
                                                            options = {this.props.categories}
                                                            className="form-control"
                                                        />   
                                                        {this.validator.message('category', this.state.category, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-sm-12 col-md-4">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Payment method")} <span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-wallet"></i></span>
                                                        </div>
                                                        <Select 
                                                            name="paymentMethod"
                                                            value={this.state.paymentMethod}
                                                            id="paymentMethod"
                                                            onChange={this.onPaymentMethodChange}
                                                            options = {this.props.paymentmethods}
                                                            className="form-control"
                                                        />   
                                                        {this.validator.message('paymentMethod', this.state.paymentMethod, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Internal reference")}</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend" >
                                                            <span className="input-group-text"><i className="ti-bookmark"></i></span>
                                                        </div>
                                                        <input name="reference" onChange={this.onChange} type="text" className="form-control" placeholder={t("Internal reference")} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label">{t("Attachment")}</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input
                                                            name="attachment"
                                                            onChange={this.onChangeFile}
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="attachment"											
                                                            aria-describedby="attachment" />
                                                            <label className="custom-file-label" htmlFor="attachment">
                                                                {t("Choose file")}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {(this.state.attachment  !== null) && <img className="image-width" src={API_IMAGES_URL + this.state.attachment} /> }
												    <div >{t("Allowed file types are: {0}.", [IMAGE_TYPES.join(', ')])}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row float-right">
                                            <div className="col-12">
                                                <div className="btn-toolbar form-group mt-3">
                                                    <Link to="/income/revenues" className="btn btn-outline-secondary waves-effect waves-light m-r-5"><i className="far fa-trash-alt"></i> {t("Cancel")}</Link>
                                                    <button type="submit" className="btn btn-outline-success waves-effect waves-light"> <span>{t("Save revenue")}</span> <i className="fab fa-telegram-plane m-l-10"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

const mapStateToProps = state => ({
    // needComponentUpdate: state.invoice.needComponentUpdate,
    customers: state.revenueForm.customers,
    accounts: state.revenueForm.accounts,
    paymentmethods: state.revenueForm.paymentmethods,
    categories: state.revenueForm.categories,
    invoices: state.invoice.options,
})

export default connect (mapStateToProps, {loadCustomers, loadAccounts, loadPaymentMethods, loadCategories, loadInvoices}) (RevenueForm);