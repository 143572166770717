import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL, IMAGE_TYPES} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/PaymentMethodActions';
import { t } from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';

class PaymentMethodForm extends Component {

	state = {
		id: null,
		name: '',
		iso: '',
		
		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let id = this.props.match.params.id;
		Axios.get(API_BASE_URL + '/paymentmethods/'+id, AuthService.getAuthHeader())
		.then(res => {			
			let method = res.data;
			this.setState({
				id: method.id,
				name: method.name,
				iso: method.iso		
			});
		});
	}

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var paymentmethod = {
				name: this.state.name,
				iso: this.state.iso
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;


			if (this.state.id !== null) {
				promise = this.props.updateItem('/paymentmethods/'+this.state.id, paymentmethod);
			} else {
				promise = this.props.createItem('/paymentmethods', paymentmethod);
			}

			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit Payment method'):t('Add Payment method')} </div>
								<div className="card-body">			
			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|string')}
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="iso" className="col-sm-3 col-form-label">{t("iso")}</label>
											<div className="col-sm-9">
												<input value={this.state.iso} onChange={this.onChange} id="iso" type="text" name="iso" className="form-control" placeholder={t("iso")}></input>
												{this.validator.message('iso', this.state.iso, 'required|alpha')}
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
										
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.paymentmethod.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (PaymentMethodForm);