import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import Axios from 'axios';
import {API_BASE_URL} from '../../../config';
import AuthService from '../../Auth/AuthService';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { SketchPicker } from 'react-color';

import {connect} from 'react-redux';
import {createItem, updateItem, setNeedComponentUpdate} from '../../../store/CategoryActions';
import {t} from '../../../components/Translation';

import { handleError } from '../../../helper';
import SweetAlert  from 'react-bootstrap-sweetalert';


class CategoryForm extends Component {

	state = {
		id: null,
		reference: '',
		name: '',
		type: '',
		color1: false,		
		bgSimple: '#fff',
		status: true,
		type_options: [
		    { value: 'debit', label: t('Debit') },
		    { value: 'credit', label: t('Credit') }
		],
		
		alert: null,
	}
	
	hideAlert = () => {
		this.setState({   alert: null  });
	}
	componentWillMount() {
		this.validator = new SimpleReactValidator();
	}
	
	componentDidMount() {
		let dpath = "/business/category/:id/duplicate";
		let id = this.props.match.params.id;
		if (typeof id !== 'undefined') {
		Axios.get(API_BASE_URL + '/categories/'+id, AuthService.getAuthHeader())
		.then(res => {			
			var st = res.data;
			this.setState({
				id: (this.props.path === dpath ? null: st.id),
				reference: st.reference,
				name: st.name,
				type: this.state.type_options.filter(option => option.value === st.type)[0],
				bgSimple: st.color,
				status: (st.status === "enabled")	
			});
		});
	}
}

	changeStatus = () => { this.setState({ status: !this.state.status });  }

	onChange = (e) => this.setState( {[e.target.name]: e.target.value });
	
	onTypeChange = (e) => {
		this.setState({type: e});
	}
	
	ChangeSimple = (color) => {   this.setState({ bgSimple: color.hex });  };
	handleSimple = (e) => {  this.setState({ color1: !this.state.color1 });  }
			
	onSubmit = (e) => {
		e.preventDefault();		
		if (this.validator.allValid()) {
			var category = {
				reference: this.state.reference,
				name: this.state.name,
				type: this.state.type.value,
				color: this.state.bgSimple,
				status: this.state.status ? "enabled" : "disabled"
			}

			var promise = null;			
			const listPath = this.props.listPath;
			const history = this.props.history;

			if (this.state.id !== null) {
				promise = this.props.updateItem('/categories/'+this.state.id, category);				
			} else {
				promise = this.props.createItem('/categories', category);
			}
			
			promise
				.then(response => {
					
					const alertBox = () => ( 
						<SweetAlert
							success				
							confirmBtnBsStyle="success"
							title={t("Success!")}
							onConfirm={() => {					
								this.hideAlert();
								history.push(listPath);
							}}>
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });		
					
				})
				.catch(error => {
					var message = handleError(error);
					
					const alertBox = () => ( 
						<SweetAlert
							danger				
							confirmBtnBsStyle="danger"
							title={t("Error!")}
							onConfirm={() => {					
								this.hideAlert();
							}}>
								{message}
						</SweetAlert> 
					);
					this.setState({   alert: alertBox()  });
				});

		} else {
		    this.validator.showMessages();
		    // rerender to show messages for the first time
		    // you can use the autoForceUpdate option to do this automatically`
		    this.forceUpdate();
		}
	}		

	render() {
		return (
			<AUX>
				<div className="container-fluid">
					{this.state.alert}
					<div className="row">
						<div className="col-lg-12">
							<div className="card m-b-20">
								<div className="card-header">{this.state.id ? t('Edit'):t('Add New')} {t("category")}</div>
								<div className="card-body">			
									<form className="form-horizontal" onSubmit={this.onSubmit} >
										
										<div className="form-group row">
											<label htmlFor="reference" className="col-sm-3 col-form-label">{t("Reference")}</label>
											<div className="col-sm-9">
												<input value={this.state.reference} onChange={this.onChange} id="reference" type="text" name="reference" className="form-control" placeholder={t("reference")}></input>
												{this.validator.message('reference', this.state.reference, 'required|string')}
											</div>
										</div>

										<div className="form-group row">
											<label htmlFor="name" className="col-sm-3 col-form-label">{t("Name")}</label>
											<div className="col-sm-9">
												<input value={this.state.name} onChange={this.onChange} id="name" type="text" name="name" className="form-control" placeholder={t("Name")}></input>
												{this.validator.message('name', this.state.name, 'required|string')}
											</div>
										</div>
									
										<div className="form-group row">
											<label htmlFor="type" className="col-sm-3 col-form-label">{t("Type")}</label>
											<div className="col-sm-9">
												<Select 
													name="type"
													value={this.state.type_options.filter(option => option.value === this.state.type.value)}
													id="type"
													onChange={this.onTypeChange}
													options = {this.state.type_options} 
												/>
												{this.validator.message('type', this.state.type, 'required')}
											</div>
										</div>
									
										<div className="form-group row">
			                                <label htmlFor="color" className="col-sm-3 col-form-label">{t("Color")}</label>
											<div className="col-sm-9">
				                                 <input type="text" className="colorpicker-default form-control" onClick={ this.handleSimple } value={ this.state.bgSimple  } />
			                                     { this.state.color1 ? <SketchPicker  color={ this.state.bgSimple } onChangeComplete={ this.ChangeSimple } /> : null }
			                                </div>
			                            </div>												
													
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">{t("Enabled?")}</label>
											<div className="col-sm-9">												
												<input type="checkbox" id="status" checked={this.state.status ? "checked"  :  "" } switch="none" onChange={this.changeStatus} />
												<label htmlFor="status" data-on-label={t("Yes")} data-off-label={t("No")}></label>
											</div>
										</div>																		
																
										<div className="form-group row">
											<label className="col-sm-3 col-form-label">&nbsp;</label>
											<div className="col-sm-9 text-right">		
												<button type="submit" className="btn btn-primary">{t("Submit")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>								
				</div>	
			</AUX>
		);
	}
}

const mapStateToProps = state => ({
    needComponentUpdate: state.category.needComponentUpdate
})

export default connect (mapStateToProps, {createItem, updateItem, setNeedComponentUpdate}) (CategoryForm);